import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useLayoutEffect,
  useMemo,
  useRef
} from 'react';
import * as THREE from 'three';
import { Vector3 } from 'three';
import { Position } from './GameObject';
import useAsset from './useAsset';
import useGameLoop from './useGameLoop';

// it's a partial because some sprites will move position
export interface GraphicProps extends Partial<Position> {
  src: string;
  sheet?: {
    [index: string]: number[][];
  };
  rotation?: number;
  state?: string;
  frameWidth?: number;
  startFrame?: number;
  frameHeight?: number;
  frameTime?: number;
  scale?: number;
  flipX?: number;
  color?: string;
  opacity?: number;
  offset?: Position;
  blending?: THREE.Blending;
  magFilter?: THREE.TextureFilter;
  onIteration?: () => void;
  isInteracting?: boolean;
  origin?: 'bottom-left' | 'top-left' | 'bottom-center';
}

const Graphic = memo(
  forwardRef<THREE.Object3D, GraphicProps>(function Graphic(
    {
      src,
      sheet = {
        default: [[0, 0]]
      },
      state = 'default',
      frameWidth = 16,
      frameHeight = 16,
      frameTime = 200,
      scale = 1,
      flipX = 1,
      rotation = 0,
      startFrame = 0,
      color = '#fff',
      opacity = 1,
      offset = { x: 0, y: 0 },
      blending = THREE.NormalBlending,
      magFilter = THREE.NearestFilter,
      onIteration,
      isInteracting,
      origin
    }: GraphicProps,
    ref
  ) {
    const image = useAsset(src) as HTMLImageElement;
    if (!image) {
      // eslint-disable-next-line no-debugger
      console.log(src);
    }

    const textureRef = useRef<THREE.Texture>(new THREE.Texture(image));

    useLayoutEffect(() => {
      textureRef.current.needsUpdate = true;
    }, []);

    const frameSizeScaleX = frameWidth / 16; // Scale relative to base 16x16
    const frameSizeScaleY = frameHeight / 16; // Scale relative to base 16x16

    const geometry = useMemo(
      () => new THREE.PlaneGeometry(frameSizeScaleX, frameSizeScaleY),
      [frameSizeScaleX, frameSizeScaleY]
    );

    let adjustedPositionX = offset.x + frameSizeScaleX * 0.5;
    let adjustedPositionY = offset.y - frameSizeScaleY * 0.5;

    if (origin && origin === 'bottom-center') {
      adjustedPositionX = offset.x + frameSizeScaleX * 0.18;
      adjustedPositionY = offset.y * 0.5;
    } else if (origin === 'bottom-left') {
      adjustedPositionY = offset.y * 0.5;
    } else {
      adjustedPositionY = offset.y - frameSizeScaleY * 0.5;
    }
    const meshPosition = useMemo(
      () => new Vector3(adjustedPositionX, adjustedPositionY, 0),
      [adjustedPositionX, adjustedPositionY]
    );

    const mounted = useRef(true);
    const interval = useRef<number>();
    const prevFrame = useRef<number>(-1);

    const frames = sheet[state];
    const frameLength = frames.length;
    const frame = useRef(startFrame < frameLength ? startFrame : 0);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [firstFrame, lastFrame = firstFrame] = frames;

    const handleFrameUpdate = useCallback(() => {
      // return;
      let currentFrame = frames[frame.current];
      if (currentFrame === undefined) {
        //TODO fix this
        currentFrame = frames[0];
        return;
      }

      let textureOffsetX = (currentFrame[0] * frameWidth) / image.width;
      let textureOffsetY = (currentFrame[1] * frameHeight) / image.height;
      if (origin === 'top-left') {
        textureOffsetY = 1 - (currentFrame[1] * frameHeight + frameHeight) / image.height;
      } else if (origin === 'bottom-left') {
        textureOffsetY = (currentFrame[1] * frameHeight) / image.height;
      }

      if (frameWidth > 16) {
        textureOffsetX = (currentFrame[0] * frameWidth + frameWidth) / image.width;
      }

      textureRef.current.offset.setX(textureOffsetX);
      textureRef.current.offset.setY(textureOffsetY);
    }, [firstFrame, frameHeight, frameWidth, image, textureRef]);

    const iterationCallback = useRef<typeof onIteration>();
    iterationCallback.current = onIteration;
    // call onIteration on cleanup
    useEffect(
      () => () => {
        mounted.current = false;
        iterationCallback.current?.();
      },
      []
    );
    // initial frame update
    useEffect(() => handleFrameUpdate(), [handleFrameUpdate]);

    useGameLoop((time) => {
      if (!mounted.current) return;
      if (!isInteracting) return;
      if (interval.current == null) interval.current = time;

      if (time >= interval.current + frameTime) {
        interval.current = time;
        prevFrame.current = frame.current;
        frame.current = (frame.current + 1) % frameLength;

        // do I need this?
        handleFrameUpdate();

        if (prevFrame.current > 0 && frame.current === frameLength - 1) {
          onIteration?.();
        }
      }
    }, frameLength > 1);

    const materialProps = useMemo<Partial<THREE.MeshBasicMaterial & THREE.MeshLambertMaterial>>(
      () => ({
        color: new THREE.Color(color),
        opacity,
        blending,
        transparent: true,
        depthTest: false,
        depthWrite: false,
        fog: false,
        // emissive: new THREE.Color('black'),
        // emissiveIntensity: 0,

        flatShading: true,
        precision: 'lowp'
      }),
      [opacity, blending, color]
    );

    // eslint-disable-next-line react-hooks/rules-of-hooks, @typescript-eslint/ban-ts-comment
    // @ts-ignore
    const textureProps = useMemo<Partial<THREE.Texture>>(() => {
      const size = {
        x: image.width / frameWidth,
        y: image.height / frameHeight
      };

      return {
        image,

        repeat: new THREE.Vector2(1 / size.x, 1 / size.y),
        magFilter,

        // center: new Vector2(size.x / 2, size.y / 2),
        minFilter: THREE.LinearMipMapLinearFilter
      };
    }, [frameHeight, frameWidth, image, magFilter]);

    if (!sheet[state]) {
      // eslint-disable-next-line no-console
      console.warn(`Sprite state '${state}' does not exist in sheet '${src}':`, Object.keys(sheet));
      return <></>;
    }
    return (
      <mesh
        ref={ref as any}
        // position={new Vector3(offset.x, offset.y, 0)}
        position={meshPosition}
        scale={[flipX * scale, scale, 1]}
        rotation={new THREE.Euler(offset.x, offset.y, -offset.y / 100 + rotation)}
        geometry={geometry}
      >
        <meshLambertMaterial attach="material" {...materialProps}>
          {/* <texture ref={textureRef as any} attach="alpha" {...textureProps} /> */}
          <texture ref={textureRef as any} attach="map" {...textureProps} />
        </meshLambertMaterial>
      </mesh>
    );
  })
);

export default Graphic;
