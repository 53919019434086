import React from 'react';
import { Dialog, DialogProps } from '@mui/material';
import { SxProps, Theme } from '@mui/system';

interface Modal3DProps extends DialogProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

const Modal3D: React.FC<Modal3DProps> = ({ children, sx = {}, ...props }) => {
  return (
    <Dialog
      maxWidth="md"
      scroll="paper"
      {...props}
      sx={{
        ...sx,
        '& .MuiDialog-paper': {
          overflow: 'visible',
          position: 'relative',
          background: '#DCBB8B',
          borderRadius: '30px',
          boxShadow: `
            inset 0px 0px 0px 2px #E4D0AB,  // Inner border
            inset 0px 0px 10px 0px #A37B5E, // Inner shadow for depth
            7px 7px 0px -3px #A37B5E,      // Right shadow
            -7px 5px 0px -2px #A37B5E,     // Left shadow
            -2px -2px 0px 3px #BD9C72,      // Top left shadow
            1px -2px 0px 3px #BD9C72,      // Top right shadow
            3px -10px 0px 1px #AF8B68,     // Upper right shadow
            -4px -10px 0px 1px #AF8B68,     // Upper left shadow
            -4px 10px 0px 0px #A37B5E,      // Bottom left shadow
            3px 10px 0px 0px #A37B5E       // Bottom right shadow
          `,
          color: '#F1E5C6',
          ...((sx as any)['& .MuiDialog-paper'] || {})
        }
      }}
    >
      {children}
    </Dialog>
  );
};

export default Modal3D;
