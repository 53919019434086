import { useQuery } from 'react-query';
import { OrganizationService } from '../client';

export const useOrganizationSlugExists = (slug: string) => {
  const { data, isLoading } = useQuery(
    ['organization-slug-exists', slug],
    () => OrganizationService.checkOrganizationSlugExistsApiOrganizationCheckSlugExistsGet(slug),
    {
      enabled: !!slug,
      retry: false,
      cacheTime: 0
    }
  );

  return { slugExists: data || false, isSlugLoading: isLoading };
};
