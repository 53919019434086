import GameObject, { GameObjectProps, Position } from '../@core/GameObject';
import Sprite, { SpriteProps } from '../@core/Sprite';
import useGameObject from '../@core/useGameObject';
import useGameObjectEvent from '../@core/useGameObjectEvent';
import useGame from '../@core/useGame';
import useSceneManager from '../@core/useSceneManager';
import { useApiLevelData } from '../context/ApiLevelDataContext';
import { UNEXPECTED_ERRORS } from '../@core/utils/gameErrors';
import { DidCollectEvent } from '../@core/Collectable';
import waitForMs from '../@core/utils/waitForMs';
import Interactable, { InteractionEvent } from '../@core/Interactable';
import { useRef } from 'react';
import { Combining } from '../@core/utils/movementHelpers';
import { PubSubEvent } from '../@core/utils/createPubSub';
import { useCommandError } from '../hooks/useCommandError';
import soundData from '../soundData';
import { useSound } from '../@core/Sound';
import { XMARK_LIST } from '../@core/utils/xmarkList';

export interface TypedPosition extends Position {
  positionType: 'horizontal' | 'vertical';
}
export type CombineEvent = PubSubEvent<'combine', TypedPosition>;

function CombiningScript() {
  const xmarkState = useRef(false);
  const { getRef } = useGameObject();
  const { publish, findGameObjectByName } = useGame();
  const { levelData, setLevelData, inventory, setInventoryData } = useSceneManager();
  const { triggerError } = useCommandError();
  const { apiLevelData } = useApiLevelData();
  const playCombineLiquidSound = useSound(soundData.combineLiquid);

  useGameObjectEvent<InteractionEvent>('interaction', async ({ obj, step }) => {
    const combineStep = step as Combining;
    const { combineObject } = combineStep;

    if (!combineObject) return;

    if (!apiLevelData) return;

    const { levelGoals } = apiLevelData;

    const actionPoint = {
      x: getRef().transform.x,
      y: getRef().transform.y
    };
    const dirX = obj.transform.rotationX;
    const dirY = obj.transform.rotationY;

    const combiningPosition = {
      x: actionPoint.x + dirX,
      y: actionPoint.y + dirY
    };

    const combiningObjectGoal = levelGoals.find(
      (goal) =>
        XMARK_LIST.includes(goal.type || '') &&
        goal.action === 'combine' &&
        goal?.position?.[0] === actionPoint.x &&
        goal?.position?.[1] === actionPoint.y
    );

    if (!combiningObjectGoal) {
      triggerError('Unexpected game error');
      return;
    }

    const targetObjectsPosition = {
      x: combiningObjectGoal.targetPosition?.[0],
      y: combiningObjectGoal.targetPosition?.[1]
    };

    if (!targetObjectsPosition.x || !targetObjectsPosition.y) {
      triggerError('Unexpected game error');
      return;
    }

    if (
      targetObjectsPosition.x !== combiningPosition.x ||
      targetObjectsPosition.y !== combiningPosition.y
    ) {
      triggerError(UNEXPECTED_ERRORS.combine.position);
      return;
    }
    const costTypes = combiningObjectGoal?.cost?.type?.split('|');
    const inventoryItems = inventory.filter((item) => costTypes?.includes(item.type));

    const combineCost = combiningObjectGoal?.cost?.amount || 0;

    const isCountEnough = inventoryItems.every((item) => item.count >= combineCost);

    if (combineCost !== 0) {
      if (inventoryItems.length === 0 || isCountEnough) {
        triggerError(UNEXPECTED_ERRORS.combine.collect);
        return;
      }
    }

    const combineHorizontal = dirX !== 0;

    const combinePosition = {
      x: obj.transform.x + dirX,
      y: obj.transform.y + dirY,
      positionType: combineHorizontal ? 'horizontal' : 'vertical'
    };

    const combineState = combineHorizontal ? 'brownmix' : 'brownmix';
    const combineToObject = findGameObjectByName(
      `${combineState}-${combinePosition.x}-${combinePosition.y}`
    );
    combineToObject?.setDisabled(false);

    const newLevelData = levelData.map((item) => {
      if (
        XMARK_LIST.includes(item.type || '') &&
        item.action === 'combine' &&
        item.position?.[0] === actionPoint.x &&
        item.position?.[1] === actionPoint.y
      ) {
        return { ...item, score: (item.score += 1) };
      }
      return item;
    });

    setLevelData(newLevelData);

    setInventoryData(
      inventory.map((item) => {
        if (inventoryItems.length === 0 || (costTypes?.includes(item.type) && item.count > 0)) {
          return { ...item, count: (item.count -= combineCost) };
        }
        return item;
      })
    );

    playCombineLiquidSound();
    await waitForMs(500);
    publish<CombineEvent>('combine', combinePosition as TypedPosition);
    publish<DidCollectEvent>('did-collect', newLevelData);
    xmarkState.current = !xmarkState.current;
    if (xmarkState.current) {
      getRef().setDisabled(true);
    }
    return await waitForMs(200);
  });

  return null;
}

interface CombineProps {
  props: GameObjectProps;
  state: string;
  spriteData: SpriteProps;
  itemName: string;
}

export default function ItemCombine({ props, state, spriteData }: CombineProps) {
  const name = `${state}-${props.x}-${props.y}`;

  return (
    <GameObject name={name} persisted={false} {...props} layer="item">
      <Sprite {...spriteData} state={state} />
      <Interactable type="combine" />
      <CombiningScript />
    </GameObject>
  );
}
