import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';

import codebook from './codebook.md';
import { useEffect, useState } from 'react';
import { Box, Button, Divider, Drawer, Typography } from '@mui/material';
import { ArrowBack, MenuBook } from '@mui/icons-material';

export default function CodeBookDrawer({
  open,
  setOpen
}: {
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}) {
  const [markdown, setMarkdown] = useState('');

  useEffect(() => {
    fetch(codebook)
      .then((res) => res.text())
      .then((text) => setMarkdown(text));
  }, []);
  return (
    <Drawer open={open} anchor="right" onClose={() => setOpen(false)}>
      <Box component="div" sx={{ width: 680, maxWidth: '100vw', px: 2, pt: 10 }}>
        <Button
          variant="outlined"
          startIcon={<ArrowBack />}
          onClick={() => setOpen(false)}
          sx={{ mb: 2 }}
        >
          Close
        </Button>
        <Typography variant="h4">
          <MenuBook /> Code Book
        </Typography>
        <p>
          Here are all the functions you can use in the game. Feel free to experiment with them and
          try to complete the level by learning how to use these functions!
        </p>
        <Divider />
        <Markdown remarkPlugins={[remarkGfm]} urlTransform={(url) => `/assets/codebook/${url}`}>
          {markdown}
        </Markdown>
      </Box>
    </Drawer>
  );
}
