import { useMutation, useQuery, useQueryClient } from 'react-query';
import { OrganizationService } from '../client';
import { useContext } from 'react';
import { UserContext } from '../pages/App';

export default function useOrganizationTeachers() {
  const queryClient = useQueryClient();
  const currentUser = useContext(UserContext);

  const { data: teachers, isLoading: isTeachersLoading } = useQuery(
    ['teachers'],
    OrganizationService.getTeachersByOrganizationApiOrganizationTeachersGet,
    {
      enabled: !!currentUser,
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false
    }
  );
  const deleteTeacherUserMutation = useMutation(
    OrganizationService.deleteTeacherApiOrganizationTeacherTeacherIdDelete,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('teachers');
      }
    }
  );

  return { teachers, isTeachersLoading, deleteTeacherUserMutation };
}
