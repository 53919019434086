import * as yup from 'yup';

export const USER_TYPES = {
  parent: 0,
  teacher: 1,
  admin: 2
};

export const signupSchema = yup.object().shape({
  email: yup.string().when('user_type', {
    is: USER_TYPES.teacher,
    then: (schema: yup.StringSchema) => schema,
    otherwise: (schema: yup.StringSchema) =>
      schema.email('Invalid email').required('Email is required')
  }),
  password: yup
    .string()
    .min(8, 'Password must be at least 8 characters')
    .required('Password is required'),
  verify_password: yup
    .string()
    .oneOf([yup.ref('password'), undefined], 'Passwords must match')
    .required('Verify Password is required'),
  full_name: yup.string().required('Full Name is required'),
  user_type: yup.number().required('User Type is required'),
  character: yup.string().default('Rabbit'),
  student_full_name: yup.string().when('user_type', {
    is: USER_TYPES.parent,
    then: (schema: yup.StringSchema) => schema.required('Student Full Name is required'),
    otherwise: (schema: yup.StringSchema) => schema
  }),
  student_username: yup.string().when('user_type', {
    is: USER_TYPES.parent,
    then: (schema: yup.StringSchema) => schema.required('Student Username is required'),
    otherwise: (schema: yup.StringSchema) => schema
  }),
  student_password: yup.string(),
  organization_name: yup.string().when('user_type', {
    is: USER_TYPES.admin,
    then: (schema: yup.StringSchema) => schema.required('Organization Name is required'),
    otherwise: (schema: yup.StringSchema) => schema
  }),
  organization_slug: yup.string().when('user_type', {
    is: USER_TYPES.admin,
    then: (schema: yup.StringSchema) =>
      schema.matches(/^[a-z\\-]{3,}$/, 'Invalid slug').required('Organization Slug is required'),
    otherwise: (schema: yup.StringSchema) => schema
  }),
  organization_type: yup.string().when('user_type', {
    is: USER_TYPES.admin,
    then: (schema: yup.StringSchema) => schema.required('Organization Type is required'),
    otherwise: (schema: yup.StringSchema) => schema
  }),
  organization_email_domain: yup.string().when('user_type', {
    is: USER_TYPES.admin,
    then: (schema: yup.StringSchema) =>
      schema
        .matches(/^[\w\-\\.]{3,}$/, 'Invalid email domain')
        .required('Organization Email Domain is required'),
    otherwise: (schema: yup.StringSchema) => schema
  })
});
