import React from 'react';
import { Box, DialogContent, SxProps, Theme } from '@mui/material';

interface ContentWrapperProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({ children, sx = {} }) => {
  if (!children) {
    return null;
  }

  return (
    <DialogContent sx={{ px: 2, display: 'flex', height: '100%', width: '100%' }}>
      <Box
        component="div"
        sx={{
          px: 2,
          background: '#6B4A5B',
          position: 'relative',
          overflow: 'visible',
          minHeight: '200px',
          display: 'flex',
          width: '100%',
          flexDirection: 'column',
          height: '100%',
          ...sx
        }}
      >
        {/* Left Border */}
        <Box
          component="div"
          sx={{
            position: 'absolute',
            top: '-13px',
            left: '-10px',
            width: '10px',
            height: 'calc(100% + 26px)',
            backgroundColor: '#AF8B68',
            border: '1px solid #8A655F',
            borderRadius: '4px',
            zIndex: 2
          }}
        />

        {/* Right Border */}
        <Box
          component="div"
          sx={{
            position: 'absolute',
            top: '-13px',
            right: '-10px',
            width: '10px',
            height: 'calc(100% + 26px)',
            backgroundColor: '#AF8B68',
            border: '1px solid #8A655F',
            borderRadius: '4px',
            zIndex: 2
          }}
        />

        {/* Top Border */}
        <Box
          component="div"
          sx={{
            position: 'absolute',
            top: '-10px',
            left: '-13px',
            height: '10px',
            width: 'calc(100% + 26px)',
            backgroundColor: '#AF8B68',
            border: '1px solid #8A655F',
            borderRadius: '4px',
            zIndex: 1
          }}
        />

        {/* Bottom Border */}
        <Box
          component="div"
          sx={{
            position: 'absolute',
            bottom: '-10px',
            left: '-13px',
            height: '10px',
            width: 'calc(100% + 26px)',
            backgroundColor: '#AF8B68',
            border: '1px solid #8A655F',
            borderRadius: '4px',
            zIndex: 1
          }}
        />
        <Box component="div">{children}</Box>
      </Box>
    </DialogContent>
  );
};

export default ContentWrapper;
