import { DialogActions } from '@mui/material';
import { useGenericModal } from '../context/GenericModalContext';
import ContentMarkdown from './GameUI/ContentMarkdown';
import Modal from './GameUI/Modal';
import SecondaryButton from './GameUI/SecondaryButton';

const ErrorModal = () => {
  const { content, openModal: open, setOpenModal } = useGenericModal('error');
  const onClose = () => setOpenModal(false);

  return (
    <Modal open={open} onClose={onClose}>
      <ContentMarkdown content={content}></ContentMarkdown>
      <DialogActions>
        <SecondaryButton onClick={onClose}>Close</SecondaryButton>
      </DialogActions>
    </Modal>
  );
};

export default ErrorModal;
