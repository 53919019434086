import { SyntheticEvent, useRef, useState } from 'react';
import {
  Box,
  Button,
  Container,
  Typography,
  TextField,
  IconButton,
  Tooltip,
  Tabs,
  Tab
} from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { OrganizationStudentsList } from '../../../components/Organization/Student/OrganizationStudentsList';
import { useSnackBarContext } from '../../../context/SnackBarContext';
import { useOrganization } from '../../../hooks/useOrganization';
import { Loader } from '../../../components/Loader';
import ImportStudentsDrawer from '../../../components/Organization/Student/ImportStudentsDrawer';
import StudentMatrix from '../../../components/Organization/Class/StudentMatrix';
import { TabContext, TabPanel } from '@mui/lab';
import TeacherClassSelector from '../../../components/Organization/Class/TeacherClassSelector';

export default function OrganizationStudentUsers() {
  const { currentOrganization, isOrganizationLoading } = useOrganization();
  const { createSnackBar } = useSnackBarContext();
  const textFieldRef = useRef<HTMLInputElement>(null);

  const [value, setValue] = useState('students');

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  //TODO: move to env
  const loginLink = `https://codingforkids.io/login-org/${currentOrganization?.slug}`;

  const handleCopyToClipboard = () => {
    navigator.clipboard.writeText(loginLink).then(() => {
      createSnackBar({
        content: 'Login link copied to clipboard!',
        autoHide: true,
        severity: 'success'
      });
      if (textFieldRef.current) {
        textFieldRef.current.focus();
      }
    });
  };

  if (isOrganizationLoading) return <Loader />;

  return (
    <Container maxWidth="lg" sx={{ mt: 3, mb: 14 }}>
      <Box component="div" display="flex" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h4" gutterBottom>
          Students
        </Typography>
      </Box>

      <Box component="div" display="flex" justifyContent="space-between" alignItems="center" mb={3}>
        <TextField
          label="Login Link"
          variant="outlined"
          fullWidth
          value={loginLink}
          inputRef={textFieldRef}
          InputProps={{
            readOnly: true,
            endAdornment: (
              <Tooltip title="Copy to clipboard">
                <IconButton onClick={handleCopyToClipboard}>
                  <ContentCopyIcon />
                </IconButton>
              </Tooltip>
            )
          }}
        />
      </Box>

      <TabContext value={value}>
        <Box component="div" sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Students" value="students" />
            <Tab label="Progress" value="progress" />
          </Tabs>
        </Box>
        <TabPanel value="students" sx={{ p: 0 }}>
          <OrganizationStudentsList />
        </TabPanel>
        <TabPanel value="progress" sx={{ p: 0 }}>
          <StudentMatrix />
        </TabPanel>
      </TabContext>
    </Container>
  );
}
