import { Box, Container, Tab, Tabs, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import TeacherClassSelector from '../../../components/Organization/Class/TeacherClassSelector';
import { useOrgClasses } from '../../../hooks/useOrgClasses';
import { Loader } from '../../../components/Loader';
import { OrganizationStudentsList } from '../../../components/Organization/Student/OrganizationStudentsList';
import StudentMatrix from '../../../components/Organization/Class/StudentMatrix';
import { TabContext, TabPanel } from '@mui/lab';
import React, { SyntheticEvent, useContext, useState } from 'react';
import { UserContext, UserTypeMap } from '../../App';
import { UserType } from '../../../client';
import QuizResults from '../../../components/Organization/Class/QuizResults';

export default function ClassPage() {
  const { classId } = useParams();
  const { classes } = useOrgClasses();
  const currentUser = useContext(UserContext);

  const [value, setValue] = useState('students');

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const classData = classes?.find((x) => x.id == classId);
  const userType = UserTypeMap[currentUser?.user_type || UserType._0];
  if (!userType) return null;

  if (!classData || !classId) {
    return <Loader />;
  }
  return (
    <Container
      maxWidth="lg"
      sx={{ mt: 3, mb: 14, display: 'flex', flexDirection: 'column', gap: 2 }}
    >
      <Typography variant="h4">Class {classData.name}</Typography>
      <TabContext value={value}>
        <Box component="div" sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Students" value="students" />
            {userType === 'admin' && <Tab label="Teachers" value="teachers" />}
            <Tab label="Progress" value="progress" />
            <Tab label="Quiz Results" value="quiz" />
          </Tabs>
        </Box>
        <TabPanel value="teachers" sx={{ p: 0 }}>
          <TeacherClassSelector classId={classId} />
        </TabPanel>
        <TabPanel value="students" sx={{ p: 0 }}>
          <OrganizationStudentsList classId={classId} />
        </TabPanel>
        <TabPanel value="progress" sx={{ p: 0 }}>
          <StudentMatrix classId={classId} />
        </TabPanel>
        <TabPanel value="quiz" sx={{ p: 0 }}>
          <QuizResults classId={classId} />
        </TabPanel>
      </TabContext>
    </Container>
  );
}
