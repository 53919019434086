import React, { MutableRefObject, useCallback, useRef } from 'react';
import GameObject, { Position } from '../@core/GameObject';
import {
  AttemptMoveEvent,
  DidMoveEvent,
  Direction,
  MoveDirection,
  MovingEvent,
  RotatingEvent,
  WillMoveEvent
} from '../@core/Moveable';
import { useScene } from '../@core/Scene';
import Sound from '../@core/Sound';
import Sprite, { SpriteRef } from '../@core/Sprite';
import useGameLoop from '../@core/useGameLoop';
import useGameObject from '../@core/useGameObject';
import useGameObjectEvent from '../@core/useGameObjectEvent';
import soundData from '../soundData';
import spriteData from '../spriteData';
import { useParams } from 'react-router-dom';

interface Props {
  interactionSound?: React.ReactElement;
  children: React.ReactNode;
}

export const characterOffsetY = 0;

/**
 * This component applies bounce/wobble animations to the GameObject's Sprite
 * when the character is moving.
 * It also applies a breathe animation when the character is standing still.
 */

export default function CharacterScript({ children }: Props) {
  const { slug } = useParams();
  const { transform, getComponent } = useGameObject();
  const { instantiate } = useScene();
  const groupRef = useRef<THREE.Group>();
  const childRef = useRef<THREE.Group>();
  const scaleRef = useRef<THREE.Group>();
  const movementWobble = useRef(0);
  const movementCount = useRef(0);
  const movementActive = useRef(false);

  // flip sprite in the current moving direction
  const faceTurnDirection = useCallback(
    ({
      direction,
      isRotating
    }: {
      direction: Direction[];
      isRotating: MutableRefObject<boolean>;
    }): MoveDirection => {
      isRotating.current = true;
      const sprite = getComponent<SpriteRef>('Sprite');
      const dirX = direction[0];
      const dirY = direction[1];
      if (dirX === 1 && dirY === 0) sprite.setState('moveRight');
      else if (dirX === -1 && dirY === 0) sprite.setState('moveLeft');
      else if (dirY === 1 && dirX === 0) sprite.setState('moveUp');
      else if (dirY === -1 && dirX === 0) sprite.setState('moveDown');
      isRotating.current = false;
      return [dirX, dirY];
    },
    [transform, getComponent]
  );

  const faceDirection = useCallback(
    ({ x, y }: Position): MoveDirection => {
      const sprite = getComponent<SpriteRef>('Sprite');
      const dirX = Math.max(-1, Math.min(1, x - transform.x)) as Direction;
      const dirY = Math.max(-1, Math.min(1, y - transform.y)) as Direction;
      if (dirX === 1) sprite.setState('moveRight');
      else if (dirX === -1) sprite.setState('moveLeft');
      else if (dirY === 1) sprite.setState('moveUp');
      else if (dirY === -1) sprite.setState('moveDown');
      return [dirX, dirY];
    },
    [transform, getComponent]
  );

  // wobble effect while moving
  const wobble = useCallback(() => {
    if (movementActive.current) {
      movementCount.current += 0.1;
      if (movementWobble.current < 1) {
        movementWobble.current = Math.min(1, movementWobble.current + 0.02);
      }
    } else {
      movementWobble.current = Math.max(0, movementWobble.current - 0.1);
    }

    if (childRef.current) {
      if (movementWobble.current > 0) {
        const wobbleTime = 2;
        const wobblePower = 0.07;
        const angle =
          Math.sin(movementCount.current * wobbleTime) * movementWobble.current * wobblePower;
        childRef.current.rotation.set(0, 0, angle);
      } else {
        childRef.current.rotation.set(0, 0, 0);
      }
    }
  }, []);

  // face direction
  useGameObjectEvent<AttemptMoveEvent>('attempt-move', faceDirection, [faceDirection]);

  // when character is about to move, enable wobble and footstep effect
  useGameObjectEvent<WillMoveEvent>(
    'will-move',
    () => {
      movementWobble.current = 1;
      movementActive.current = true;

      if (slug === 'creative') {
        instantiate(
          <GameObject x={transform.x} y={transform.y} layer="draw" persisted={false}>
            <Sprite
              {...spriteData['items']}
              state={'starDraw'}
              color="#beee62"
              scale={0.7}
              offset={{ x: 0, y: characterOffsetY }}
            />
            <Sound {...soundData.footstep} />
          </GameObject>
        );
      } else {
        instantiate(
          <GameObject x={transform.x} y={transform.y} persisted={false}>
            <Sound {...soundData.footstep} />
          </GameObject>
        );
      }
    },
    [transform]
  );

  // bounce animation while moving
  useGameObjectEvent<MovingEvent>('moving', ({ currentPosition, nextPosition, direction }) => {
    const [dirX, dirY] = direction;
    const { x, y } = currentPosition;
    const sizeDivider = 5;

    let bounce = 0;

    let delta = 0;
    if (dirX !== 0) delta = x - nextPosition.x;
    else if (dirY !== 0) delta = y - nextPosition.y;

    if (delta > 0) {
      // left/up
      delta = delta > 0.5 ? 1 - delta : delta;
    } else if (delta < 0) {
      // right/down
      delta = delta < -0.5 ? -1 - delta : delta;
    }
    bounce = Math.abs(delta / sizeDivider);
    if (childRef.current) {
      childRef.current.position.setX(bounce * dirX);
      childRef.current.position.setY(bounce);
    }
  });

  useGameObjectEvent<DidMoveEvent>('did-move', () => {
    movementActive.current = false;
  });

  useGameObjectEvent<RotatingEvent>('rotating', faceTurnDirection, [faceTurnDirection]);

  useGameLoop((time) => {
    // apply wobbling animation
    wobble();

    // apply breathe animation
    if (!movementActive.current) {
      // breathe animation while standing still
      const breathIntensity = 20;
      if (scaleRef.current) {
        scaleRef.current.scale.setY(1 + Math.sin(time / 240) / breathIntensity);
      }
    } else {
      // no breathe animation while moving
      if (scaleRef.current) {
        scaleRef.current.scale.setY(1);
      }
    }
  });

  const offsetY = 1;

  return (
    <group position-y={characterOffsetY}>
      <group ref={groupRef as any}>
        <group ref={scaleRef as any} position-y={-offsetY}>
          <group position-y={offsetY}>
            <group ref={childRef as any}>{children}</group>
          </group>
        </group>
      </group>
    </group>
  );
}
