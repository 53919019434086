import { Box, Container, Typography } from '@mui/material';
import BillingDetails from '../../components/Profile/BillingDetails';

export default function ProfileBilling() {
  return (
    <Container maxWidth="lg" sx={{ mt: 3, mb: 14 }}>
      <Box component="div" display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" gutterBottom>
          Billing
        </Typography>
      </Box>

      <BillingDetails />
    </Container>
  );
}
