import { useQuery } from 'react-query';
import { ProfileService } from '../client';
import useLevels from '../components/useLevels';

export const useStudentsByPurchase = () => {
  const { courseId } = useLevels();
  const result = useQuery('students-by-purchase', () =>
    ProfileService.getStudentsByPurchasedCourseApiProfileCourseSlugStudentsPurchasedGet(courseId)
  );
  return result;
};
