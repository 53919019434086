import { useContext, useState } from 'react';
import { Modal, Box, Button, Paper, Card, CardMedia, CardContent, Stack } from '@mui/material';
import Typography from '@mui/material/Typography';
import { UserContext } from '../pages/App';
import { Loader } from './Loader';
import useUnassignedPurchase from '../hooks/useUnassignedPurchase';
import { useQueryClient } from 'react-query';
import { useStudentsWithoutPurchase } from '../hooks/useStudentsWithoutPurchase';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 480,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
  outline: 'none'
};

export default function AssignPurchaseModal() {
  const [selectedStudent, setSelectedStudent] = useState<string>();
  const queryClient = useQueryClient();
  const currentUser = useContext(UserContext);

  const { data: students, isLoading: isStudentsLoading } = useStudentsWithoutPurchase();

  const { hasUnassignedPurchase, assignPurchase } = useUnassignedPurchase();

  const open = !!currentUser && !!hasUnassignedPurchase && !!students && students.length > 0;

  const handleConfirm = async () => {
    if (!selectedStudent) return;
    await assignPurchase.mutateAsync(selectedStudent);
    onClose();
  };

  const onClose = () => {
    queryClient.invalidateQueries([currentUser?.user_id, 'hasUnassignedPurchase']);
    queryClient.invalidateQueries('students-without-purchase');
    queryClient.invalidateQueries('students-by-purchase');
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      aria-labelledby="select-avatar-modal"
      aria-describedby="modal-modal-description"
    >
      {!students || isStudentsLoading ? (
        <Loader />
      ) : (
        <Paper sx={style}>
          <Typography variant="h5" sx={{ textAlign: 'center', py: 2 }}>
            {/* TODO: make the course name dynamic in future */}
            Python course purchase is awaiting assignment.
          </Typography>
          <Typography variant="subtitle1" sx={{ textAlign: 'center', pb: 2 }}>
            Please select a student to assign it to.
          </Typography>
          <Stack gap={2}>
            {students.map((student) => (
              <Card
                key={student.id}
                onClick={() => setSelectedStudent(student.id)}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderColor: student.id === selectedStudent ? 'primary.main' : '#666',
                  borderStyle: 'solid',
                  borderWidth: 1,
                  '&:hover': { cursor: 'pointer', backgroundColor: 'rgba(255,255,255,0.1)' }
                }}
              >
                <CardMedia
                  component="img"
                  sx={{ width: 64 }}
                  image={`/assets/characters/portraits/${student.character}.png`}
                  alt={student.username}
                />
                <CardContent sx={{ flex: '1 0 auto' }}>
                  <Typography variant="h5" sx={{ pt: 1 }}>
                    {student.username}
                  </Typography>
                </CardContent>
              </Card>
            ))}
          </Stack>
          <Box component="div" sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
            <Button
              variant="contained"
              color="primary"
              onClick={handleConfirm}
              disabled={!selectedStudent}
              sx={{ mt: 2 }}
            >
              Confirm
            </Button>
          </Box>
        </Paper>
      )}
    </Modal>
  );
}
