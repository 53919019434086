import { useMutation, useQuery, useQueryClient } from 'react-query';
import { OrganizationService } from '../client';
import { useContext } from 'react';
import { UserContext } from '../pages/App';

export default function useOrganizationStudents() {
  const queryClient = useQueryClient();
  const currentUser = useContext(UserContext);

  const { data: students, isLoading: isStudentsLoading } = useQuery(
    [currentUser?.user_id, 'org-students'],
    () => OrganizationService.getStudentsApiOrganizationStudentsGet(),
    {
      enabled: !!currentUser,
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false
    }
  );
  const deleteOrgStudentUserMutation = useMutation(
    OrganizationService.deleteStudentApiOrganizationStudentsStudentIdDelete,
    {
      onSuccess: () => {
        queryClient.invalidateQueries([currentUser?.user_id, 'org-students']);
      }
    }
  );

  return { students, isStudentsLoading, deleteOrgStudentUserMutation };
}
