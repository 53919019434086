import Collider from '../@core/Collider';
import GameObject, { GameObjectProps } from '../@core/GameObject';
import Interactable, { InteractionEvent } from '../@core/Interactable';
import Sprite, { SpriteProps, SpriteRef } from '../@core/Sprite';
import useGameObject from '../@core/useGameObject';
import useGameObjectEvent from '../@core/useGameObjectEvent';
import waitForMs from '../@core/utils/waitForMs';

function DoorScript() {
  const { getComponent, forceUpdate } = useGameObject();

  useGameObjectEvent<InteractionEvent>('interaction', async ({ step }) => {
    const typedStep = step as { state: string };
    if (!typedStep.state) return;

    switch (typedStep.state) {
      case 'open':
        getComponent<SpriteRef>('Sprite').setIsInteracting(true);
        getComponent<SpriteRef>('Sprite').setState('singleDoorClosed')
        break;
      case 'close':
        getComponent<SpriteRef>('Sprite').setIsInteracting(true);
        getComponent<SpriteRef>('Sprite').setState('singleDoorOpened')
        break;
      default:
        getComponent<SpriteRef>('Sprite').setIsInteracting(false);
    }

    return await waitForMs(750).then(() => forceUpdate());
  });

  return null;
}
interface DoorProps {
  props: GameObjectProps;
  state: string;
  spriteData: SpriteProps;
  itemName: string;
}

export default function Door({ props, state: initialState, spriteData }: DoorProps) {
  const name = `${initialState}-${props.x}-${props.y}`; // fallback name required for persisted flag

  return (
    <GameObject name={name} persisted={false} {...props} layer="door">
      <Sprite {...spriteData} state={initialState} isInteracting={false} frameTime={50} />
      <Interactable type={`open-close-${initialState}`} />
      <Collider />
      <DoorScript />
    </GameObject>
  );
}
