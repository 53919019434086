import { useState } from 'react';
import Collider from '../@core/Collider';
import GameObject, { GameObjectProps } from '../@core/GameObject';
import Sprite, { SpriteProps } from '../@core/Sprite';
import useGameEvent from '../@core/useGameEvent';
import useGameObject from '../@core/useGameObject';
import waitForMs from '../@core/utils/waitForMs';
import useGame from '../@core/useGame';

interface WoodProps {
  props: GameObjectProps;
  state: string;
  spriteData: SpriteProps;
  itemName: string;
}

export default function Wood({ props, state, spriteData }: WoodProps) {
  const [woodPosition, setWoodPosition] = useState({ x: props.x, y: props.y });
  const [name, setName] = useState(`${state}-${props.x}-${props.y}`);
  const [disabled, setDisabled] = useState(true);
  const { getRef, forceUpdate } = useGameObject();
  const { findGameObjectsByXY } = useGame();
  const [woodState, setWoodState] = useState('wood');

  useGameEvent('build', (position) => {
    if (woodPosition.x !== position.x || woodPosition.y !== position.y) return;

    const newWoodState = position.positionType === 'horizontal' ? 'wood' : 'wood';

    setWoodState(newWoodState);
    setName(`${newWoodState}-${position.x}-${position.y}`);
    waitForMs(0).then(() => forceUpdate());

    const obj = findGameObjectsByXY(position.x, position.y);
    obj.forEach((o) => {
      const collider = o.getComponent('Collider');
      if (collider) {
        o.getComponent('Collider').setWalkable(true);
      }
    });

    getRef().setDisabled(false);
    setDisabled(false);
    setWoodPosition({ ...position });
    waitForMs(0).then(() => forceUpdate());
  });

  return (
    <GameObject
      key={name}
      name={name}
      persisted={false}
      {...props}
      {...woodPosition}
      layer="wood"
      disabled={disabled}
    >
      <Sprite {...spriteData} state={woodState} />
      <Collider isTrigger />
    </GameObject>
  );
}
