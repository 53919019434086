import React, {
  createContext,
  RefObject,
  useEffect,
  useLayoutEffect,
  useRef,
  useState
} from 'react';
import HtmlOverlay from './HtmlOverlay';
import useStateFromProp from './useStateFromProp';
import { useGenericModal } from '../context/GenericModalContext';

export type AssetType = HTMLImageElement | HTMLAudioElement;

export interface AssetStore {
  [url: string]: AssetType;
}

export const AssetLoaderContext = createContext<RefObject<AssetStore>>(null!);

interface Props {
  urls: string[];
  placeholder: React.ReactNode;
  children: React.ReactNode;
}

const createRegExp = (extensions: string) => new RegExp(`^.*\\.(${extensions})$`, 'i');

const imageRegExp = createRegExp('jpg|png|gif');
const audioRegExp = createRegExp('wav|mp3|ogg');

function loadAsset(url: string) {
  return new Promise<AssetType>((resolve, reject) => {
    let asset: AssetType;
    if (imageRegExp.test(url)) {
      asset = new Image();
      asset.onload = handleLoad;
      asset.oncanplaythrough = handleLoad;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      asset.onerror = handleLoad;
      asset.src = url;
    } else if (audioRegExp.test(url)) {
      asset = new Audio();
      asset.onload = handleLoad;
      asset.oncanplaythrough = handleLoad;
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      asset.onerror = handleLoad;
      asset.src = url;
    }

    function handleLoad(event: Event) {
      if (event.type === 'error') {
        reject();
        return;
      }
      resolve(asset);
    }
  });
}

// define asset store in module scope, so it can be accessed
// from both dom and webgl reconcilers.
const assets: { current: AssetStore } = {
  current: {}
};

interface ProviderProps {
  children: React.ReactNode;
}

export function AssetLoaderProvider({ children }: ProviderProps) {
  return <AssetLoaderContext.Provider value={assets}>{children}</AssetLoaderContext.Provider>;
}

export default function AssetLoader({ urls: urlsProp, placeholder, children }: Props) {
  const [urls, setUrls] = useStateFromProp(urlsProp);
  const [count, setCount] = useState(0);
  // const assets = useRef<AssetStore>({});
  const uniqueUrls = useRef<Set<string>>(null!);
  uniqueUrls.current = new Set(urls);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const timeout = useRef<any>();
  const mounted = useRef(true);

  const { setOpenModal: setOpenGuideModal } = useGenericModal('guide');

  useLayoutEffect(
    () => () => {
      mounted.current = false;
    },
    []
  );

  useEffect(() => {
    (async () => {
      const loadedAssets = await Promise.all(
        [...uniqueUrls.current].map(
          async (url): Promise<[string, AssetType]> => [url, await loadAsset(url)]
        )
      );
      for (const [url, asset] of loadedAssets) {
        try {
          assets.current[url] = asset;
          if (mounted.current) setCount((current) => current + 1);
        } catch {
          // eslint-disable-next-line no-console
          console.error('Error loading asset:', url);
        }
      }
      clearTimeout(timeout.current);
    })();
  }, [urls]);

  useEffect(() => {
    if (count === uniqueUrls.current.size) {
      setOpenGuideModal(true);
    }
  }, [count, setOpenGuideModal]);

  useEffect(() => {
    if (process.env.NODE_ENV === 'development') {
      // sometimes after WDS triggers a reload, not all assets are being reloaded here.
      const delay = 2000 + uniqueUrls.current.size * 100;
      timeout.current = setTimeout(() => {
        setCount(0);
        setUrls(urls.slice());
        // eslint-disable-next-line no-console
        console.warn('AssetLoader failed loading after timeout.');
      }, delay);
      return () => clearTimeout(timeout.current);
    }
    return undefined;
  }, [urls, setUrls]);

  if (count < uniqueUrls.current.size) {
    return placeholder ? (
      <HtmlOverlay center>
        <span>{placeholder}</span>
      </HtmlOverlay>
    ) : null;
  }

  return <AssetLoaderProvider>{children}</AssetLoaderProvider>;
}
