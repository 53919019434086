import React, { useState, useEffect } from 'react';
import { Box, DialogActions } from '@mui/material';
import { useGenericModal } from '../context/GenericModalContext';
import { LottieRefCurrentProps, useLottie } from 'lottie-react';
import LevelCompleteAnimation from '../resources/animations/level-complete.json';
import useStudents from './useStudents';
import { CourseData } from '../client';
import Modal3D from './GameUI/Modal3D';
import RibbonHeader from './GameUI/RibbonHeader';
import ContentMarkdown from './GameUI/ContentMarkdown';
import PrimaryButton from './GameUI/PrimaryButton';

const lottieStyle: { [key: string]: unknown } = {
  pointerEvents: 'none',
  position: 'absolute',
  top: '50%',
  left: '50%',
  zIndex: 1400,
  transform: 'translate(-50%, -50%)',
  maxWidth: '700px',
  height: '700px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

interface FinalLevelCompleteModalProps {
  courseData: CourseData;
}

const FinalLevelCompleteModal: React.FC<FinalLevelCompleteModalProps> = ({ courseData }) => {
  const { openModal: open, setOpenModal } = useGenericModal('final-level-complete');
  const { currentStudent } = useStudents();

  const [completedLevels, setCompletedLevels] = useState<number>(0);
  const [totalLevels, setTotalLevels] = useState<number>(0);

  const lottieRef = React.useRef<LottieRefCurrentProps>(null);
  const { View: LottieView, goToAndPlay } = useLottie({
    animationData: LevelCompleteAnimation,
    loop: false,
    autoplay: false,
    style: lottieStyle,
    lottieRef
  });

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        goToAndPlay(0);
      }, 10);
    }
  }, [open]);

  useEffect(() => {
    if (currentStudent) {
      const total =
        courseData?.chapters.reduce((acc, chapter) => acc + chapter.level_data.length, 0) || 0;
      const completed =
        courseData?.chapters.reduce(
          (acc, chapter) => acc + chapter.level_data.filter((level) => level.completed).length,
          0
        ) || 0;
      setTotalLevels(total);
      setCompletedLevels(completed);
    }
  }, [courseData, currentStudent]);

  const onClose = () => {
    setOpenModal(false);
  };

  const content = `Great work completing the final level!\n\nYou have completed ${completedLevels} out of ${totalLevels} levels so far. Finish the remaining levels to complete the course and earn your certification! Unfinished levels are the ones that don’t have a checkmark on your sidebar.`;

  return (
    <>
      <Box component="div">{LottieView}</Box>
      <Modal3D open={open} onClose={onClose} maxWidth="sm">
        <RibbonHeader>WOOHOO! You did it!</RibbonHeader>
        <Box component="div" sx={{ pt: 0, px: 2, pb: 0, mt: 3, display: 'flex', overflow: 'auto' }}>
          <ContentMarkdown content={content} />
        </Box>
        <DialogActions sx={{ justifyContent: 'center', pb: 2 }}>
          <PrimaryButton onClick={onClose}>Continue</PrimaryButton>
        </DialogActions>
      </Modal3D>
    </>
  );
};

export default FinalLevelCompleteModal;
