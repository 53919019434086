import Collider from '../@core/Collider';
import GameObject, { GameObjectProps, Position } from '../@core/GameObject';
import Sprite, { SpriteProps } from '../@core/Sprite';
import useGameObject from '../@core/useGameObject';
import useGameObjectEvent from '../@core/useGameObjectEvent';
import useSceneManager from '../@core/useSceneManager';
import useGame from '../@core/useGame';
import { DidCollectEvent } from '../@core/Collectable';
import { PubSubEvent } from '../@core/utils/createPubSub';
import { useApiLevelData } from '../context/ApiLevelDataContext';
import { UNEXPECTED_ERRORS } from '../@core/utils/gameErrors';
import waitForMs from '../@core/utils/waitForMs';
import Interactable, { InteractionEvent } from '../@core/Interactable';
import { Close } from '../@core/utils/movementHelpers';
import { useCommandError } from '../hooks/useCommandError';
import { useSound } from '../@core/Sound';
import soundData from '../soundData';
import { XMARK_LIST } from '../@core/utils/xmarkList';

export interface TypedPosition extends Position {
  positionType: 'horizontal' | 'vertical';
}

export type CloseEvent = PubSubEvent<'close', TypedPosition>;

const closeableData = [
  'chestOpened',
  'chestClosed',
  'singleDoorOpened',
  'singleDoorClosed',
  'gateOpened',
  'gateClosed'
];

function ItemCloseScript() {
  const { getRef } = useGameObject();
  const { publish, findGameObjectsByXY, findGameObjectByName, findCompoundObjectByXY } = useGame();

  const { levelData, setLevelData } = useSceneManager();
  const { apiLevelData } = useApiLevelData();
  const { triggerError } = useCommandError();
  const playChestCloseSound = useSound(soundData.chestClose);
  const playDoorCloseSound = useSound(soundData.doorClose);

  useGameObjectEvent<InteractionEvent>('interaction', async ({ obj, step }) => {
    const closeStep = step as Close;
    const close = closeStep.close;

    if (!close) return;

    if (!apiLevelData) return;

    const { levelGoals } = apiLevelData;

    const actionPoint = {
      x: getRef().transform.x,
      y: getRef().transform.y
    };
    const dirX = obj.transform.rotationX;
    const dirY = obj.transform.rotationY;

    const objectPosition = {
      x: actionPoint.x + dirX,
      y: actionPoint.y + dirY
    };

    const closeGoal = levelGoals.find(
      (goal) =>
        closeableData.includes(goal.data) &&
        XMARK_LIST.includes(goal?.type || '') &&
        goal?.action === 'close' &&
        goal?.position?.[0] === actionPoint.x &&
        goal?.position?.[1] === actionPoint.y
    );

    if (!closeGoal) {
      triggerError('Unexpected game error');
      return;
    }

    const targetObjectsPosition = {
      x: closeGoal.targetPosition?.[0],
      y: closeGoal.targetPosition?.[1]
    };

    if (!targetObjectsPosition.x || !targetObjectsPosition.y) {
      triggerError('Unexpected game error');
      return;
    }

    if (
      targetObjectsPosition.x !== objectPosition.x ||
      targetObjectsPosition.y !== objectPosition.y
    ) {
      triggerError(UNEXPECTED_ERRORS.close.position);
      return;
    }

    const targetObjects = findGameObjectsByXY(targetObjectsPosition.x, targetObjectsPosition.y);

    const closeableObject = targetObjects.find((o) =>
      closeableData.find((d) => o.name?.startsWith(d) || o.layer?.startsWith(d))
    );

    const closeablePartObjects = findGameObjectsByXY(objectPosition.x, objectPosition.y);

    const closeablePart = findCompoundObjectByXY(targetObjectsPosition.x, targetObjectsPosition.y);

    const closeablePartType = closeableData.find(
      (d) => closeablePart?.name?.startsWith(d) || closeablePart?.layer?.startsWith(d)
    );

    const closeableType = closeableData.find(
      (d) =>
        closeableObject?.name?.startsWith(d) ||
        closeableObject?.layer?.startsWith(d) ||
        closeablePart?.name?.startsWith(d)
    );

    if (!closeablePart && !closeableObject) {
      triggerError(UNEXPECTED_ERRORS.close.position);
      return;
    }

    const newLevelData = levelData.map((item) => {
      if (
        XMARK_LIST.includes(item.type || '') &&
        item.action === 'close' &&
        item.position?.[0] === actionPoint.x &&
        item.position?.[1] === actionPoint.y
      ) {
        return { ...item, score: (item.score += 1) };
      }
      return item;
    });

    setLevelData(newLevelData);

    closeablePartObjects.forEach((o) => {
      const collider = o.getComponent('Collider');
      if (collider) {
        o.getComponent('Collider').setWalkable(false);
      }
    });

    const playerObject = findGameObjectByName('player');
    const playerInteractable = playerObject?.getComponent('Interactable');
    const interactWithPosition = {
      x: closeablePart?.transform.x || closeableObject?.transform.x,
      y: closeablePart?.transform.y || closeableObject?.transform.y
    };

    const handleInteraction = async (soundFunction: () => void, command: string, delay: number) => {
      soundFunction();
      await playerInteractable.interact({
        position: {
          x: interactWithPosition.x,
          y: interactWithPosition.y
        },
        step: { state: 'close' },
        command
      });
      await waitForMs(delay);
    };

    const handleCloseable = async (soundFunction: () => void, delay1: number, delay2: number) => {
      await handleInteraction(
        soundFunction,
        `open-close-${closeableType || closeablePartType}`,
        delay1
      );
      publish<DidCollectEvent>('did-collect', newLevelData);
      getRef().setDisabled(true);
      return waitForMs(delay2);
    };

    if (obj === playerObject) {
      const name = closeablePart?.name || closeableObject?.name;
      if (name?.startsWith('chest')) {
        await handleCloseable(playChestCloseSound, 750, 150);
      } else if (name?.startsWith('singleDoor') || name?.startsWith('gate')) {
        await handleCloseable(playDoorCloseSound, 150, 150);
      }
    }
  });
  return null;
}

interface ItemCloseProps {
  props: GameObjectProps;
  state: string;
  spriteData: SpriteProps;
  itemName: string;
}

export default function ItemClose({ props, state, spriteData }: ItemCloseProps) {
  const name = `${state}-${props.x}-${props.y}`; // fallback name required for persisted flag

  return (
    <GameObject name={name} persisted={false} {...props} layer="item">
      <Sprite {...spriteData} state={state} />
      <Collider isTrigger />
      <Interactable type="close" />
      <ItemCloseScript />
    </GameObject>
  );
}
