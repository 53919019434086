import React from 'react';
import Button, { ButtonProps } from '@mui/material/Button';
import { SxProps, Theme } from '@mui/system';

interface CustomButtonProps extends ButtonProps {
  children: React.ReactNode;
  sx?: SxProps<Theme>;
}

const SecondaryButton: React.FC<CustomButtonProps> = ({ children, sx, ...otherProps }) => {
  const defaultStyles: SxProps<Theme> = {
    borderRadius: '8px',
    px: '16px',
    border: '1px solid #AF8B68',
    color: '#AF8B68',
    fontSize: '16px',
    fontWeight: 'bold',
    background: '#E4D0AB',
    boxShadow: '0px 0px 0px 1px #BD9C72, 0px 0px 0px 1px #AF8B68',
    transition: 'background 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      background: '#ECDEC4',
      boxShadow: '0px 0px 0px 1px #BD9C72, 0px 0px 0px 1px #AF8B68'
    },
    ...sx
  };

  return (
    <Button {...otherProps} sx={defaultStyles}>
      {children}
    </Button>
  );
};

export default SecondaryButton;
