import * as yup from 'yup';

export const loginSchema = yup.object().shape({
  email: yup.string().required('Email is required'),
  password: yup.string().required('Password is required')
});

export const loginOrgSchema = yup.object().shape({
  organization_slug: yup
    .string()
    .matches(/^[a-z\\-]{3,}$/, 'Invalid slug')
    .required('Organization Slug is required'),
  username: yup.string().required('Username is required'),
  password: yup.string().required('Password is required')
});
