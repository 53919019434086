import React, { useEffect, useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Button,
  TextField,
  Typography,
  Container,
  Box,
  Paper,
  Divider,
  IconButton,
  InputAdornment,
  useTheme
} from '@mui/material';
import { AuthService, LoginResponse, OrgStudentLoginForm } from '../client';
import codingforkids from '../assets/codingforkids-cat.png';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { AxiosError } from 'axios';
import { useSnackBarContext } from '../context/SnackBarContext';
import { useLoginOrgForm } from '../hooks/useLoginOrgForm';
import { Controller, FieldError } from 'react-hook-form';

export default function LoginOrgPage() {
  const { organizationSlug } = useParams<{ organizationSlug?: string }>();

  const {
    control,
    formState: { errors, isValid, isSubmitting },
    handleSubmit,
    trigger,
    setValue,
    setError
  } = useLoginOrgForm();

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { createSnackBar } = useSnackBarContext();

  useEffect(() => {
    if (organizationSlug) {
      setValue('organization_slug', organizationSlug);
    }
  }, [organizationSlug, setValue]);

  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();

  const loginMutation = useMutation<LoginResponse, AxiosError, OrgStudentLoginForm>(
    AuthService.loginOrganizationStudentApiAuthLoginOrgPost,
    {
      onSuccess: (loginResponse) => {
        queryClient.invalidateQueries('currentUser');
        if (loginResponse.last_played) {
          navigate(
            `/play/${loginResponse.last_played.course_id}/${loginResponse.last_played.level_slug}`
          );
        } else {
          navigate('/');
        }
      },
      onError: (error: AxiosError) => {
        if (error.status === 404) {
          createSnackBar({
            content: 'Username or organization not found.',
            autoHide: true,
            severity: 'error'
          });
        } else if (error.status === 401) {
          createSnackBar({
            content: 'Invalid username or password.',
            autoHide: true,
            severity: 'error'
          });
        } else {
          createSnackBar({
            content: 'Logging in failed, please contact support.',
            autoHide: true,
            severity: 'error'
          });
        }
      }
    }
  );

  const triggerWithApi = async () => {
    const manualErrors = Object.entries(errors).filter(([_, value]) => value.type === 'manual');
    await trigger();
    manualErrors.forEach(([key, value]) => {
      setError(key as never, value as FieldError);
    });
  };

  const onSubmitWrapper = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isValid) {
      await triggerWithApi();
    }
    if (isValid && Object.keys(errors).length === 0) {
      handleSubmit(onSubmit)(event);
    }
  };

  const onSubmit = (data: OrgStudentLoginForm) => {
    loginMutation.mutate(data);
  };

  const orgSlugHelperText =
    'Please enter a unique identifier for your organization, using only lowercase letters and dashes. You can ask your teacher what it is.';

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}
    >
      <Paper
        sx={{
          p: 3,
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2
        }}
      >
        <img src={codingforkids} height={64} />
        <Typography component="h1" variant="h5">
          School Log in
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={onSubmitWrapper}>
          <Controller
            name="organization_slug"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                id="organizationSlug"
                label="Organization Slug"
                autoComplete="organizationSlug"
                error={!!errors.organization_slug}
                helperText={
                  errors.organization_slug
                    ? errors.organization_slug.message
                    : !organizationSlug && orgSlugHelperText
                }
                disabled={!!organizationSlug}
                autoFocus={!organizationSlug}
              />
            )}
          />
          <Controller
            name="username"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                id="username"
                label="Username"
                autoComplete="username"
                error={!!errors.username}
                helperText={errors.username?.message}
                autoFocus={!!organizationSlug}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                error={!!errors.password}
                helperText={errors.password?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((prev) => !prev)}
                        edge="end"
                        sx={{ color: theme.palette.text.secondary }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            )}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 3 }}
            size="large"
            disabled={isSubmitting || loginMutation.isLoading}
          >
            {isSubmitting || loginMutation.isLoading ? 'Signing In...' : 'Sign In'}
          </Button>
          {loginMutation.isError && (
            <Typography color="error" variant="body2" sx={{ mb: 3 }}>
              Error logging in. Please try again.
            </Typography>
          )}
          <Divider component="div" />
          <Typography variant="body2" sx={{ mt: 2 }}>
            Don't have an account? Ask your teacher to create one for you.
          </Typography>
        </Box>
      </Paper>
    </Container>
  );
}
