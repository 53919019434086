import { DialogActions, DialogContentText, TextField, Typography } from '@mui/material';
import { useGenericModal } from '../context/GenericModalContext';
import { useState, useEffect, useRef } from 'react';
import PrimaryButton from './GameUI/PrimaryButton';
import Modal from './GameUI/Modal';
import SecondaryButton from './GameUI/SecondaryButton';
import ContentWrapper from './GameUI/ContentWrapper';

interface LoadCodeModalProps {
  onLoad: (code: string) => void;
}

const LoadCodeModal: React.FC<LoadCodeModalProps> = ({ onLoad }) => {
  const { openModal: open, setOpenModal } = useGenericModal('loadCode');
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (selectedFile) {
      handleLoad();
    }
  }, [selectedFile]);

  const onClose = () => {
    setSelectedFile(null);
    setOpenModal(false);
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSelectedFile(file);
      event.target.value = ''; // Reset the input
    }
  };

  const handleLoad = async () => {
    if (!selectedFile) return;

    try {
      const fileContent = await readCodeAsText(selectedFile);
      onLoad(fileContent);
      onClose();
    } catch (error) {
      console.error('Error reading file:', error);
    } finally {
      setSelectedFile(null); // Ensure selectedFile is reset for clean state management
    }
  };

  const handleChooseFileClick = () => {
    fileInputRef.current?.click();
  };

  const readCodeAsText = (file: File): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = reject;
      reader.readAsText(file);
    });

  return (
    <Modal open={open} onClose={onClose}>
      <ContentWrapper sx={{ py: 2 }}>
        <Typography variant="h5">Load My Code</Typography>
        <DialogContentText color="white" sx={{ marginTop: 2 }}>
          <i>Load your code by clicking on the 'Choose File' button below.</i>
        </DialogContentText>
        <TextField
          inputRef={fileInputRef}
          type="file"
          InputProps={{ style: { display: 'none' } }}
          onChange={handleFileChange}
        />
      </ContentWrapper>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <SecondaryButton onClick={onClose} sx={{ width: '120px' }}>
          Cancel
        </SecondaryButton>
        <PrimaryButton onClick={handleChooseFileClick} sx={{ width: '160px' }}>
          Choose File
        </PrimaryButton>
      </DialogActions>
    </Modal>
  );
};

export default LoadCodeModal;
