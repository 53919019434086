import { createContext, useContext, useState } from 'react';

interface CodeContextType {
  code: string;
  setCode: React.Dispatch<React.SetStateAction<string>>;
}

const  CodeContext = createContext<CodeContextType | null>(null);

export function useCode(): CodeContextType {
  return useContext(CodeContext) as CodeContextType;
}

export function CodeProvider({ children }: { children: React.ReactNode }) {
  const [code, setCode] = useState<string>("");

  const value: CodeContextType = {
    code,
    setCode
  };

  return <CodeContext.Provider value={value}>{children}</CodeContext.Provider>;
}
