import { SoundProps } from './@core/Sound';

const soundData: { [index: string]: SoundProps } = {
  eating: {
    // "Chewing" by InspectorJ - freesound.org
    src: '/assets/sfx/eating.mp3'
  },
  drinking: {
    // "Drinking" by dersuperanton - freesound.org"
    src: '/assets/sfx/drinking.mp3'
  },
  footstep: {
    src: '/assets/sfx/footstep.mp3',
    volume: 0.75
  },
  good: {
    src: '/assets/sfx/good.mp3',
    volume: 0.75
  },
  errorMovement: {
    src: '/assets/sfx/error-movement.mp3',
    volume: 0.75
  },
  push: {
    src: '/assets/sfx/push.mp3',
    volume: 0.75
  },
  success: {
    src: '/assets/sfx/success.mp3',
    volume: 0.75
  },
  water: {
    src: '/assets/sfx/water-pour.mp3',
    volume: 0.5
  },
  collectWater: {
    src: '/assets/sfx/water-refill.mp3',
    volume: 0.75
  },
  chestOpen: {
    src: '/assets/sfx/chest_open.mp3',
    volume: 0.75
  },
  chestClose: {
    src: '/assets/sfx/chest_close.mp3',
    volume: 0.75
  },
  doorOpen: {
    src: '/assets/sfx/door_open.mp3',
    volume: 0.75
  },
  doorClose: {
    src: '/assets/sfx/door_close.mp3',
    volume: 0.75
  },
  placeItem: {
    src: '/assets/sfx/place_item.mp3',
    volume: 0.75
  },
  placeFood: {
    src: '/assets/sfx/stack-food_drop.mp3',
    volume: 0.75
  },
  removeItem: {
    src: '/assets/sfx/remove_item.mp3',
    volume: 0.75
  },
  buildWood: {
    src: '/assets/sfx/wood_sound.mp3',
    volume: 0.75
  },
  buildStone: {
    src: '/assets/sfx/push.mp3',
    volume: 0.75
  },
  hayStack: {
    src: '/assets/sfx/stack-food_drop.mp3',
    volume: 0.75
  },
  combineLiquid: {
    src: '/assets/sfx/combine_liquid.mp3',
    volume: 0.75
  },
  sowSeeds: {
    src: '/assets/sfx/sow_seeds.mp3',
    volume: 0.75
  }
};

export default soundData;
