import { useContext } from 'react';
import PyodideContext, { PyodideContextType } from '../context/PyodideContext';

const usePyodide = (): PyodideContextType => {
  const context = useContext(PyodideContext);

  if (!context) {
    throw new Error(
      'Pyodide instance not found in the context. Make sure you are using PyodideProvider.'
    );
  }

  return context;
};

export default usePyodide;
