import React, { useState } from 'react';
import { useMutation, useQueryClient } from 'react-query';
import { NavLink, useNavigate } from 'react-router-dom';
import {
  Button,
  TextField,
  Typography,
  Container,
  Box,
  Paper,
  Divider,
  Grid,
  IconButton,
  InputAdornment,
  useTheme
} from '@mui/material';
import { AuthService, LoginForm, LoginResponse } from '../client';
import { GoogleIcon } from '../components/GoogleIcon';
import codingforkids from '../assets/codingforkids-cat.png';
import SchoolIcon from '@mui/icons-material/School';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { AxiosError } from 'axios';
import { useSnackBarContext } from '../context/SnackBarContext';
import { Controller, FieldError } from 'react-hook-form';
import { useLoginForm } from '../hooks/useLoginForm';

export default function LoginPage() {
  const {
    control,
    formState: { errors, isValid, isSubmitting },
    handleSubmit,
    trigger,
    setError
  } = useLoginForm();

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { createSnackBar } = useSnackBarContext();

  const [showPassword, setShowPassword] = useState(false);
  const theme = useTheme();

  const loginMutation = useMutation<LoginResponse, AxiosError, LoginForm>(
    AuthService.loginApiAuthLoginPost,
    {
      onSuccess: (loginResponse) => {
        queryClient.invalidateQueries('currentUser');
        if (loginResponse.last_played) {
          navigate(
            `/play/${loginResponse.last_played.course_id}/${loginResponse.last_played.level_slug}`
          );
        } else {
          navigate('/');
        }
      },
      onError: (error: AxiosError) => {
        if (error.status === 404) {
          createSnackBar({
            content: 'Username not found.',
            autoHide: true,
            severity: 'error'
          });
        } else if (error.status === 401) {
          createSnackBar({
            content: 'Invalid username or password.',
            autoHide: true,
            severity: 'error'
          });
        } else {
          createSnackBar({
            content: 'Logging in failed, please contact support.',
            autoHide: true,
            severity: 'error'
          });
        }
      }
    }
  );

  const triggerWithApi = async () => {
    const manualErrors = Object.entries(errors).filter(([_, value]) => value.type === 'manual');
    await trigger();
    manualErrors.forEach(([key, value]) => {
      setError(key as never, value as FieldError);
    });
  };

  const onSubmitWrapper = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isValid) {
      await triggerWithApi();
    }
    if (isValid && Object.keys(errors).length === 0) {
      handleSubmit(onSubmit)(event);
    }
  };

  const onSubmit = (data: LoginForm) => {
    loginMutation.mutate(data);
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}
    >
      <Paper
        sx={{
          p: 3,
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2
        }}
      >
        <img src={codingforkids} height={64} />
        <Typography component="h1" variant="h5">
          Log in
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={onSubmitWrapper}>
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                id="email"
                label="Username or Email Address"
                autoComplete="email"
                autoFocus
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            )}
          />
          <Controller
            name="password"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type={showPassword ? 'text' : 'password'}
                id="password"
                autoComplete="current-password"
                error={!!errors.password}
                helperText={errors.password?.message}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword((prev) => !prev)}
                        edge="end"
                        sx={{ color: theme.palette.text.secondary }}
                      >
                        {showPassword ? <VisibilityOff /> : <Visibility />}
                      </IconButton>
                    </InputAdornment>
                  )
                }}
              />
            )}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3 }}
            size="large"
            disabled={isSubmitting || loginMutation.isLoading}
          >
            {isSubmitting || loginMutation.isLoading ? 'Signing In...' : 'Sign In'}
          </Button>
          <Button
            href="/api/auth/google/authorize"
            fullWidth
            variant="contained"
            sx={{ mt: 2, mb: 3 }}
            size="large"
            startIcon={<GoogleIcon />}
          >
            Sign In with Google
          </Button>
          <Divider component="div" />
          <Button
            href="/login-org"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            size="large"
            startIcon={<SchoolIcon />}
          >
            Sign In For School
          </Button>
          {loginMutation.isError && (
            <Typography color="error" variant="body2" sx={{ mt: 2 }}>
              Error logging in. Please try again.
            </Typography>
          )}
          <Grid container>
            <Grid item xs>
              <Typography variant="body2">
                Don't have an account? <NavLink to="/signup">Sign up</NavLink>
              </Typography>
            </Grid>
          </Grid>
          <Grid container>
            <Grid item xs>
              <NavLink to="/forgot-password">
                <Typography variant="body2">Forgot password?</Typography>
              </NavLink>
            </Grid>
          </Grid>
        </Box>
      </Paper>
    </Container>
  );
}
