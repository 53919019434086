import React, { createContext } from 'react';
import { Route, Routes } from 'react-router-dom';

import Layout from './Layout';
import Login from './Login';
import LoginOrg from './LoginOrg';
import SignUp from './SignUp';
import Home from './Home';
import ProtectedRoute from '../components/ProtectedRoute';
import TermsOfService from './TermsOfService';
import PrivacyPolicy from './PrivacyPolicy';
import Profile from './profile/Profile';
import ProfileDetails from './profile/ProfileDetails';
import ProfileBilling from './profile/ProfileBilling';
import ProfileStudentUsers from './profile/StudentUsers';
import ProfileAddEditStudentUser from './profile/AddEditStudentUser';

import { ConfirmProvider } from 'material-ui-confirm';
import { CurrentUserAndStudentResponse, UserType } from '../client';
import { PyodideProvider } from '../context/PyodideContext';
import { CodeProvider } from '../context/CodeContext';
import { GenericModalProvider } from '../context/GenericModalContext';
import { ApiLevelDataProvider } from '../context/ApiLevelDataContext';
import Purchase from './Purchase';
import CertificateViewer from '../components/Certificate/CertificateViewer';
import StudentPage from './profile/StudentPage';
import ResetPassword from './ResetPassword';
import ForgotPassword from './ForgotPassword';
import Unsubscribed from './Unsubscribed';
import { OutputProvider } from '../context/OutputContext';
import InstitutionalPurchase from './InstitutionalPurchase';
import OrganizationTeacherUsers from './organization/Teachers';
import OrganizationAddEditTeacherUser from './organization/AddEditTeacher';
import OrganizationAddEditStudentUser from './organization/Student/AddEditStudentUser';
import OrganizationStudentUsers from './organization/Student/Students';
import Checkout from './Checkout';
import Classes from './organization/Classes/Classes';
import AddEditClass from './organization/Classes/AddEditClass';
import ClassPage from './organization/Classes/ClassPage';
import Impersonate from './Impersonate';
import OrganizationDetails from './organization/OrganizationDetails';
import MyCertificates from './MyCertificates';
import QuizPage from './quiz/QuizPage';
import Question from './quiz/Question';
import Courses from './Courses';
import { useCurrentUser } from '../hooks/useCurrentUser';
import MyBadges from './MyBadges';
import { TipProvider } from '../context/TipProvider';

export const UserContext = createContext<CurrentUserAndStudentResponse | undefined>(undefined);

// TODO: fix this by using strings as enums
export const UserTypeMap = {
  [UserType._0]: 'parent',
  [UserType._1]: 'teacher',
  [UserType._2]: 'admin'
};

function App() {
  const { currentUser } = useCurrentUser();

  return (
    <UserContext.Provider value={currentUser}>
      <ApiLevelDataProvider>
        <OutputProvider>
          <PyodideProvider>
            <CodeProvider>
              <ConfirmProvider>
                <GenericModalProvider>
                  <TipProvider>
                    <Routes>
                      <Route element={<Layout />}>
                        <Route path="badges/:studentId" element={<MyBadges />} />
                        <Route path="certificates/:studentId" element={<MyCertificates />} />
                        <Route path="certificate/:certificateId" element={<CertificateViewer />} />
                        <Route element={<ProtectedRoute />}>
                          <Route path="profile" element={<Profile />}>
                            <Route path="details" element={<ProfileDetails />} />
                            <Route path="students" element={<ProfileStudentUsers />} />
                            <Route path="students/add" element={<ProfileAddEditStudentUser />} />
                            <Route
                              path="students/:studentId/edit"
                              element={<ProfileAddEditStudentUser />}
                            />
                            <Route path="students/:studentId" element={<StudentPage />} />
                            <Route path="billing" element={<ProfileBilling />} />
                          </Route>
                          <Route path="organization" element={<Profile />}>
                            <Route path="details" element={<OrganizationDetails />} />
                            <Route path="teachers" element={<OrganizationTeacherUsers />} />
                            <Route
                              path="teachers/add"
                              element={<OrganizationAddEditTeacherUser />}
                            />
                            <Route
                              path="teachers/:teacherId/edit"
                              element={<OrganizationAddEditTeacherUser />}
                            />
                            <Route path="classes" element={<Classes />} />
                            <Route path="classes/add" element={<AddEditClass />} />
                            <Route path="classes/:classId" element={<ClassPage />} />
                            <Route path="classes/:classId/edit" element={<AddEditClass />} />
                            <Route path="students" element={<OrganizationStudentUsers />} />
                            <Route
                              path="students/add"
                              element={<OrganizationAddEditStudentUser />}
                            />
                            <Route
                              path="students/:studentId/edit"
                              element={<OrganizationAddEditStudentUser />}
                            />
                            <Route path="students/:studentId" element={<StudentPage />} />
                          </Route>
                          <Route path="quiz/:courseId/:chapterId" element={<QuizPage />}>
                            <Route path="question/:questionId" element={<Question />} />
                          </Route>
                        </Route>
                        <Route index element={<Home />} />
                        <Route path="unsubscribed" element={<Unsubscribed />} />
                        <Route path="tos" element={<TermsOfService />} />
                        <Route path="privacy" element={<PrivacyPolicy />} />
                        <Route path="checkout" element={<Checkout />} />
                        <Route path="purchase" element={<Purchase />} />
                        <Route path="courses" element={<Courses />} />
                        <Route path="schools" element={<InstitutionalPurchase />} />
                        <Route path="login" element={<Login />} />
                        <Route path="login-org" element={<LoginOrg />} />
                        <Route path="login-org/:organizationSlug" element={<LoginOrg />} />
                        <Route path="signup" element={<SignUp />} />
                        <Route path="forgot-password" element={<ForgotPassword />} />
                        <Route path="reset-password" element={<ResetPassword />} />
                        <Route path="impersonate" element={<Impersonate />} />
                        <Route path="play/:courseSlug/:levelSlug" element={<Home />} />
                      </Route>
                    </Routes>
                  </TipProvider>
                </GenericModalProvider>
              </ConfirmProvider>
            </CodeProvider>
          </PyodideProvider>
        </OutputProvider>
      </ApiLevelDataProvider>
    </UserContext.Provider>
  );
}

export default App;
