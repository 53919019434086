import React from 'react';
import { Box, Button, Grid, Paper, Stack, TextField, MenuItem } from '@mui/material';
import { useForm, Controller, FieldError } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { OrganizationInput, OrganizationService } from '../../client';
import { useSnackBarContext } from '../../context/SnackBarContext';
import { Loader } from '../Loader';
import { schoolTypes } from '../../schoolData';

export const AddEditOrganizationDetails = () => {
  const { createSnackBar } = useSnackBarContext();
  const queryClient = useQueryClient();

  const { data: organization, isLoading: isOrganizationLoading } = useQuery(['organization'], () =>
    OrganizationService.currentOrganizationApiOrganizationCurrentGet()
  );

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting, isValid },
    trigger,
    setError
  } = useForm<OrganizationInput>({
    defaultValues: organization || {}
  });

  const updateOrganizationMutation = useMutation(
    (updatedOrganization: OrganizationInput) =>
      OrganizationService.updateOrganizationApiOrganizationCurrentPut(updatedOrganization),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('organizations');
        createSnackBar({
          content: 'Organization details updated successfully.',
          autoHide: true,
          severity: 'success'
        });
      },
      onError: () => {
        createSnackBar({
          content: 'Editing organization failed',
          autoHide: true,
          severity: 'error'
        });
      }
    }
  );

  const triggerWithApi = async () => {
    const manualErrors = Object.entries(errors).filter(([_, value]) => value.type === 'manual');
    await trigger();
    manualErrors.forEach(([key, value]) => {
      setError(key as never, value as FieldError);
    });
  };

  const onSubmitWrapper = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();

    if (!isValid) {
      await triggerWithApi();
    }
    if (isValid && Object.keys(errors).length === 0) {
      handleSubmit(onSubmit)(event);
    }
  };

  const onSubmit = async (data: OrganizationInput) => {
    try {
      await updateOrganizationMutation.mutateAsync(data);
    } catch (error) {
      console.log(error);
    }
  };

  if (isOrganizationLoading) return <Loader />;
  if (!organization) return <Box component="div">Organization not found</Box>;

  return (
    <Paper sx={{ p: 5, mt: 4 }}>
      <Box component="form" onSubmit={onSubmitWrapper} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Stack gap={4}>
              <Controller
                name="name"
                control={control}
                defaultValue={organization?.name || ''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.name}
                    helperText={errors.name?.message}
                    fullWidth
                    label="Organization Name"
                    margin="none"
                    required
                  />
                )}
              />
              <Controller
                name="slug"
                control={control}
                defaultValue={organization?.slug || ''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.slug}
                    helperText={
                      errors.slug?.message ??
                      'The slug is a short token that your students will be using to log in to their school accounts.'
                    }
                    fullWidth
                    label="Slug"
                    margin="none"
                    required
                  />
                )}
              />
              <Controller
                name="email_domain"
                control={control}
                defaultValue={organization?.email_domain || ''}
                render={({ field }) => (
                  <TextField
                    {...field}
                    error={!!errors.email_domain}
                    helperText={
                      errors.email_domain?.message ??
                      'The e-mail domain can be used to automatically associate students to your organization during account creation.'
                    }
                    fullWidth
                    label="E-mail Domain"
                    margin="none"
                  />
                )}
              />
              <Controller
                name="organization_type"
                control={control}
                defaultValue={organization?.organization_type || 'private_school'}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    fullWidth
                    label="Organization Type"
                    margin="none"
                    error={!!errors.organization_type}
                    helperText={errors.organization_type?.message}
                  >
                    {schoolTypes.map((option) => (
                      <MenuItem key={option.value} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </Stack>
          </Grid>
          <Grid item xs={12}>
            <Box component="div" display="flex" justifyContent="flex-end" mt={2}>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting || !!errors.organization_type}
              >
                {isSubmitting || updateOrganizationMutation.isLoading ? 'Saving...' : 'Save'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
