import { Box, Container, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { AddEditOrgStudentForm } from '../../../components/Organization/Student/AddEditOrgStudentForm';
import { AddEditClassForm } from '../../../components/Organization/Class/AddEditClassForm';

export default function AddEditClass() {
  const { classId } = useParams();
  return (
    <Container maxWidth="lg" sx={{ mt: 3, mb: 14 }}>
      <Box component="div" display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" gutterBottom>
          {classId ? 'Edit Class' : 'Add Class'}
        </Typography>
      </Box>
      <AddEditClassForm />
    </Container>
  );
}
