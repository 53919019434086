import Collider from '../@core/Collider';
import GameObject, { GameObjectProps, Position } from '../@core/GameObject';
import Sprite, { SpriteProps } from '../@core/Sprite';
import useGameObject from '../@core/useGameObject';
import useGameObjectEvent from '../@core/useGameObjectEvent';
import useSceneManager from '../@core/useSceneManager';
import useGame from '../@core/useGame';
import { DidCollectEvent } from '../@core/Collectable';
import { useApiLevelData } from '../context/ApiLevelDataContext';
import { UNEXPECTED_ERRORS } from '../@core/utils/gameErrors';
import waitForMs from '../@core/utils/waitForMs';
import Interactable, { InteractionEvent } from '../@core/Interactable';
import { Open } from '../@core/utils/movementHelpers';
import { useCommandError } from '../hooks/useCommandError';
import { useSound } from '../@core/Sound';
import soundData from '../soundData';
import { XMARK_LIST } from '../@core/utils/xmarkList';

export interface TypedPosition extends Position {
  positionType: 'horizontal' | 'vertical';
}

const openableData = [
  'singleDoorOpened',
  'singleDoorClosed',
  'gateOpened',
  'gateClosed',
  'chestOpened',
  'chestClosed'
];

function ItemOpenScript() {
  const { getRef } = useGameObject();
  const { publish, findGameObjectsByXY, findGameObjectByName, findCompoundObjectByXY } = useGame();

  const { levelData, setLevelData } = useSceneManager();
  const { apiLevelData } = useApiLevelData();
  const { triggerError } = useCommandError();
  const playChestOpenSound = useSound(soundData.chestOpen);
  const playDoorOpenSound = useSound(soundData.doorOpen);

  useGameObjectEvent<InteractionEvent>('interaction', async ({ obj, step }) => {
    const openStep = step as Open;
    const open = openStep.open;

    if (!open) return;

    if (!apiLevelData) return;

    const { levelGoals } = apiLevelData;

    const actionPoint = {
      x: getRef().transform.x,
      y: getRef().transform.y
    };
    const dirX = obj.transform.rotationX;
    const dirY = obj.transform.rotationY;

    const objectPosition = {
      x: actionPoint.x + dirX,
      y: actionPoint.y + dirY
    };

    const openGoal = levelGoals.find(
      (goal) =>
        openableData.includes(goal.data) &&
        XMARK_LIST.includes(goal?.type || '') &&
        goal?.action === 'open' &&
        goal?.position?.[0] === actionPoint.x &&
        goal?.position?.[1] === actionPoint.y
    );

    if (!openGoal) {
      triggerError('Unexpected game error');
      return;
    }

    const targetObjectsPosition = {
      x: openGoal.targetPosition?.[0],
      y: openGoal.targetPosition?.[1]
    };

    if (!targetObjectsPosition.x || !targetObjectsPosition.y) {
      triggerError('Unexpected game error');
      return;
    }

    if (
      targetObjectsPosition.x !== objectPosition.x ||
      targetObjectsPosition.y !== objectPosition.y
    ) {
      triggerError(UNEXPECTED_ERRORS.open.position);
      return;
    }

    const targetObjects = findGameObjectsByXY(targetObjectsPosition.x, targetObjectsPosition.y);

    const openableObject = targetObjects.find((o) =>
      openableData.find((d) => o.name?.startsWith(d) || o.layer?.startsWith(d))
    );

    const openablePartObjects = findGameObjectsByXY(objectPosition.x, objectPosition.y);

    const openablePart = findCompoundObjectByXY(targetObjectsPosition.x, targetObjectsPosition.y);

    const openableType = openableData.find(
      (d) =>
        openablePart?.name?.startsWith(d) ||
        openableObject?.layer?.startsWith(d) ||
        openableObject?.name?.startsWith(d)
    );

    if (!openablePart && !openableObject) {
      triggerError(UNEXPECTED_ERRORS.open.position);
      return;
    }

    const newLevelData = levelData.map((item) => {
      if (
        XMARK_LIST.includes(item.type || '') &&
        item.action === 'open' &&
        item.position?.[0] === actionPoint.x &&
        item.position?.[1] === actionPoint.y
      ) {
        return { ...item, score: (item.score += 1) };
      }
      return item;
    });

    setLevelData(newLevelData);

    openablePartObjects.forEach((o) => {
      const collider = o.getComponent('Collider');
      if (collider) {
        o.getComponent('Collider').setWalkable(true);
      }
    });

    const playerObject = findGameObjectByName('player');
    const playerInteractable = playerObject?.getComponent('Interactable');
    const interactWithPosition = {
      x: openablePart?.transform.x || openableObject?.transform.x,
      y: openablePart?.transform.y || openableObject?.transform.y
    };

    const handleInteraction = async (soundFunction: () => void, command: string, delay: number) => {
      soundFunction();
      await playerInteractable.interact({
        position: { x: interactWithPosition.x, y: interactWithPosition.y },
        step: { state: 'open' },
        command
      });
      await waitForMs(delay);
    };

    const handleOpenable = async (soundFunction: () => void, delay1: number, delay2: number) => {
      await handleInteraction(soundFunction, `open-close-${openableType}`, delay1);
      publish<DidCollectEvent>('did-collect', newLevelData);
      getRef().setDisabled(true);
      return waitForMs(delay2);
    };

    if (obj === playerObject) {
      const name = openablePart?.name || openableObject?.name;
      if (name?.startsWith('chest')) {
        await handleOpenable(playChestOpenSound, 1500, 700);
      } else if (name?.startsWith('singleDoor') || name?.startsWith('gate')) {
        await handleOpenable(playDoorOpenSound, 150, 150);
      }
    }
  });

  return null;
}

interface ItemOpenProps {
  props: GameObjectProps;
  state: string;
  spriteData: SpriteProps;
  itemName: string;
}

export default function ItemOpen({ props, state, spriteData }: ItemOpenProps) {
  const name = `${state}-${props.x}-${props.y}`; // fallback name required for persisted flag

  return (
    <GameObject name={name} persisted={false} {...props} layer="item">
      <Sprite {...spriteData} state={state} />
      <Collider isTrigger />
      <Interactable type="open" />
      <ItemOpenScript />
    </GameObject>
  );
}
