import Collider from '../@core/Collider';
import GameObject, { GameObjectProps } from '../@core/GameObject';
import Sprite, { SpriteProps } from '../@core/Sprite';
import useGameObject from '../@core/useGameObject';
import useGameObjectEvent from '../@core/useGameObjectEvent';
import useSceneManager from '../@core/useSceneManager';
import useGame from '../@core/useGame';
import { useApiLevelData } from '../context/ApiLevelDataContext';
import { UNEXPECTED_ERRORS } from '../@core/utils/gameErrors';
import { DidCollectEvent } from '../@core/Collectable';
import { useSound } from '../@core/Sound';
import soundData from '../soundData';
import waitForMs from '../@core/utils/waitForMs';
import { useOutput } from '../context/OutputContext';
import Interactable, { InteractionEvent } from '../@core/Interactable';
import { Collecting } from '../@core/utils/movementHelpers';
import { useCommandError } from '../hooks/useCommandError';
import { XMARK_LIST } from '../@core/utils/xmarkList';

function CollectWaterScript() {
  const { getRef } = useGameObject();
  const { publish } = useGame();
  const { levelData, setLevelData, inventory, setInventoryData } = useSceneManager();
  const { resetCommandsQueue } = useOutput();
  const { apiLevelData } = useApiLevelData();
  const playWaterRefillSound = useSound(soundData.collectWater);
  const { triggerError } = useCommandError();

  useGameObjectEvent<InteractionEvent>('interaction', async ({ obj, step }) => {
    const collectStep = step as Collecting;
    const collect = collectStep.collect;

    if (!collect) return;

    if (!apiLevelData) return;

    const { levelGoals } = apiLevelData;

    const actionPoint = {
      x: getRef().transform.x,
      y: getRef().transform.y
    };
    const dirX = obj.transform.rotationX;
    const dirY = obj.transform.rotationY;

    const wellPosition = {
      x: actionPoint.x + dirX,
      y: actionPoint.y + dirY
    };

    const collectObjectGoal = levelGoals.find(
      (goal) =>
        // collectableData.includes(goal.data) &&
        XMARK_LIST.includes(goal.type || '') &&
        goal.action === 'collect' &&
        goal?.position?.[0] === actionPoint.x &&
        goal?.position?.[1] === actionPoint.y
    );

    if (!collectObjectGoal) {
      triggerError('Unexpected game error');
      return;
    }

    const targetObjectsPosition = {
      x: collectObjectGoal.targetPosition?.[0],
      y: collectObjectGoal.targetPosition?.[1]
    };

    if (
      (!targetObjectsPosition.x && targetObjectsPosition.x !== 0) ||
      (!targetObjectsPosition.y && targetObjectsPosition.y !== 0)
    ) {
      triggerError('Unexpected game error');
      return;
    }

    const inventoryItem = inventory.find((item) => item.type === collectObjectGoal?.cost?.type);

    if (targetObjectsPosition.x !== wellPosition.x || targetObjectsPosition.y !== wellPosition.y) {
      triggerError(UNEXPECTED_ERRORS.collect.water.position);
      return;
    }

    const newLevelData = levelData.map((item) => {
      if (
        XMARK_LIST.includes(item.type!) &&
        item.action === 'collect' &&
        item.position?.[0] === getRef().transform.x &&
        item.position?.[1] === getRef().transform.y
      ) {
        return { ...item, score: (item.score += 1) };
      } else if (item.type === collectObjectGoal?.cost?.type) {
        return { ...item, score: (item.score += 1) };
      }
      return item;
    });

    setInventoryData(
      inventory.map((item) => {
        if (item.type === inventoryItem?.type) {
          return { ...item, count: (item.count += 1) };
        }
        return item;
      })
    );
    playWaterRefillSound();
    setLevelData(newLevelData);
    await waitForMs(2500);
    publish<DidCollectEvent>('did-collect', newLevelData);
    getRef().setDisabled(true);
    return await waitForMs(100);
  });
  return null;
}

interface ItemCollectWaterProps {
  props: GameObjectProps;
  state: string;
  spriteData: SpriteProps;
  itemName: string;
}

export default function ItemCollectWater({ props, state, spriteData }: ItemCollectWaterProps) {
  const name = `${state}-${props.x}-${props.y}`; // fallback name required for persisted flag

  return (
    <GameObject name={name} persisted={false} {...props} layer="item">
      <Sprite {...spriteData} state={state} />
      <Collider isTrigger />
      <Interactable type="collect" />
      <CollectWaterScript />
    </GameObject>
  );
}
