import React, { createContext, useState, useEffect } from 'react';
import { useConfirm } from 'material-ui-confirm';

import { PyodideInterface } from 'pyodide';
import type { PyProxy as PyProxyType } from 'pyodide/ffi';
import { Command } from '../@core/utils/movementHelpers';
import { Box } from '@mui/material';
import { useApiLevelData } from './ApiLevelDataContext';
import { Constant } from '../client';
import { Primitive, detectDataType } from '../@core/utils/commandHelper';
import { useOutput } from './OutputContext';

export interface ErrorAnnotation {
  row: number;
  column: number;
  text: string;
  type: 'error' | 'warning' | 'info';
}

export interface PyodideContextType {
  pyodide: PyodideInterface | null;
  runScript: (code: string) => Promise<void>;
  isLoading: boolean;
  errorAnnotations: ErrorAnnotation[];
  setErrorAnnotations: React.Dispatch<React.SetStateAction<ErrorAnnotation[]>>;
  errorMessage: string | null;
  setErrorMessage: React.Dispatch<React.SetStateAction<string | null>>;
}

const PyodideContext = createContext<PyodideContextType>({
  pyodide: null,
  runScript: async () => {
    throw new Error(
      'Pyodide instance not found in the context. Make sure you are using PyodideProvider.'
    );
  },
  isLoading: false,
  errorAnnotations: [],
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setErrorAnnotations: () => {},
  errorMessage: null,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setErrorMessage: () => {}
});

interface PyodideProviderProps {
  children: React.ReactNode;
}

export const PyodideProvider: React.FC<PyodideProviderProps> = ({ children }) => {
  const [, setError] = useState<string>();
  const [pyodide, setPyodide] = useState<PyodideInterface | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [errorAnnotations, setErrorAnnotations] = useState<ErrorAnnotation[]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const { apiLevelData } = useApiLevelData();
  const { resetCommandsQueue, setIsCodeExecuted } = useOutput();

  const confirm = useConfirm();

  const loadPyodide = async (): Promise<PyodideInterface> => {
    const window = (globalThis as any).window;
    return await window.loadPyodide({
      stderr: (err: string) => {
        setError(err);
      }
    });
  };

  const runScript = async (code: string) => {
    if (!pyodide) {
      return;
    }

    try {
      setErrorMessage(null);
      return await pyodide.runPythonAsync(code);
    } catch (e) {
      console.log(e);
      const message = (e as { message: string }).message;
      const lines = message.trim().split('\n');
      const errorLine = lines[lines.length - 1];
      setErrorMessage(message);

      try {
        // now let's get the error annotation using a regular expression.
        // here is an example Python line for an error annotation: "  File "<exec>", line 2, in <module>"

        const errorRow = parseInt(message.match(/File "<exec>", line (\d+)/)![1], 10) - 1;

        setErrorAnnotations([
          {
            row: errorRow,
            column: 0,
            text: errorLine,
            type: 'error'
          }
        ]);
      } catch (e) {
        console.log(e);
      }

      await confirm({
        description: (
          <Box component="div">
            <p>
              Oh no! You've ran into an error. This happened either because you did something wrong
              and the interpreter can't understand what you wanted to do, or because something bad
              happened that was unexpected during the execution of your code.
            </p>
            <p>Here is the error:</p>
            <code style={{ color: 'white' }}>
              <pre>{errorLine}</pre>
            </code>

            <p>Here is the full stack trace of the error:</p>
            <pre style={{ fontSize: 12, color: 'white' }}>{message}</pre>
          </Box>
        ),
        title: 'Error',
        hideCancelButton: true,
        confirmationText: 'Close',
        confirmationButtonProps: { variant: 'contained' }
      });
      setIsCodeExecuted(true);
      resetCommandsQueue();
    }
  };

  const assignConstants = (lib: PyodideInterface) => {
    apiLevelData?.constants?.map((constant: Constant) => {
      lib.globals.set(constant.name, lib.toPy(constant.value));
    });
  };

  const assignCommand = (lib: PyodideInterface, command: Command) => {
    const commands = lib.globals.get('commands');
    lib.globals.set('commands', [...commands, command]);
  };

  const createPlayerModule = (lib: PyodideInterface) => ({
    move_forward: async (steps: number) => {
      assignCommand(lib, { moveForward: true, steps });
    },
    move_backward: async (steps: number) => {
      assignCommand(lib, { moveBackward: true, steps });
    },
    turn_left: async () => {
      assignCommand(lib, { turnLeft: true });
    },
    turn_right: async () => {
      assignCommand(lib, { turnRight: true });
    },
    push: async () => {
      assignCommand(lib, { push: true });
    },
    speak: async (phrase: Primitive | PyProxyType) => {
      if (['string', 'number'].includes(typeof phrase)) {
        assignCommand(lib, { speak: true, phrase });
        return;
      }
      phrase = phrase as PyProxyType;
      const objectType = detectDataType(phrase.toJs());

      switch (objectType) {
        case 'json':
          assignCommand(lib, { speak: true, phrase: Object.fromEntries(phrase.toJs()) });
          break;
        case 'list':
          assignCommand(lib, { speak: true, phrase: phrase.toJs() });
          break;
        case 'primitive':
          assignCommand(lib, { speak: true, phrase });
          break;
        default:
          console.log('Unknown object type:', objectType);
          break;
      }
    },
    build: async (structure: Primitive | PyProxyType) => {
      if (['string', 'number'].includes(typeof structure)) {
        assignCommand(lib, { build: true, structure });
        return;
      }
      structure = structure as PyProxyType;
      const objectType = detectDataType(structure.toJs());
      switch (objectType) {
        case 'class':
          assignCommand(lib, { build: true, structure: structure.toJs() });
          break;
        case 'json':
          assignCommand(lib, { build: true, structure: Object.fromEntries(structure.toJs()) });
          break;
        case 'list':
          assignCommand(lib, { build: true, structure: structure.toJs() });
          break;
        case 'primitive':
          assignCommand(lib, { build: true, structure });
          break;
        default:
          console.log('Unknown object type:', objectType);
          break;
      }
    },
    water: async () => {
      assignCommand(lib, { watering: true });
    },
    collect: async (collectSubject: string) => {
      assignCommand(lib, { collect: true, collectSubject });
    },
    open: async () => {
      assignCommand(lib, { open: true });
    },
    close: async () => {
      assignCommand(lib, { close: true });
    },
    place: async (placeObject: Primitive | PyProxyType) => {
      if (['string', 'number'].includes(typeof placeObject)) {
        assignCommand(lib, { place: true, placeObject });
        return;
      }
      placeObject = placeObject as PyProxyType;
      const objectType = detectDataType(placeObject.toJs());

      switch (objectType) {
        case 'json':
          assignCommand(lib, { place: true, placeObject: Object.fromEntries(placeObject.toJs()) });
          break;
        case 'list':
          assignCommand(lib, { place: true, placeObject: placeObject.toJs() });
          break;
        case 'primitive':
          assignCommand(lib, { place: true, placeObject: placeObject });
          break;
        default:
          console.log('Unknown object type:', objectType);
          break;
      }
    },
    combine: async (combiningObject: Primitive | PyProxyType) => {
      if (['string', 'number'].includes(typeof combiningObject)) {
        assignCommand(lib, { combine: true, combineObject: combiningObject });
        return;
      }
      combiningObject = combiningObject as PyProxyType;
      const objectType = detectDataType(combiningObject.toJs());

      switch (objectType) {
        case 'json':
          assignCommand(lib, {
            combine: true,
            combineObject: Object.fromEntries(combiningObject.toJs())
          });
          break;
        case 'list':
          assignCommand(lib, { combine: true, combineObject: combiningObject.toJs() });
          break;
        case 'primitive':
          assignCommand(lib, { combine: true, combineObject: combiningObject });
          break;
        default:
          console.log('Unknown object type:', objectType);
          break;
      }
    },
    plant: async (plantObject: Primitive | PyProxyType, plantData: Primitive) => {
      if (['string', 'number'].includes(typeof plantObject)) {
        assignCommand(lib, { plant: true, plantObject });
        return;
      }
      plantObject = plantObject as PyProxyType;
      const objectType = detectDataType(plantObject.toJs());

      switch (objectType) {
        case 'json':
          assignCommand(lib, {
            plant: true,
            plantObject: Object.fromEntries(plantObject.toJs()),
            plantData
          });
          break;
        case 'list':
          assignCommand(lib, { plant: true, plantObject: plantObject.toJs(), plantData });
          break;
        case 'primitive':
          assignCommand(lib, { plant: true, plantObject: plantObject, plantData });
          break;
        default:
          console.log('Unknown object type:', objectType);
          break;
      }
    }
  });

  useEffect(() => {
    if (pyodide && apiLevelData) {
      assignConstants(pyodide);
    }
  }, [apiLevelData, pyodide]);

  useEffect(() => {
    async function load() {
      if (!pyodide) {
        const lib = await loadPyodide();

        lib.globals.set('commands', []);
        lib.registerJsModule('player', createPlayerModule(lib));

        setPyodide(lib);
        setIsLoading(false);
      }
    }
    load();
  }, []);

  const value: PyodideContextType = {
    pyodide,
    runScript,
    isLoading,
    errorAnnotations,
    setErrorAnnotations,
    errorMessage,
    setErrorMessage
  };

  return <PyodideContext.Provider value={value}>{children}</PyodideContext.Provider>;
};

export default PyodideContext;
