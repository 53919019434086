import React from 'react';
import { Box, DialogContentText, SxProps, Theme } from '@mui/material';
import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { monokaiSublime } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import SyntaxHighlighter from 'react-syntax-highlighter';
import ContentWrapper from './ContentWrapper';

interface ContentMarkdownProps {
  content: string;
  image?: string;
  sx?: SxProps<Theme>;
}

const ContentMarkdown: React.FC<ContentMarkdownProps> = ({ content, image, sx = {} }) => {
  if (!content) {
    return null;
  }

  return (
    <ContentWrapper sx={sx}>
      <DialogContentText
        sx={{
          color: '#FFF',
          display: 'flex',
          gap: 2,
          flexWrap: 'wrap',
          overflow: 'hidden',
          overflowX: 'auto'
        }}
      >
        <Box component="div" sx={{ flex: 1 }} className="level-data-markdown" dir="auto">
          <Markdown
            remarkPlugins={[remarkGfm]}
            components={{
              code({ node, inline, className, children, ...props }: any) {
                const match = /language-(\w+)/.exec(className || '');
                return !inline && match ? (
                  <SyntaxHighlighter
                    style={monokaiSublime}
                    PreTag="div"
                    language={match[1]}
                    customStyle={{
                      direction: 'ltr'
                    }}
                    {...props}
                  >
                    {String(children).replace(/\n$/, '')}
                  </SyntaxHighlighter>
                ) : (
                  <code
                    style={{
                      backgroundColor: '#47483F',
                      padding: '1px 4px',
                      borderRadius: '4px',
                      direction: 'ltr'
                    }}
                    {...props}
                  >
                    {children}
                  </code>
                );
              }
            }}
          >
            {content}
          </Markdown>
        </Box>
        {image && (
          <Box component="div" sx={{ display: 'flex' }}>
            <img src={image} alt="modal" style={{ height: '100%' }} />
          </Box>
        )}
      </DialogContentText>
    </ContentWrapper>
  );
};

export default ContentMarkdown;
