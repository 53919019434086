import Collider, { TriggerEvent } from '../@core/Collider';
import GameObject, { GameObjectProps } from '../@core/GameObject';
import Sprite, { SpriteProps } from '../@core/Sprite';
import useGameObject from '../@core/useGameObject';
import useGameObjectEvent from '../@core/useGameObjectEvent';
import useSceneManager from '../@core/useSceneManager';
import useGame from '../@core/useGame';
import { DidCollectEvent } from '../@core/Collectable';

function DisableOnTriggerScript({ itemName }: { itemName: string }) {
  const { levelData, setLevelData } = useSceneManager();
  const { publish } = useGame();
  const { getRef } = useGameObject();

  useGameObjectEvent<TriggerEvent>('trigger', (other) => {
    if (other.name === 'player') {
      getRef().setDisabled(true);
      const newLevelData = levelData.map((item) => {
        if (item.type === itemName) {
          return { ...item, score: (item.score += 1) };
        }
        return item;
      });
      setLevelData(newLevelData);
      publish<DidCollectEvent>('did-collect', newLevelData);
    }
  });

  return null;
}

interface ItemFinishProps {
  props: GameObjectProps;
  state: string;
  spriteData: SpriteProps;
  itemName: string;
}

export default function ItemFinish({ props, state, spriteData, itemName }: ItemFinishProps) {
  const name = `${state}-${props.x}-${props.y}`; // fallback name required for persisted flag

  return (
    <GameObject name={name} persisted={false} {...props} layer="item">
      <Sprite {...spriteData} state={state} />
      <Collider isTrigger />
      <DisableOnTriggerScript itemName={itemName} />
    </GameObject>
  );
}
