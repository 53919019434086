import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Rating,
  Stack,
  TextField,
  Typography
} from '@mui/material';
import { styled } from '@mui/material/styles';
import FeedbackIcon from '@mui/icons-material/Feedback';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';
import { useFeedbackForm } from '../../hooks/useFeedbackForm';
import { Controller } from 'react-hook-form';
import { useMutation } from 'react-query';
import { ApiError, FeedbackSchema, FeedbackService } from '../../client';
import { useSnackBarContext } from '../../context/SnackBarContext';
import { UserContext } from '../../pages/App';
import { LoadingButton } from '@mui/lab';

type CustomIconType = {
  icon: React.ReactElement;
  label: string;
};

interface IconContainerProps {
  value: number;
}

const StyledRating = styled(Rating)(({ theme }) => ({
  '& .MuiRating-icon': {
    margin: theme.spacing(2),
    transition: 'transform 0.25s'
  },
  '& .MuiSvgIcon-root': {
    fontSize: '2rem'
  },
  '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
    color: '#ffffff'
  }
}));

const customIcons: { [index: number]: CustomIconType } = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon color="error" />,
    label: 'Very Dissatisfied'
  },
  2: {
    icon: <SentimentDissatisfiedIcon color="error" />,
    label: 'Dissatisfied'
  },
  3: {
    icon: <SentimentSatisfiedIcon color="warning" />,
    label: 'Neutral'
  },
  4: {
    icon: <SentimentSatisfiedAltIcon color="success" />,
    label: 'Satisfied'
  },
  5: {
    icon: <SentimentVerySatisfiedIcon color="success" />,
    label: 'Very Satisfied'
  }
};

function IconContainer(props: IconContainerProps) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

IconContainer.propTypes = {
  value: PropTypes.number.isRequired
};

const Feedback: React.FC = () => {
  const [open, setOpen] = useState(false);
  const { createSnackBar } = useSnackBarContext();
  const currentUser = useContext(UserContext);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting }
  } = useFeedbackForm();

  const feedbackMutation = useMutation(FeedbackService.sendFeedbackApiFeedbackPost, {
    onSuccess: () => {
      handleClose();
      createSnackBar({
        content: 'Feedback sent successfully',
        severity: 'success',
        autoHide: true
      });
    },
    onError: (error: ApiError) => {
      createSnackBar({
        content: 'Failed to send feedback',
        severity: 'error',
        autoHide: true
      });
    }
  });

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const onSubmitFeedback = async (data: Partial<FeedbackSchema>) => {
    await feedbackMutation.mutateAsync(data as FeedbackSchema);
  };

  return (
    <React.Fragment>
      <Button
        onClick={handleClickOpen}
        variant="contained"
        sx={{
          position: 'fixed',
          zIndex: '1200',
          opacity: '.9',
          bottom: '50%',
          right: -30,
          transform: 'translateY(50%)',
          rotate: '270deg'
        }}
        startIcon={<FeedbackIcon />}
      >
        Feedback
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="sm">
        <DialogTitle>How would you rate your experience?</DialogTitle>
        <DialogContent>
          <Stack
            spacing={2}
            component="form"
            id="feedback-form"
            display="flex"
            alignItems="center"
            onSubmit={handleSubmit(onSubmitFeedback)}
            noValidate
          >
            <Controller
              name="rating"
              control={control}
              render={({ field }) => (
                <StyledRating
                  {...field}
                  value={Number(field.value)}
                  name="highlight-selected-only"
                  IconContainerComponent={IconContainer}
                  getLabelText={(value) => customIcons[value].label}
                  highlightSelectedOnly
                />
              )}
            />

            <Controller
              name="feedback"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  autoFocus
                  id="feedback"
                  label="Please tell us about your experience"
                  type="text"
                  fullWidth
                  multiline
                  minRows={6}
                  variant="outlined"
                />
              )}
            />

            {currentUser === undefined && (
              <Controller
                name="from_email"
                control={control}
                render={({ field }) => (
                  <TextField
                    {...field}
                    id="email"
                    label="Your email address (optional)"
                    type="email"
                    fullWidth
                    variant="outlined"
                  />
                )}
              />
            )}

            <Box component="div" sx={{ alignSelf: 'start' }}>
              {errors.rating && (
                <Typography variant="body2" color="error">
                  {errors.rating.message}
                </Typography>
              )}
              {errors.feedback && (
                <Typography variant="body2" color="error">
                  {errors.feedback.message}
                </Typography>
              )}
            </Box>
          </Stack>
        </DialogContent>
        <DialogActions sx={{ px: 3, pb: 3 }}>
          <Button onClick={handleClose}>Cancel</Button>
          <LoadingButton
            type="submit"
            form="feedback-form"
            variant="contained"
            disabled={isSubmitting}
            loading={isSubmitting}
          >
            Send
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
};

export default Feedback;
