import React, { useState, useContext, useEffect } from 'react';
import { Modal, Box, Button, Paper, Grid, Card } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { UserContext } from '../pages/App';
import {
  OrganizationService,
  ProfileService,
  StudentCreateForm,
  CreateOrganizationStudentForm
} from '../client';
import { useGenericModal } from '../context/GenericModalContext';
import { useOutput } from '../context/OutputContext';
import { useSnackBarContext } from '../context/SnackBarContext';
import { avatars } from './Profile/AvatarSelectionModal';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 640,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2
};

export default function AvatarSelectorGlobal() {
  const { openModal: open, setOpenModal } = useGenericModal('avatar-selector-global-modal');
  const { createSnackBar } = useSnackBarContext();
  const { setResetLevel } = useOutput();
  const queryClient = useQueryClient();
  const currentUser = useContext(UserContext);
  const studentId = currentUser?.student_id;
  const isOrgStudent = !!currentUser?.organization_id;

  const { data: student, isLoading: isStudentLoading } = useQuery(
    isOrgStudent ? ['org-student-user', studentId] : ['student-user', studentId],
    () =>
      isOrgStudent
        ? OrganizationService.getStudentApiOrganizationStudentsStudentIdGet(studentId || '')
        : ProfileService.getStudentApiProfileStudentsStudentIdGet(studentId || ''),
    {
      enabled: !!studentId,
      retry: false
    }
  );

  const [selectedAvatar, setSelectedAvatar] = useState<string>(student?.character || 'Rabbit');

  useEffect(() => {
    if (student?.character) {
      setSelectedAvatar(student.character);
    }
  }, [student]);

  const changeStudentAvatarMutation = useMutation(
    (updatedStudentUser: StudentCreateForm) =>
      ProfileService.updateStudentApiProfileStudentsStudentIdPut(
        studentId || '',
        updatedStudentUser
      ),
    {
      onSuccess: () => {
        setResetLevel(true);
        queryClient.invalidateQueries('students');
        queryClient.invalidateQueries(['student-user', studentId]);
        queryClient.invalidateQueries('currentUser');
        setOpenModal(false);
      },
      onError: () => {
        createSnackBar({ content: 'Updating avatar failed', autoHide: true, severity: 'error' });
      }
    }
  );

  const changeOrgStudentAvatarMutation = useMutation(
    (updatedStudentUser: CreateOrganizationStudentForm) =>
      OrganizationService.updateStudentApiOrganizationStudentsStudentIdPut(
        studentId || '',
        updatedStudentUser
      ),
    {
      onSuccess: () => {
        setResetLevel(true);
        queryClient.invalidateQueries('org-students');
        queryClient.invalidateQueries(['org-student-user', studentId]);
        queryClient.invalidateQueries('currentUser');
        setOpenModal(false);
      },
      onError: () => {
        createSnackBar({ content: 'Updating avatar failed', autoHide: true, severity: 'error' });
      }
    }
  );

  const handleConfirm = async () => {
    if (!student) return;
    const updatedStudentUser = {
      username: student.username,
      character: selectedAvatar
    };

    if (isOrgStudent) {
      await changeOrgStudentAvatarMutation.mutateAsync(
        updatedStudentUser as CreateOrganizationStudentForm
      );
    } else {
      await changeStudentAvatarMutation.mutateAsync(updatedStudentUser as StudentCreateForm);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpenModal(false)}
      aria-labelledby="select-avatar-modal"
      aria-describedby="modal-modal-description"
    >
      <Paper sx={style}>
        <Typography variant="h6" sx={{ textAlign: 'center', py: 2 }}>
          Choose an Avatar
        </Typography>
        <Grid container spacing={2}>
          {avatars.map((avatar, index) => (
            <Grid item xs={2} key={index}>
              <Card
                onClick={() => setSelectedAvatar(avatar)}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderColor: avatar === selectedAvatar ? 'primary.main' : '#666',
                  borderStyle: 'solid',
                  borderWidth: 1,
                  pt: 2,
                  '&:hover': { cursor: 'pointer', backgroundColor: 'rgba(255,255,255,0.1)' }
                }}
              >
                <img
                  src={`/assets/characters/portraits/${avatar}.png`}
                  style={{ width: 64, height: 64 }}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
        <Box component="div" sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            disabled={!selectedAvatar}
            sx={{ mt: 2 }}
          >
            Save
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
}
