import { Link, useNavigate } from 'react-router-dom';
import { Box, IconButton, Tooltip, Paper } from '@mui/material';
import { Edit, Delete, ChevronRightSharp, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { useConfirm } from 'material-ui-confirm';
import { Loader } from '../Loader';
import { Student } from '../../client';
import useStudents from '../useStudents';
import StudentAvatar from '../StudentAvatar';
import {
  DataGrid,
  GridActionsCellItem,
  GridCellParams,
  GridColDef,
  GridRowParams,
  GridToolbar
} from '@mui/x-data-grid';
import { useCheckStudentsLimit } from '../../hooks/useCheckStudentsLimit';
import { useGenericModal } from '../../context/GenericModalContext';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';

export const StudentUsersList = () => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { students, isStudentsLoading, deleteStudentUserMutation } = useStudents();
  const { data: studentsLimit } = useCheckStudentsLimit();
  const { setOpenModal: setOpenUpgradeToOrgModal, setContent: setUpgradeToOrgContent } =
    useGenericModal('upgrade-to-org-modal');

  useEffect(() => {
    if (studentsLimit) {
      handleUpgradeToOrg();
    }
  }, [studentsLimit]);

  const handleUpgradeToOrg = () => {
    setUpgradeToOrgContent(
      'You cannot create more than 5 students. To manage more than 5 students, please upgrade to the organizational tier and create a class instead.'
    );
    setOpenUpgradeToOrgModal(true);
  };

  if (isStudentsLoading) return <Loader />;
  if (!students || students.length === 0) return <div>No students</div>;

  const handleEditClick = async (params: GridRowParams) => {
    queryClient.invalidateQueries('currentUser');
    queryClient.invalidateQueries(['student-user', params.id]);
    navigate(`/profile/students/${params.id}/edit`);
  };

  const handleDeleteClick = async (params: GridRowParams) => {
    try {
      await confirm({
        description: `Are you sure you want to remove ${params.row.username}?`,
        title: 'Remove student',
        confirmationText: 'Yes',
        cancellationText: 'No'
      });
    } catch (e) {
      return;
    }

    if (typeof params.id === 'string') {
      await deleteStudentUserMutation.mutateAsync(params.id);
    }
  };

  const columns: GridColDef[] = [
    {
      field: 'username',
      headerName: 'Username',
      flex: 1,
      renderCell: (params) => (
        <Box component="div" display="flex" alignItems="center" gap={2}>
          <StudentAvatar student={params.row} size={32} variant="body2" />
        </Box>
      )
    },
    {
      field: 'full_name',
      headerName: 'Full Name',
      flex: 1,
      type: 'string'
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 150,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          onClick={() => handleEditClick(params)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<Delete />}
          label="Delete"
          onClick={() => handleDeleteClick(params)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<KeyboardDoubleArrowRight color="primary" />}
          label="Open"
          onClick={() => navigate(`/profile/students/${params.row.id}`)}
          color="inherit"
        />
      ]
    }
  ];

  return (
    <Box sx={{ height: 500, width: '100%', mt: 2, mb: 2 }} component={Paper}>
      <DataGrid
        slots={{ toolbar: GridToolbar }}
        columns={columns}
        rows={students}
        onCellDoubleClick={(e) => navigate(`/profile/students/${e.row.id}`)}
      />
    </Box>
  );
};
