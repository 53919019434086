import React from 'react';

interface RibbonHeaderProps {
  children: React.ReactNode;
}

const RibbonHeader: React.FC<RibbonHeaderProps> = ({ children }) => {
  return (
    <div className="ribbonHeader">
      {children}
      <i></i>
      <i></i>
      <i></i>
      <i></i>
    </div>
  );
};

export default RibbonHeader;
