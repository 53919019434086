import { Box, Container, Typography } from '@mui/material';
import { AddEditStudentUserForm } from '../../components/Profile/AddEditStudentUserForm';
import { useParams } from 'react-router-dom';
import { AddEditOrganizationDetails } from '../../components/Organization/AddEditOrganizationDetails';

export default function OrganizationDetails() {
  return (
    <Container maxWidth="lg" sx={{ mt: 3, mb: 14 }}>
      <Box component="div" display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" gutterBottom>
          Organization Details
        </Typography>
      </Box>
      <AddEditOrganizationDetails />
    </Container>
  );
}
