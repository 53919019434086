import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { debounce } from 'lodash-es';

import { AuthService } from '../client';

export const useEmailExists = (email: string) => {
  const isEmailValid = email?.includes('@') && email?.includes('.');

  const [debouncedEmail, setDebouncedEmail] = useState(email);

  useEffect(() => {
    const handler = debounce(() => {
      setDebouncedEmail(email);
    }, 800);

    handler();
    setDebouncedEmail('');

    return () => {
      handler.cancel();
    };
  }, [email]);

  const { data, isLoading } = useQuery(
    ['email-exists', email],
    () => AuthService.checkUserEmailExistsApiAuthCheckUserEmailExistsGet(email),
    {
      enabled: !!debouncedEmail && isEmailValid,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      cacheTime: 800
    }
  );

  return { emailExists: data || false, isLoading };
};
