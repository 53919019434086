import { Box, Paper } from '@mui/material';
import useOrganizationStudents from '../../../hooks/useOrganzationStudents';
import { Loader } from '../../Loader';
import useLevels from '../../useLevels';
import Tooltip from '@mui/material/Tooltip';
import { useQuery } from 'react-query';
import {
  ChapterData,
  LevelCompletionStatus,
  LevelDataSummary,
  LevelService
} from '../../../client';
import React from 'react';

export default function StudentMatrix({ classId }: { classId?: string }) {
  const { students, isStudentsLoading } = useOrganizationStudents();
  const { courseId, courseData } = useLevels();

  const { data, isLoading } = useQuery('studentCompletion', () =>
    LevelService.getOrgStudentCompletionRouteApiLevelCourseCourseSlugOrgCompletionGet(courseId)
  );

  const filteredStudents = classId ? data?.filter((student) => student.class_id === classId) : data;

  if (isStudentsLoading || !filteredStudents || !courseData || !data || isLoading) {
    return <Loader />;
  }

  const levelDetailsBySlug: {
    [key: string]: { chapterIndex: number; levelIndex: number; name: string };
  } = {};
  courseData.chapters.forEach((chapter: ChapterData, chapterIndex) => {
    chapter.level_data.forEach((level: LevelDataSummary, levelIndex) => {
      levelDetailsBySlug[level.slug] = {
        chapterIndex,
        levelIndex,
        name: `Chapter ${chapterIndex + 1} Level ${levelIndex + 1}`
      };
    });
  });

  const levelStatusString = {
    not_started: 'Not started',
    in_progress: 'In progress',
    complete: 'Complete'
  };

  return (
    <Paper
      component="div"
      sx={{
        display: 'flex',
        flexDirection: 'column',
        overflowX: 'scroll',
        gap: 1,
        p: 2,
        borderColor: 'divider',
        borderWidth: 1,
        borderStyle: 'solid'
      }}
    >
      <Box component="div" sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}>
        <Box
          component="div"
          sx={{ fontWeight: 'bold', minWidth: 250, display: 'flex', alignItems: 'center' }}
        >
          Username
        </Box>
        {courseData.chapters.map((chapter, chapterIndex) => (
          <>
            {chapter.level_data.map((level, levelIndex) => (
              <Box
                component="div"
                sx={{
                  display: 'flex',
                  minWidth: 32,
                  minHeight: 32,
                  width: 32,
                  height: 32,
                  alignItems: 'center',
                  justifyContent: 'center',
                  fontWeight: 'bold'
                }}
              >
                {`${chapterIndex + 1}.${levelIndex + 1}`}
              </Box>
            ))}
          </>
        ))}
      </Box>
      {filteredStudents.map((student) => (
        <Box
          component="div"
          key={student.username}
          sx={{ display: 'flex', flexDirection: 'row', gap: 1 }}
        >
          <Box component="div" sx={{ minWidth: 250, display: 'flex', alignItems: 'center' }}>
            {student.username}
          </Box>
          {student.progress.map((level, levelIndex) => (
            <Tooltip
              title={`${levelDetailsBySlug[level.level_slug].name} - ${levelStatusString[level.last_status]}`}
              key={level.level_slug}
            >
              <Box
                component="div"
                sx={{
                  minWidth: 32,
                  minHeight: 32,
                  width: 32,
                  height: 32,
                  borderRadius: 1,
                  backgroundColor:
                    level.last_status == LevelCompletionStatus.COMPLETE
                      ? 'success.main'
                      : level.last_status == LevelCompletionStatus.IN_PROGRESS
                        ? 'warning.main'
                        : 'divider'
                }}
              ></Box>
            </Tooltip>
          ))}
        </Box>
      ))}
    </Paper>
  );
}
