import React from 'react';
import { LevelData, LevelGoal } from '../client';
import spriteData from '../spriteData';
import Bridge from '../entities/Bridge';
import GrainStack from '../entities/GrainStack';
import Wood from '../entities/Wood';
import Stone from '../entities/Stone';
import ChickenHouse from '../entities/ChickenHouse';
import { XMARK_LIST } from './utils/xmarkList';

interface BuildLayerProps {
  levelData: LevelData;
}
export default function BuildLayer({ levelData }: BuildLayerProps) {
  if (!levelData) return null;

  const { levelGoals } = levelData;

  const buildGoals = levelGoals.filter(
    (goal) => goal.action === 'build' && XMARK_LIST.includes(goal?.type || '')
  );

  const renderItem = (goal: LevelGoal) => {
    const { data, targetPosition } = goal;
    switch (data) {
      case 'bridge':
        return (
          <Bridge
            props={{ x: targetPosition?.[0], y: targetPosition?.[1] }}
            state={'bridgeH'}
            spriteData={spriteData['items']}
            itemName={'bridgeH'}
          />
        );
      case 'Grain':
        return (
          <GrainStack
            props={{ x: targetPosition?.[0], y: targetPosition?.[1] }}
            state={'grainstack-initial'}
            spriteData={spriteData['barn']}
            itemName={'grainstack'}
          />
        );
      case 'wood':
        return (
          <Wood
            props={{ x: targetPosition?.[0], y: targetPosition?.[1] }}
            state={'wood-initial'}
            spriteData={spriteData['path']}
            itemName={'wood'}
          />
        );
      case 'stone':
        return (
          <Stone
            props={{ x: targetPosition?.[0], y: targetPosition?.[1] }}
            state={'stone-initial'}
            spriteData={spriteData['path']}
            itemName={'stone'}
          />
        );
      case 'chicken_house|blue|small':
      case 'chicken_house|red|small':
        return (
          <ChickenHouse
            props={{ x: targetPosition?.[0], y: targetPosition?.[1] }}
            state={data}
            spriteData={spriteData[`chickenHouseSmall`]}
          />
        );
      case 'chicken_house|green|big':
      case 'chicken_house|orange|big':
        return (
          <ChickenHouse
            props={{ x: targetPosition?.[0], y: targetPosition?.[1] }}
            state={data}
            spriteData={spriteData[`chickenHouseBig`]}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {buildGoals?.map((goal, index) => {
        const { action, type } = goal;
        const key = `${type}-${index}-${action}`;
        return <React.Fragment key={key}>{renderItem(goal)}</React.Fragment>;
      })}
    </>
  );
}
