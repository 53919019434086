import React from 'react';
import { Stack } from '@mui/material';
import useGameEvent from './useGameEvent';
import { useState } from 'react';
import { SceneReadyEvent } from './Scene';
import { LevelData, LevelGoal } from '../client';
import { GameLevelData } from './SceneManager';

interface InventoryProps {
  levelData: LevelData;
}
export const Inventory = ({ levelData }: InventoryProps) => {
  const [collectedItems, setCollectedItems] = useState<GameLevelData>();

  const [sceneIsReady, setSceneIsReady] = useState(false);

  useGameEvent('did-collect', (data) => {
    setCollectedItems(data);
  });

  useGameEvent<SceneReadyEvent>('scene-ready', () => {
    setCollectedItems(undefined);
    setSceneIsReady(true);
  });

  const { levelGoals } = levelData;

  const hasCollectableGoals = Object.values(levelGoals).some((goal) => goal.action === 'collect');

  const getCollectedItemScore = (type?: string) => {
    return collectedItems?.find((item) => item.type === type)?.score || 0;
  };

  const renderInventoryItem = (goal: LevelGoal) => {
    const { action, type, count, cost, data } = goal;
    if (action !== 'collect') return null;

    switch (type) {
      case 'xmarkLight':
      case 'xmarkGold':
        return (
          cost?.type &&
          data === 'water' && (
            <Stack direction="row" spacing={1}>
              <img
                src={`/assets/inventory/${cost?.type}.png`}
                width={32}
                height={32}
                alt={cost?.type}
              />
              <div style={{ fontSize: 20 }}>
                <span>{getCollectedItemScore(cost?.type)}</span>/<span>{count}</span>
              </div>
            </Stack>
          )
        );
      default:
        return (
          !cost?.type && (
            <Stack direction="row" spacing={1}>
              <img src={`/assets/inventory/${type}.png`} width={32} height={32} alt={type} />
              <div style={{ fontSize: 20 }}>
                <span>{getCollectedItemScore(goal?.type)}</span>/<span>{count}</span>
              </div>
            </Stack>
          )
        );
    }
  };

  if (sceneIsReady && hasCollectableGoals) {
    return (
      <div
        style={{
          position: 'fixed',
          top: 150,
          right: 20,
          zIndex: 5,
          fontFamily: 'Silkscreen',
          fontWeight: 400,
          backgroundColor: 'rgba(30,30,30,0.5)',
          padding: 10,
          borderRadius: 10
        }}
      >
        <Stack spacing={1}>
          {levelGoals.map((goal, index) => {
            return <React.Fragment key={index}>{renderInventoryItem(goal)}</React.Fragment>;
          })}
        </Stack>
      </div>
    );
  }

  return null;
};
