import React from 'react';
import { TransitionProps } from '@mui/material/transitions';
import { Dialog, DialogProps, Zoom } from '@mui/material';

interface ModalProps extends DialogProps {
  children?: React.ReactNode;
}

// const Transition = React.forwardRef(function Transition(
//   props: TransitionProps & { children: React.ReactElement<any, any> },
//   ref: React.Ref<unknown>
// ) {
//   return <Zoom in appear ref={ref} {...props} />;
// });

const Modal: React.FC<ModalProps> = ({ children, sx = {}, ...props }) => {
  return (
    <Dialog
      maxWidth="md"
      scroll="paper"
      // TransitionComponent={Transition}
      {...props}
      sx={{
        '& .MuiDialog-paper': {
          backgroundColor: '#DCBB8B',
          border: '3px solid #E6D4AF',
          outline: '3px solid #AF8B68',
          backgroundImage: 'none',
          overflow: 'hidden',
          padding: 1,
          width: '100%',
          ...((sx as any)['& .MuiDialog-paper'] || {})
        },
        ...sx
      }}
    >
      {children}
    </Dialog>
  );
};

export default Modal;
