import { useQuery } from 'react-query';
import { OrganizationService } from '../client';
import { useContext } from 'react';
import { UserContext } from '../pages/App';

export const useOrganization = () => {
  const currentUser = useContext(UserContext);

  const { data: currentOrganization, isLoading: isOrganizationLoading } = useQuery(
    [currentUser?.user_id, 'current-organization'],
    () => OrganizationService.currentOrganizationApiOrganizationCurrentGet(),
    {
      enabled: !!currentUser?.organization_id,
      retry: false,
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false
    }
  );

  return { currentOrganization, isOrganizationLoading };
};
