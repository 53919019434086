import useKeyPress from './useKeyPress';

export const useMovementControls = () => {
  const leftKey = useKeyPress(['ArrowLeft']);
  const rightKey = useKeyPress(['ArrowRight']);
  const upKey = useKeyPress(['ArrowUp']);
  const downKey = useKeyPress(['ArrowDown']);

  return { leftKey, rightKey, upKey, downKey };
};
