import { Container, Divider, Typography } from '@mui/material';

export default function Unsubscribed() {
  return (
    <Container maxWidth="md" sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Unsubscribed
      </Typography>
      <Divider sx={{ my: 2 }} />

      <Typography paragraph>E-mail unsubscribed successfully from Coding for Kids.</Typography>
    </Container>
  );
}
