import React from 'react';
import { LevelData, LevelGoal } from '../client';
import spriteData from '../spriteData';
import Jar from '../entities/Jar';
import PurpleMix from '../entities/PurpleMix';
import HalfPurpleMix from '../entities/HalfPurpleMix';
import RedMix from '../entities/RedMix';
import BrownMix from '../entities/BrownMix';
import { XMARK_LIST } from './utils/xmarkList';

interface CombineLayerProps {
  levelData: LevelData;
}
export default function CombineLayer({ levelData }: CombineLayerProps) {
  if (!levelData) return null;

  const { levelGoals } = levelData;

  const combineGoals = levelGoals.filter(
    (goal) => goal.action === 'combine' && XMARK_LIST.includes(goal.type || '')
  );

  const renderItem = (goal: LevelGoal) => {
    const { data, targetPosition } = goal;
    switch (data) {
      case 'jar':
        return (
          <Jar
            props={{ x: targetPosition?.[0], y: targetPosition?.[1] }}
            state={'jar-initial'}
            spriteData={spriteData['items']}
          />
        );
      case 'purplemix3':
        return (
          <PurpleMix
            props={{ x: targetPosition?.[0], y: targetPosition?.[1] }}
            state={'purplemix-initial'}
            spriteData={spriteData['items']}
          />
        );
      case 'halfpurplemix':
        return (
          <HalfPurpleMix
            props={{ x: targetPosition?.[0], y: targetPosition?.[1] }}
            state={'halfpurplemix-initial'}
            spriteData={spriteData['items']}
          />
        );
      case 'redmix3':
        return (
          <RedMix
            props={{ x: targetPosition?.[0], y: targetPosition?.[1] }}
            state={'redmix-initial'}
            spriteData={spriteData['items']}
          />
        );
      case 'brownmix':
        return (
          <BrownMix
            props={{ x: targetPosition?.[0], y: targetPosition?.[1] }}
            state={'brownmix-initial'}
            spriteData={spriteData['items']}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {combineGoals?.map((goal, index) => {
        const { action, type } = goal;
        const key = `${type}-${index}-${action}`;
        return <React.Fragment key={key}>{renderItem(goal)}</React.Fragment>;
      })}
    </>
  );
}
