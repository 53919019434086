import { DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import useOrganizationTeachers from '../../../hooks/useOrganizationTeachers';
import {
  Class,
  ClassTeacher,
  ClassTeacherAction,
  ClassTeacherForm,
  OrganizationService,
  User
} from '../../../client';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import React, { useState } from 'react';
import { AxiosError } from 'axios';
import { useNavigate } from 'react-router-dom';
import { useSnackBarContext } from '../../../context/SnackBarContext';
import { Box, Button, Paper } from '@mui/material';
import { Save } from '@mui/icons-material';

export interface TeacherClassSelectorProps {
  classId: string;
}

export default function TeacherClassSelector({ classId }: TeacherClassSelectorProps) {
  const { teachers } = useOrganizationTeachers();

  const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
  const queryClient = useQueryClient();
  const { createSnackBar } = useSnackBarContext();

  const { data: classTeachers, isLoading: isClassTeachersLoading } = useQuery(
    ['class-teachers', classId],
    () => OrganizationService.getClassTeachersApiOrganizationClassTeachersClassIdGet(classId),
    {
      onSuccess: (data) => {
        setRowSelectionModel(data.map((x) => x.user_id));
      },
      enabled: !!classId
    }
  );

  const classTeacherIds = classTeachers ? classTeachers.map((x) => x.user_id) : [];

  const classTeacherMutation = useMutation<ClassTeacher, AxiosError, ClassTeacherForm>(
    (classTeacherForm) =>
      OrganizationService.updateClassTeacherApiOrganizationClassTeachersPut(classTeacherForm),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['class-teachers', classId]);
        createSnackBar({
          content: 'Teacher assignment saved!',
          autoHide: true,
          severity: 'success'
        });
      },
      onError: (error: AxiosError) => {
        if (error.status === 404) {
          createSnackBar({
            content: 'Teacher assignment not found.',
            autoHide: true,
            severity: 'error'
          });
        } else if (error.status === 409) {
          createSnackBar({
            content: 'Teacher assignment already exists.',
            autoHide: true,
            severity: 'error'
          });
        } else if (error.status === 401) {
          createSnackBar({
            content: 'Permission denied.',
            autoHide: true,
            severity: 'error'
          });
        } else {
          createSnackBar({
            content: 'Editing class teachers failed, please contact support.',
            autoHide: true,
            severity: 'error'
          });
        }
      }
    }
  );

  const teacherColumns: GridColDef[] = [
    {
      field: 'full_name',
      headerName: 'Name',
      minWidth: 200,
      flex: 1
    },
    {
      field: 'email',
      headerName: 'E-mail Address',
      minWidth: 200
    }
  ];

  const save = async () => {
    if (!teachers) {
      return;
    }
    for (const teacher of teachers) {
      if (!teacher.id) {
        continue;
      }
      // teacher is selected but not currently in class teachers, add it
      if (rowSelectionModel.includes(teacher.id) && !classTeacherIds.includes(teacher.id)) {
        await classTeacherMutation.mutateAsync({
          user_id: teacher.id,
          class_id: classId,
          action: ClassTeacherAction.ADD
        });
      }

      // teacher is not selected but in class teachers, remove it
      if (!rowSelectionModel.includes(teacher.id) && classTeacherIds.includes(teacher.id)) {
        await classTeacherMutation.mutateAsync({
          user_id: teacher.id,
          class_id: classId,
          action: ClassTeacherAction.REMOVE
        });
      }
    }
  };

  let invalidated = false;
  for (const teacher of teachers ?? []) {
    if (!teacher.id) {
      continue;
    }
    if (rowSelectionModel.includes(teacher.id) != classTeacherIds.includes(teacher.id)) {
      invalidated = true;
    }
  }

  if (!teachers) {
    return null;
  }
  return (
    <Box component="div">
      <Box component="div" sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button variant="contained" onClick={save} sx={{ mt: 2 }} disabled={!invalidated}>
          Update class teachers
        </Button>
      </Box>

      <Box sx={{ height: 400, width: '100%', mt: 2, mb: 2 }} component={Paper}>
        <DataGrid<User>
          checkboxSelection
          onRowSelectionModelChange={(newRowSelectionModel) => {
            setRowSelectionModel(newRowSelectionModel);
          }}
          rowSelectionModel={rowSelectionModel}
          columns={teacherColumns}
          rows={teachers}
        />
      </Box>
    </Box>
  );
}
