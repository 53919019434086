import { Outlet } from 'react-router-dom';
import { SnackBarProvider } from '../context/SnackBarContext';
import Sidebar from '../components/Sidebar';
import { SidebarProvider } from '../context/SidebarContext';
import Header from '../components/Header';
import NotificationBar from '../components/NotificationBar';
import Feedback from '../components/Feedback/Feedback';
import AssignPurchaseModal from '../components/AssignPurchaseModal';
import React from 'react';
import { AppProvider } from '../context/AppContext';
import AvatarSelectorGlobal from '../components/AvatarSelectorGlobalModal';

export default function Layout() {
  return (
    <AppProvider>
      <SnackBarProvider>
        <SidebarProvider>
          <NotificationBar />
          <Header />
          <Sidebar />
          <Feedback />
          <AssignPurchaseModal />
          <AvatarSelectorGlobal />
          <main
            style={{
              paddingTop: 64,
              background: '#2F3129',
              minHeight: 'calc(100vh - 64px)'
            }}
          >
            <Outlet />
          </main>
          {/* <Footer /> */}
        </SidebarProvider>
      </SnackBarProvider>
    </AppProvider>
  );
}
