import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  PaperProps,
  Stack,
  Typography
} from '@mui/material';

import certificate from '../assets/cfk-certificate.jpg';
import promo from '../assets/promo_hor_scale_complete.png';
import codingforkids from '../assets/codingforkids-cat.png';
import python from '../assets/python-logo-white.png';
import useCheckout, {
  PADDLE_SINGLE_PRICE_ID,
  PADDLE_SUBSCRIPTION_PRICE_ID
} from '../components/useCheckout';
import {
  Check,
  ColorLens,
  Construction,
  School,
  SentimentSatisfiedAlt,
  SportsEsports,
  SupervisorAccount,
  TrendingUp,
  Verified
} from '@mui/icons-material';
import React, { useContext } from 'react';
import WhatsIncluded from '../components/Purchase/WhatsIncluded';
import { UserContext } from './App';
import { useCurrentPlan } from '../hooks/useCurrentPlan';
import UpgradePrice from '../components/Purchase/UpgradePrice';
import { useStudentsWithoutPurchase } from '../hooks/useStudentsWithoutPurchase';

const PricingBox: React.FC<PaperProps> = ({ children, ...props }) => {
  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        gap: 2,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
      {...props}
    >
      {children}
    </Paper>
  );
};

export default function Purchase() {
  const { openCheckout } = useCheckout();
  const currentUser = useContext(UserContext);

  const { data: currentPlan, isLoading: isPlanLoading } = useCurrentPlan();
  const {data: studentsWithoutPurchasedCourse} = useStudentsWithoutPurchase();

  return (
    <Container maxWidth="lg" sx={{ p: 2 }}>
      <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
        <Typography variant="h4" gutterBottom align="center" sx={{ paddingY: '40px' }}>
          Unlock the World of Python for Your Child!
        </Typography>
      </div>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8}>
          <Typography variant="h6" gutterBottom color="primary">
            Embark on an Exciting Coding Adventure!
          </Typography>
          <Typography paragraph>
            Is your child ready to dive into the world of coding? Our "Coding for Kids - Python
            Development" course offers a fun and interactive way to learn Python, one of the most
            popular and versatile programming languages in the world!
          </Typography>
          <Typography variant="h6" gutterBottom color="primary">
            Why Python?
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <List>
            <ListItem>
              <ListItemIcon>
                <SentimentSatisfiedAlt fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary="Easy to Learn"
                secondary="Python’s simple syntax is perfect for kids, making coding more accessible and
            enjoyable."
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Construction fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary="Real-World Skills"
                secondary="Python is used by professionals in various fields, from web development to data
            science."
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ColorLens fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary="Creativity & Problem-Solving"
                secondary="Coding with Python enhances creativity and promotes critical thinking."
              ></ListItemText>
            </ListItem>
          </List>

          <Typography variant="h6" gutterBottom color="primary">
            Course Features:
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <List>
            <ListItem>
              <ListItemIcon>
                <SportsEsports fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary="Interactive Games & Challenges"
                secondary="Learn through play with our engaging coding games designed to reinforce new
            skills."
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <TrendingUp fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary="Progress at Your Pace"
                secondary="Our self-guided modules allow kids to learn at a comfortable pace, perfect for
            ages 7 to 16."
              ></ListItemText>
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <SupervisorAccount fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary="Parental Involvement"
                secondary="Parents can track progress and celebrate milestones as kids advance through the
            course."
              ></ListItemText>
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} md={4}>
          <WhatsIncluded />
        </Grid>
      </Grid>
      <Typography variant="h4" align="center" sx={{ my: 5 }} id="prices">
        Choose Your Plan
      </Typography>
      <Container maxWidth="md">
        <Stack sx={{ textAlign: 'center', alignItems: 'center', my: 4 }}>
          <Typography variant="body1" align="center" id="prices" gutterBottom>
            Want to sign up your institution for full access to all students? Click here:
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<School />}
            component={Link}
            href="/schools"
          >
            Coding for Kids for Institutions
          </Button>
        </Stack>
      </Container>

      <Grid container spacing={4}>
        <Grid item xs={12} md={4}>
          <PricingBox>
            <Typography
              variant="h6"
              align="center"
              color="text.secondary"
              sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
            >
              <SupervisorAccount /> For Parents
            </Typography>
            <Typography variant="h4" align="center">
              Buy Course
            </Typography>
            <Typography align="center" color="text.secondary">
              Buy the Python Development Course!
            </Typography>
            <Typography variant="h4" align="center" color="primary">
              US $49
            </Typography>
            {isPlanLoading ? (
              <Box
                component="div"
                sx={{ display: 'flex', gap: 1, flexDirection: 'row', alignItems: 'center' }}
              >
                <CircularProgress size={32} />
              </Box>
            ) : studentsWithoutPurchasedCourse?.length === 0 ? (
              <Chip icon={<Check />} label="Course Purchased" color="primary" variant="outlined" />
            ) : (
              <Button
                variant="outlined"
                color="primary"
                size="large"
                disabled={!!currentUser?.organization_id}
                onClick={() => openCheckout(PADDLE_SINGLE_PRICE_ID)}
              >
                Buy Now
              </Button>
            )}
            <Link href="/tos">Refund Policy</Link>
            <Divider />
            <List sx={{ width: '100%' }}>
              <ListItem>
                <ListItemIcon>
                  <Verified color="primary" />
                </ListItemIcon>
                <ListItemText>Full access to over 50 levels</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Verified color="primary" />
                </ListItemIcon>
                <ListItemText>Get a course certificate</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Verified color="primary" />
                </ListItemIcon>
                <ListItemText>Unlimited time access</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Verified color="primary" />
                </ListItemIcon>
                <ListItemText>Teach up to 5 students</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Verified color="disabled" />
                </ListItemIcon>
                <ListItemText sx={{ textDecoration: 'line-through', color: 'text.secondary' }}>
                  Access to all courses
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Verified color="disabled" />
                </ListItemIcon>
                <ListItemText sx={{ textDecoration: 'line-through', color: 'text.secondary' }}>
                  Teacher administration
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Verified color="disabled" />
                </ListItemIcon>
                <ListItemText sx={{ textDecoration: 'line-through', color: 'text.secondary' }}>
                  Student administration
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Verified color="disabled" />
                </ListItemIcon>
                <ListItemText sx={{ textDecoration: 'line-through', color: 'text.secondary' }}>
                  Course quizzes
                </ListItemText>
              </ListItem>
            </List>
          </PricingBox>
        </Grid>
        <Grid item xs={12} md={4}>
          <PricingBox>
            <Typography
              variant="h6"
              align="center"
              color="text.secondary"
              sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
            >
              <SupervisorAccount /> For Parents
            </Typography>
            <Typography variant="h4" align="center">
              Subscribe
            </Typography>
            <Typography align="center" color="text.secondary">
              Get full unlimited access to all courses!
            </Typography>
            <Typography variant="h4" align="center" color="primary">
              US $9
              <Box component="span" sx={{ fontSize: '0.5em', color: 'text.primary' }}>
                /month
              </Box>
            </Typography>
            {isPlanLoading ? (
              <Box
                component="div"
                sx={{ display: 'flex', gap: 1, flexDirection: 'row', alignItems: 'center' }}
              >
                <CircularProgress size={32} />
              </Box>
            ) : currentPlan?.subscription === 'parent' ? (
              <Chip icon={<Check />} label="Subscribed" color="primary" variant="outlined" />
            ) : (
              <Button
                variant="contained"
                color="primary"
                size="large"
                disabled={
                  isPlanLoading || currentPlan?.subscription || currentUser?.organization_id
                }
                onClick={() => openCheckout(PADDLE_SUBSCRIPTION_PRICE_ID)}
              >
                Subscribe
              </Button>
            )}
            <Link href="/tos">Refund Policy</Link>
            <Divider />
            <List sx={{ width: '100%' }}>
              <ListItem>
                <ListItemIcon>
                  <Verified color="primary" />
                </ListItemIcon>
                <ListItemText>Full access to over 50 levels</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Verified color="primary" />
                </ListItemIcon>
                <ListItemText>Get a course certificate</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Verified color="primary" />
                </ListItemIcon>
                <ListItemText>Unlimited time access</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Verified color="primary" />
                </ListItemIcon>
                <ListItemText>Teach up to 5 students</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Verified color="primary" />
                </ListItemIcon>
                <ListItemText>Access to all courses</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Verified color="disabled" />
                </ListItemIcon>
                <ListItemText sx={{ textDecoration: 'line-through', color: 'text.secondary' }}>
                  Teacher administration
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Verified color="disabled" />
                </ListItemIcon>
                <ListItemText sx={{ textDecoration: 'line-through', color: 'text.secondary' }}>
                  Student administration
                </ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Verified color="disabled" />
                </ListItemIcon>
                <ListItemText sx={{ textDecoration: 'line-through', color: 'text.secondary' }}>
                  Course quizzes
                </ListItemText>
              </ListItem>
            </List>
          </PricingBox>
        </Grid>
        <Grid item xs={12} md={4}>
          <PricingBox>
            <Typography
              variant="h6"
              align="center"
              color="text.secondary"
              sx={{ display: 'flex', alignItems: 'center', gap: 2 }}
            >
              <School /> For Schools
            </Typography>
            <Typography variant="h4" align="center">
              Subscribe
            </Typography>
            <Typography align="center" color="text.secondary">
              Teach, track and certify your students!
            </Typography>
            <Typography variant="h4" align="center" color="primary">
              US $49
              <Box component="span" sx={{ fontSize: '0.5em', color: 'text.primary' }}>
                /month
              </Box>
            </Typography>
            <Stack justifyContent="center">
              {isPlanLoading ? (
                <Box
                  component="div"
                  sx={{ display: 'flex', gap: 1, flexDirection: 'row', alignItems: 'center' }}
                >
                  <CircularProgress size={32} />
                </Box>
              ) : currentPlan?.subscription === 'small_organization' ? (
                <Chip icon={<Check />} label="Subscribed" color="primary" variant="outlined" />
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  component="a"
                  href="/schools"
                >
                  Read More
                </Button>
              )}

              {currentPlan?.subscription === 'parent' ? (
                <UpgradePrice planName="small_organization" />
              ) : null}
            </Stack>
            <Link href="/tos">Refund Policy</Link>
            <Divider />
            <List sx={{ width: '100%' }}>
              <ListItem>
                <ListItemIcon>
                  <Verified color="primary" />
                </ListItemIcon>
                <ListItemText>Full access to over 50 levels</ListItemText>
              </ListItem>

              <ListItem>
                <ListItemIcon>
                  <Verified color="primary" />
                </ListItemIcon>
                <ListItemText>Provide course certificates</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Verified color="primary" />
                </ListItemIcon>
                <ListItemText>Unlimited time access</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Verified color="primary" />
                </ListItemIcon>
                <ListItemText>Teach up to 200 students</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Verified color="primary" />
                </ListItemIcon>
                <ListItemText>Access to all courses</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Verified color="primary" />
                </ListItemIcon>
                <ListItemText>Teacher administration</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Verified color="primary" />
                </ListItemIcon>
                <ListItemText>Student administration</ListItemText>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <Verified color="primary" />
                </ListItemIcon>
                <ListItemText>Course quizzes</ListItemText>
              </ListItem>
            </List>
          </PricingBox>
        </Grid>
      </Grid>
    </Container>
  );
}
