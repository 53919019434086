import { MoveableRef } from '../Moveable';
import { GameObjectContextValue, Position } from '../GameObject';
import { ComponentRegistryUtils } from '../useComponentRegistry';

interface PlayerInitialProps {
  transform: GameObjectContextValue['transform'];
  startingPosition: Position;
  getComponent: ComponentRegistryUtils['getComponent'];
}
export const setPlayerInitialPosition = async ({
  transform,
  startingPosition,
  getComponent
}: PlayerInitialProps) => {
  transform.setX(startingPosition.x);
  transform.setY(startingPosition.y);
  await getComponent<MoveableRef>('Moveable')?.rotate([0, -1]);
};
