import { useState } from 'react';
import Collider from '../@core/Collider';
import GameObject, { GameObjectProps } from '../@core/GameObject';
import Sprite, { SpriteProps } from '../@core/Sprite';
import useGameEvent from '../@core/useGameEvent';
import useGameObject from '../@core/useGameObject';
import waitForMs from '../@core/utils/waitForMs';

interface JarProps {
  props: GameObjectProps;
  state: string;
  spriteData: SpriteProps;
}

export default function Jar({ props, state, spriteData }: JarProps) {
  const [appearPosition, setAppearPosition] = useState({ x: props.x, y: props.y });
  const [disabled, setDisabled] = useState(true);
  const { getRef, forceUpdate } = useGameObject();
  const [jarState, setJarState] = useState(state);
  const [name, setName] = useState(`${jarState}-${props.x}-${props.y}`);

  useGameEvent('combine', (position) => {
    if (appearPosition.x !== position.x || appearPosition.y !== position.y) return;

    const newJarState = position.positionType === 'horizontal' ? 'jar1' : 'jar1';

    setJarState(newJarState);
    setName(`${newJarState}-${position.x}-${position.y}`);
    waitForMs(0).then(() => forceUpdate());

    getRef().setDisabled(false);
    setDisabled(false);

    setAppearPosition({ ...position });
    waitForMs(0).then(() => forceUpdate());
  });

  return (
    <GameObject
      key={name}
      name={name}
      persisted={false}
      {...props}
      {...appearPosition}
      layer="jar"
      disabled={disabled}
    >
      <Sprite {...spriteData} state={jarState} />
      <Collider isTrigger />
    </GameObject>
  );
}
