import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { configureCSVSchema } from '../components/Organization/Student/configureCSVSchema';

export const useConfigureCSVForm = () => {
  const formMethods = useForm({
    resolver: yupResolver(configureCSVSchema),
    defaultValues: {
      username: 'username',
      password: 'password',
      full_name: 'full_name',
      separator: ',',
      first_name: '',
      last_name: '',
      email: '',
      isFullNameSplit: false,
    },
    mode: 'onChange'
  });

  return formMethods;
};
