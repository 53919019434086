import { useState } from 'react';
import Collider from '../@core/Collider';
import GameObject, { GameObjectProps } from '../@core/GameObject';
import Sprite, { SpriteProps } from '../@core/Sprite';
import useGameEvent from '../@core/useGameEvent';
import useGameObject from '../@core/useGameObject';
import waitForMs from '../@core/utils/waitForMs';

interface PurpleMixProps {
  props: GameObjectProps;
  state: string;
  spriteData: SpriteProps;
}

export default function PurpleMix({ props, state, spriteData }: PurpleMixProps) {
  const [appearPosition, setAppearPosition] = useState({ x: props.x, y: props.y });
  const [disabled, setDisabled] = useState(true);
  const { getRef, forceUpdate } = useGameObject();
  const [mixState, setMixState] = useState(state);
  const [name, setName] = useState(`${mixState}-${props.x}-${props.y}`);

  useGameEvent('combine', (position) => {
    if (appearPosition.x !== position.x || appearPosition.y !== position.y) return;

    const newMixState = position.positionType === 'horizontal' ? 'purplemix3' : 'purplemix3';

    setMixState(newMixState);
    setName(`${newMixState}-${position.x}-${position.y}`);
    waitForMs(0).then(() => forceUpdate());

    getRef().setDisabled(false);
    setDisabled(false);

    setAppearPosition({ ...position });
    waitForMs(0).then(() => forceUpdate());
  });

  return (
    <GameObject
      key={name}
      name={name}
      persisted={false}
      {...props}
      {...appearPosition}
      layer="purplemix"
      disabled={disabled}
    >
      <Sprite {...spriteData} state={mixState} />
      <Collider isTrigger />
    </GameObject>
  );
}
