import Collider, { TriggerEvent } from '../@core/Collider';
import GameObject, { GameObjectProps } from '../@core/GameObject';
import { useSound } from '../@core/Sound';
import Sprite, { SpriteProps } from '../@core/Sprite';
import useGameObject from '../@core/useGameObject';
import useGameObjectEvent from '../@core/useGameObjectEvent';
import soundData from '../soundData';
import useSceneManager from '../@core/useSceneManager';
import useGame from '../@core/useGame';
import { DidCollectEvent } from '../@core/Collectable';

function DisableOnTriggerScript({ itemName }: { itemName: string }) {
  const { levelData, setLevelData, inventory, setInventoryData } = useSceneManager();
  const { publish } = useGame();
  const { getRef } = useGameObject();

  const playSfx = useSound(soundData.good);

  useGameObjectEvent<TriggerEvent>('trigger', (other) => {
    // skip xmarkLight for complex collectables
    if (itemName === 'xmarkLight' || itemName === 'xmarkGold') return;

    if (other.name === 'player') {
      getRef().setDisabled(true);
      const newLevelData = levelData.map((item) => {
        if (item.type === itemName) {
          return { ...item, score: (item.score += 1) };
        }
        return item;
      });
      setLevelData(newLevelData);
      setInventoryData(
        inventory.map((item) => {
          if (item.type === itemName) {
            return { ...item, count: (item.count += 1) };
          }
          return item;
        })
      );
      publish<DidCollectEvent>('did-collect', newLevelData);
      setTimeout(() => {
        playSfx();
      }, 20);
    }
  });

  return null;
}

interface ItemCollectProps {
  props: GameObjectProps;
  state: string;
  spriteData: SpriteProps;
  itemName: string;
}

export default function ItemCollect({ props, state, spriteData, itemName }: ItemCollectProps) {
  const name = `${state}-${props.x}-${props.y}`; // fallback name required for persisted flag

  return (
    <GameObject name={name} persisted={false} {...props} layer="item">
      <Sprite {...spriteData} state={state} />
      <Collider isTrigger />
      <DisableOnTriggerScript itemName={itemName} />
    </GameObject>
  );
}
