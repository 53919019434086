import React from 'react';
import { DataGrid, GridColDef, GridRowsProp, GridRowSelectionModel } from '@mui/x-data-grid';
import { Box } from '@mui/material';

interface DataGridStudentsFromCSVProps {
  data: GridRowsProp;
  columns: GridColDef[];
  onProcessRowUpdate: (newRow: any) => void;
  onRowSelectionModelChange: (selectionModel: GridRowSelectionModel) => void;
  rowSelectionModel: GridRowSelectionModel;
}

const DataGridStudentsFromCSV: React.FC<DataGridStudentsFromCSVProps> = ({
  data,
  columns,
  onProcessRowUpdate,
  onRowSelectionModelChange,
  rowSelectionModel
}) => {
  return (
    <Box
      component="div"
      sx={{ height: 400, width: '100%', display: 'flex', flexDirection: 'column', mb: 2 }}
    >
      <DataGrid
        rows={data}
        columns={columns}
        checkboxSelection
        disableRowSelectionOnClick
        processRowUpdate={onProcessRowUpdate}
        onRowSelectionModelChange={onRowSelectionModelChange}
        rowSelectionModel={rowSelectionModel}
      />
    </Box>
  );
};

export default DataGridStudentsFromCSV;
