import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { teacherSchema } from '../components/Organization/Teacher/teacherSchema';

export const useAddTeacherForm = () => {
  const formMethods = useForm({
    resolver: yupResolver(teacherSchema),
    mode: 'onChange'
  });
  return formMethods;
};
