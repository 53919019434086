import { Box, Button, Card, Typography } from '@mui/material';
import { SxProps } from '@mui/system';
import { monokaiSublime } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { ArrowLeftIcon } from '@mui/x-date-pickers';
import TipBox from './TipBox';

export default function CodeAdder({
  codeToAdd,
  sx,
  onClick
}: {
  codeToAdd: string;
  sx: SxProps;
  onClick: () => void;
}) {
  return (
    <TipBox sx={sx} name="insert-code">
      <Box
        component="div"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          gap: 2
        }}
      >
        <Typography>Click here to add this code:</Typography>
        <Box component="div" display="flex">
          <SyntaxHighlighter style={monokaiSublime} PreTag="div" language="python">
            {codeToAdd}
          </SyntaxHighlighter>
          <Box component="div" sx={{ flex: 1 }} />
          <Button color="primary" variant="contained" sx={{ ml: 1 }} onClick={onClick}>
            Add Code
          </Button>
        </Box>
      </Box>
    </TipBox>
  );
}
