import * as yup from 'yup';

export const orgStudentSchema = yup.object({
  username: yup.string().required('Username is required'),
  email: yup.string(),
  full_name: yup.string().required('Full name is required'),
  class_id: yup.string().required('Class is required'),
  password: yup.string().test({
    name: 'password-validation',
    message: 'Password must be at least 6 characters long',
    test: function (value) {
      const confirmPassword = this.parent.confirmPassword;
      if (!confirmPassword) return true;
      return !!value && value.length >= 6;
    }
  }),
  confirmPassword: yup.string().test({
    name: 'confirmPassword-validation',
    message: 'Passwords must match',
    test: function (value) {
      const password = this.parent.password;
      return password === value;
    }
  }),
  character: yup.string()
});
