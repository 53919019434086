import { Box, Container, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { AddEditTeacherUserForm } from '../../components/Organization/Teacher/AddEditTeacherForm';

export default function AddEditTeacherUser() {
  const { teacherId } = useParams();
  return (
    <Container maxWidth="lg" sx={{ mt: 3, mb: 14 }}>
      <Box component="div" display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" gutterBottom>
          {teacherId ? 'Edit Teacher' : 'Add Teacher'}
        </Typography>
      </Box>
      <AddEditTeacherUserForm />
    </Container>
  );
}
