import { useState } from 'react';
import Collider from '../@core/Collider';
import GameObject, { GameObjectProps } from '../@core/GameObject';
import Sprite, { SpriteProps } from '../@core/Sprite';
import useGameEvent from '../@core/useGameEvent';
import useGameObject from '../@core/useGameObject';
import waitForMs from '../@core/utils/waitForMs';
import useGame from '../@core/useGame';

interface ChickenHouseProps {
  props: GameObjectProps;
  state: string;
  spriteData: SpriteProps;
}

export default function ChickenHouse({ props, state, spriteData }: ChickenHouseProps) {
  const initialState = state.split('|');
  const color = initialState[1];
  const size = initialState[2];
  const [chickenHousePosition, setChickenHousePosition] = useState({ x: props.x, y: props.y });
  const [name, setName] = useState(`${state}-${props.x}-${props.y}`);
  const [disabled, setDisabled] = useState(true);
  const { getRef, forceUpdate } = useGameObject();
  const { findGameObjectsByXY } = useGame();
  const [chickenHouseState, setChickenHouseState] = useState(color);

  useGameEvent('build', (position) => {
    if (chickenHousePosition.x !== position.x || chickenHousePosition.y !== position.y) return;

    switch (position.color) {
      case 'red':
        setChickenHouseState(position.color);
        break;
      case 'orange':
        setChickenHouseState(position.color);
        break;
      case 'yellow':
        setChickenHouseState(position.color);
        break;
      case 'green':
        setChickenHouseState(position.color);
        break;
      case 'blue':
        setChickenHouseState(position.color);
        break;
      default:
        setChickenHouseState(position.color);
    }
    waitForMs(0).then(() => forceUpdate());

    setName(`${chickenHouseState}-${position.x}-${position.y}`);
    waitForMs(0).then(() => forceUpdate());

    const obj = findGameObjectsByXY(position.x, position.y);
    obj.forEach((o) => {
      const collider = o.getComponent('Collider');
      if (collider) {
        o.getComponent('Collider').setWalkable(false);
      }
    });

    getRef().setDisabled(false);
    setDisabled(false);
    setChickenHousePosition({ ...position });
    waitForMs(0).then(() => forceUpdate());
  });

  return (
    <GameObject
      key={name}
      name={name}
      persisted={false}
      {...props}
      {...chickenHousePosition}
      layer="chickenhouse"
      disabled={disabled}
    >
      <Sprite
        {...spriteData}
        state={chickenHouseState}
        frameWidth={size === 'small' ? 32 : 48}
        frameHeight={size === 'small' ? 48 : 48}
        origin="bottom-center"
      />
      <Collider />
    </GameObject>
  );
}
