import { useEffect } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { signupSchema, USER_TYPES } from '../components/SignUp/signupSchema';
import { useEmailExists } from './useEmailExists';
import { useStudentUsernameExists } from './useStudentUsernameExists';
import { useOrganizationSlugExists } from './useOrganizationSlugExists';

function toSlug(text: string): string {
  return text
    .normalize('NFD') // Normalize to NFD Unicode normal form
    .replace(/[\u0300-\u036f]/g, '') // Remove diacritics
    .toLowerCase() // Convert to lowercase
    .trim() // Trim whitespace
    .replace(/[^a-z0-9 ]/g, '') // Remove non-alphanumeric characters except spaces
    .replace(/\s+/g, '-') // Replace spaces with hyphens
    .replace(/-+/g, '-'); // Replace multiple hyphens with a single hyphen
}

export const useSignupForm = () => {
  const formMethods = useForm({
    resolver: yupResolver(signupSchema),
    defaultValues: {
      password: '',
      verify_password: '',
      full_name: '',
      student_full_name: '',
      student_username: '',
      student_password: '',
      character: 'Rabbit',
      organization_name: '',
      organization_slug: '',
      organization_type: 'private_school',
      organization_email_domain: ''
    },
    mode: 'onChange'
  });

  useEffect(() => {
    formMethods.watch((value, { name, type }) => {
      if (name === 'organization_name' && type === 'change' && value.organization_name) {
        formMethods.setValue('organization_slug', toSlug(value.organization_name));
      }
    });
  }, [formMethods.watch]);

  const email = formMethods.watch('email');
  const student_username = formMethods.watch('student_username');
  const organization_slug = formMethods.watch('organization_slug');

  const { emailExists, isLoading } = useEmailExists(email!);
  const { usernameExists, isUsernameLoading } = useStudentUsernameExists(student_username!);
  const { slugExists, isSlugLoading } = useOrganizationSlugExists(organization_slug!);

  useEffect(() => {
    if (email && !isLoading) {
      if (emailExists) {
        formMethods.setError('email', {
          type: 'manual',
          message: 'Email already exists'
        });
      } else {
        if (formMethods.formState.errors.email?.type === 'manual') {
          formMethods.clearErrors('email');
        }
      }
    }
  }, [email, emailExists, isLoading, formMethods.setError, formMethods.clearErrors]);

  useEffect(() => {
    if (student_username && !isUsernameLoading) {
      if (usernameExists) {
        formMethods.setError('student_username', {
          type: 'manual',
          message: 'Student username already exists'
        });
      } else {
        if (formMethods.formState.errors.student_username?.type === 'manual') {
          formMethods.clearErrors('student_username');
        }
      }
    }
  }, [
    student_username,
    usernameExists,
    isUsernameLoading,
    formMethods.setError,
    formMethods.clearErrors
  ]);

  useEffect(() => {
    if (organization_slug && !isSlugLoading) {
      if (slugExists) {
        formMethods.setError('organization_slug', {
          type: 'manual',
          message: 'Organization slug already exists'
        });
      } else {
        if (formMethods.formState.errors.organization_slug?.type === 'manual') {
          formMethods.clearErrors('organization_slug');
        }
      }
    }
  }, [organization_slug, slugExists, isSlugLoading, formMethods.setError, formMethods.clearErrors]);

  return formMethods;
};
