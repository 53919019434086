import React, {
  Dispatch,
  RefObject,
  SetStateAction,
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import { ComponentRegistryUtils } from './useComponentRegistry';
import useForceUpdate from './useForceUpdate';
import useGame from './useGame';
import useGameObject from './useGameObject';
import useGameObjectStore from './useGameObjectStore';
import useStateFromProp from './useStateFromProp';
import createPubSub, { PubSub } from './utils/createPubSub';

export interface Position {
  x: number;
  y: number;
}

export interface GameObjectContextValue extends ComponentRegistryUtils, PubSub {
  id: symbol;
  name: Readonly<string | undefined>;
  transform: {
    x: Readonly<number>;
    y: Readonly<number>;
    setX: Dispatch<SetStateAction<number>>;
    setY: Dispatch<SetStateAction<number>>;
    rotationX: Readonly<number>;
    setRotationX: Dispatch<SetStateAction<number>>;
    rotationY: Readonly<number>;
    setRotationY: Dispatch<SetStateAction<number>>;
  };
  sprites: {
    isCompound: boolean;
    positions: Position[];
  };
  forceUpdate: VoidFunction;
  nodeRef: RefObject<THREE.Group>;
  getRef: () => GameObjectRef;
}

export const GameObjectContext = React.createContext<GameObjectContextValue>(null!);

export type GameObjectLayer =
  | 'ground'
  | 'ground-decal'
  | 'wall'
  | 'visible-wall'
  | 'water'
  | 'obstacle'
  | 'character'
  | 'item'
  | 'bridge'
  | 'gate'
  | 'door'
  | 'chest'
  | 'tray'
  | 'jar'
  | 'purplemix'
  | 'brownmix'
  | 'halfpurplemix'
  | 'redmix'
  | 'grainstack'
  | 'wood'
  | 'stone'
  | 'chickenhouse'
  | 'draw'
  | 'fx';

export interface GameObjectProps extends Partial<Position> {
  name?: string;
  displayName?: string;
  layer?: GameObjectLayer;
  disabled?: boolean;
  persisted?: boolean;
  children?: React.ReactNode;
  rotationX?: number;
  rotationY?: number;
  layerOffset?: number;
  isCompound?: boolean;
  positions?: Position[];
}

export type GameObjectRef = Pick<GameObjectProps, 'name' | 'displayName'> & {
  id: symbol;
  layer: GameObjectLayer | undefined;
  transform: GameObjectContextValue['transform'];
  getComponent: ComponentRegistryUtils['getComponent'];
  disabled: Readonly<boolean>;
  setDisabled: Dispatch<SetStateAction<boolean>>;
  subscribe: PubSub['subscribe'];
  sprites: GameObjectContextValue['sprites'];
};

function Persistence() {
  const { getRef } = useGameObject();

  useGameObjectStore(
    '_gameObject',
    () => {
      const self = getRef();
      return {
        x: self.transform.x,
        y: self.transform.y,
        disabled: self.disabled,
        rotationX: self.transform.rotationX,
        rotationY: self.transform.rotationY
      };
    },
    (stored) => {
      const self = getRef();
      // TODO: make peristence of position optional
      //   (the position of one-time enemies like bosses should not be persisted)
      // self.transform.setX(stored.x);
      // self.transform.setY(stored.y);
      self.setDisabled(stored.disabled);
    }
  );

  return null;
}

export default function GameObject({
  name,
  displayName,
  layer,
  layerOffset,
  children,
  disabled: initialDisabled = false,
  persisted = false,
  isCompound: initialIsCompound = false,
  positions: initialPositions,
  ...props
}: GameObjectProps) {
  const identifier = useRef(Symbol('GameObject'));
  const node = useRef(null);
  const [registry] = useState(() => new Map<string, any>());
  const [pubSub] = useState(() => createPubSub());
  const [x, setX] = useStateFromProp(props.x || 0);
  const [y, setY] = useStateFromProp(props.y || 0);
  const [rotationX, setRotationX] = useStateFromProp(props.rotationX || 0);
  const [rotationY, setRotationY] = useStateFromProp(props.rotationY || -1);
  const [disabled, setDisabled] = useState(initialDisabled);
  const [isCompound, setIsCompound] = useState(initialIsCompound);
  const [positions, setPositions] = useState<Position[]>(initialPositions || []);
  const { registerGameObject, unregisterGameObject } = useGame();
  const forceUpdate = useForceUpdate();

  const registryUtils = useMemo<ComponentRegistryUtils>(
    () => ({
      registerComponent(id, api) {
        registry.set(id, api);
      },
      unregisterComponent(id) {
        registry.delete(id);
      },
      getComponent(id) {
        return registry.get(id);
      }
    }),
    [registry]
  );

  const transform = useMemo<GameObjectContextValue['transform']>(
    () => ({
      x,
      y,
      setX,
      setY,
      rotationX,
      setRotationX,
      rotationY,
      setRotationY
    }),
    [x, y, setX, setY, rotationX, setRotationX, rotationY, setRotationY]
  );

  const sprites = useMemo<GameObjectContextValue['sprites']>(
    () => ({
      isCompound,
      positions,
      setIsCompound,
      setPositions
    }),
    [isCompound, positions, setIsCompound, setPositions]
  );

  const gameObjectRef = useMemo<GameObjectRef>(
    () => ({
      id: identifier.current,
      name,
      displayName,
      layer,
      transform,
      getComponent: registryUtils.getComponent,
      disabled,
      setDisabled,
      subscribe: pubSub.subscribe,
      sprites
    }),
    [name, displayName, layer, transform, registryUtils, disabled, pubSub, sprites]
  );

  const getRef = useCallback(() => gameObjectRef, [gameObjectRef]);

  useLayoutEffect(() => {
    const id = identifier.current;
    registerGameObject(id, gameObjectRef);
    return () => unregisterGameObject(id, gameObjectRef);
  }, [registerGameObject, unregisterGameObject, gameObjectRef]);

  const contextValue: GameObjectContextValue = {
    id: identifier.current,
    name,
    transform,
    forceUpdate,
    nodeRef: node,
    getRef,
    sprites,
    ...pubSub,
    ...registryUtils
  };

  // TODO: add constants for z indices
  let offsetZ = 0;

  // if (layer?.startsWith('barn_tileset')) offsetZ = 1;
  // if (layer?.startsWith('boats_tileset')) offsetZ = 1;
  // if (layer?.startsWith('bridge_tileset')) offsetZ = 1;
  // if (layer?.startsWith('brown_cow_tileset')) offsetZ = 1;
  // if (layer?.startsWith('bush_tileset')) offsetZ = 1;
  // if (layer?.startsWith('chest_tileset')) offsetZ = 1;
  // if (layer?.startsWith('chicken_house_tileset')) offsetZ = 1;
  // if (layer?.startsWith('chicken_white_tileset')) offsetZ = 1;
  // if (layer?.startsWith('darker_grass_tileset')) offsetZ = 1;
  // if (layer?.startsWith('door_tileset')) offsetZ = 1;
  // if (layer?.startsWith('farming_tileset')) offsetZ = 1;
  // if (layer?.startsWith('fence_gate_tileset')) offsetZ = 1;
  // if (layer?.startsWith('fences_tileset')) offsetZ = 2;
  // if (layer?.startsWith('foliage_tileset')) offsetZ = 4;
  // if (layer?.startsWith('goat_baby_spritesheet_tileset')) offsetZ = 1;
  // if (layer?.startsWith('goat_baby_stripe_spritesheet_tileset')) offsetZ = 1;
  // if (layer?.startsWith('grass_tileset')) offsetZ = 1;
  // if (layer?.startsWith('grass_v2_tileset')) offsetZ = 1;
  // if (layer?.startsWith('house_tileset')) offsetZ = 1;
  // if (layer?.startsWith('light_cow_tileset')) offsetZ = 1;
  // if (layer?.startsWith('mill_tileset')) offsetZ = 4;
  // if (layer?.startsWith('path_tileset')) offsetZ = 1;
  // if (layer?.startsWith('piglet_spritesheet_tileset')) offsetZ = 1;
  // if (layer?.startsWith('sheep_baby_spritesheet_tileset')) offsetZ = 1;
  // if (layer?.startsWith('sheep_spritesheet_tileset')) offsetZ = 1;
  // if (layer?.startsWith('signs_tileset')) offsetZ = 1;
  // if (layer?.startsWith('soil_tileset')) offsetZ = 1;
  // if (layer?.startsWith('water_object_tileset')) offsetZ = 1;
  // if (layer?.startsWith('water_tileset')) offsetZ = 1;
  // if (layer?.startsWith('water_tray_tileset')) offsetZ = 1;
  // if (layer?.startsWith('well_tileset')) offsetZ = 1;
  // if (layer?.startsWith('wilderness_props_tileset')) offsetZ = 1;
  // if (layer?.startsWith('work_station_tileset')) offsetZ = 1;

  // if (layer === 'ground') offsetZ = -1;
  // if (layer === 'ground-decal') offsetZ = 0.1;
  // if (layer === 'obstacle') offsetZ = 0.2;
  if (layer === 'item') offsetZ = 7;
  if (layer === 'bridge') offsetZ = 2;
  if (layer === 'grainstack') offsetZ = 2;
  if (layer === 'jar') offsetZ = 2;
  if (layer === 'purplemix') offsetZ = 2;
  if (layer === 'brownmix') offsetZ = 2;
  if (layer === 'halfpurplemix') offsetZ = 2;
  if (layer === 'redmix') offsetZ = 2;
  if (layer === 'tray') offsetZ = 7;
  if (layer === 'chickenhouse') offsetZ = 9;
  if (layer === 'gate') offsetZ = 7;
  if (layer === 'door') offsetZ = 7;
  if (layer === 'chest') offsetZ = 20;
  if (layer === 'character') offsetZ = 9;
  if (layer === 'fx') offsetZ = 10;
  if (layer === 'draw') offsetZ = 2;


  let layerOffsetZ = (-y + offsetZ) / 100;
  if (layerOffset) {
    layerOffsetZ = (-y + layerOffset) / 100;
  }

  return (
    <GameObjectContext.Provider value={contextValue}>
      {persisted && <Persistence />}
      <group ref={node} position={[x, y, layerOffsetZ]}>
        {!disabled && children}
      </group>
    </GameObjectContext.Provider>
  );
}
