import { Box, Button, Container, Typography } from '@mui/material';
import { StudentUsersList } from '../../components/Profile/StudentUsersList';
import { useGenericModal } from '../../context/GenericModalContext';
import { useCheckStudentsLimit } from '../../hooks/useCheckStudentsLimit';

export default function ProfileStudentUsers() {
  const { setOpenModal: setOpenUpgradeToOrgModal, setContent } =
    useGenericModal('upgrade-to-org-modal');

  const { data: isStudentsLimit } = useCheckStudentsLimit();

  const handleUpgradeToOrgStudentsLimit = () => {
    setContent(
      'You cannot create more than 5 students. To manage more than 5 students, please upgrade to the organizational tier and create a class instead.'
    );
    setOpenUpgradeToOrgModal(true);
  };

  const handleUpgradeToOrgCSV = () => {
    setContent(
      'To access the import CSV feature and upload students, please upgrade to the Organization tier. Enjoy enhanced features and manage your students more effectively.'
    );
    setOpenUpgradeToOrgModal(true);
  };
  return (
    <Container maxWidth="lg" sx={{ mt: 3, mb: 14 }}>
      <Box component="div" display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" gutterBottom>
          My Students
        </Typography>
        <Box component="div" display="flex" gap={2}>
          <Button variant="outlined" color="primary" onClick={handleUpgradeToOrgCSV}>
            Import CSV
          </Button>
          {isStudentsLimit ? (
            <Button variant="contained" color="primary" onClick={handleUpgradeToOrgStudentsLimit}>
              Add Student
            </Button>
          ) : (
            <Button variant="contained" color="primary" href="/profile/students/add">
              Add Student
            </Button>
          )}
        </Box>
      </Box>

      <StudentUsersList />
    </Container>
  );
}
