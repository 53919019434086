import { Button, TextField, Typography, Container, Box, Paper } from '@mui/material';
import { useMutation } from 'react-query';
import { ApiError, AuthService } from '../client';
import { useSnackBarContext } from '../context/SnackBarContext';
import { Controller, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { forgotPasswordSchema } from '../components/Profile/forgotPasswordSchema';
import { AxiosError } from 'axios';
import { Api } from '@mui/icons-material';

export default function ForgotPasswordPage() {
  const { createSnackBar } = useSnackBarContext();

  const forgotPasswordMutation = useMutation(AuthService.forgotPasswordApiAuthForgotPasswordPost, {
    onSuccess: () => {
      createSnackBar({
        content: 'Password reset email sent.',
        autoHide: true,
        severity: 'success'
      });
    }
  });

  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting }
  } = useForm({
    resolver: yupResolver(forgotPasswordSchema),
    mode: 'onChange'
  });

  const onSubmit = async ({ email }: { email: string }) => {
    try {
      await forgotPasswordMutation.mutateAsync(email);
    } catch (error) {
      if (!(error instanceof ApiError)) {
        return;
      }
      if (error.status === 404) {
        createSnackBar({
          content: 'E-mail not found.',
          autoHide: true,
          severity: 'error'
        });
      } else {
        createSnackBar({
          content: 'Unknown error.',
          autoHide: true,
          severity: 'error'
        });
      }
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        paddingY: 15
      }}
    >
      <Paper
        sx={{
          p: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Typography component="h1" variant="h5" sx={{ display: 'flex', gap: 1 }}>
          Reset Password
        </Typography>
        <Box component="form" noValidate sx={{ mt: 1 }} onSubmit={handleSubmit(onSubmit)}>
          <Controller
            name="email"
            control={control}
            render={({ field: { value, onChange } }) => (
              <TextField
                type="email"
                error={!!errors.email}
                helperText={errors.email?.message}
                fullWidth
                label="Email Address"
                autoComplete="email"
                autoFocus
                margin="normal"
                value={value}
                onChange={onChange}
                required
              />
            )}
          />
          <Button
            type="submit"
            fullWidth
            variant="contained"
            sx={{ mt: 3, mb: 2 }}
            size="large"
            disabled={forgotPasswordMutation.isLoading || isSubmitting}
          >
            Reset My Password
          </Button>
        </Box>
      </Paper>
    </Container>
  );
}
