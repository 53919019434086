import { useQuery } from 'react-query';
import { CertificateService } from '../../client';
import { Stack, Typography } from '@mui/material';
import CertificateCard from '../../components/Certificate/CertificateCard';
import React from 'react';
import { Loader } from '../../components/Loader';

export default function StudentCertificates({ studentId }: { studentId: string }) {
  const { data: certificates, isLoading } = useQuery('certificates', () =>
    CertificateService.getCertificationsApiCertificateStudentStudentIdGet(studentId)
  );

  if (isLoading) {
    return <Loader />;
  }
  return (
    <Stack gap={4}>
      {certificates && certificates.length > 0 ? (
        certificates.map((certificate) => (
          <CertificateCard key={certificate.id} certificate={certificate} />
        ))
      ) : (
        <Typography variant="body1">No certificates found.</Typography>
      )}
    </Stack>
  );
}
