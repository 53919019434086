import useComponentRegistry, { ComponentRef } from './useComponentRegistry';
import useGame from './useGame';
import { PubSubEvent } from './utils/createPubSub';
import { GameLevelData } from './SceneManager';

export type DidCollectEvent = PubSubEvent<'did-collect', GameLevelData>;

export type CollectibleRef = ComponentRef<
  'Collectible',
  {
    onCollect: (levelData: GameLevelData) => Promise<void>;
  }
>;

export default function Collectible() {
  const { publish } = useGame();
  useComponentRegistry<CollectibleRef>('Collectible', {
    // this function is to be called after an item has been successfully collected
    async onCollect(levelData) {
      await publish<DidCollectEvent>('did-collect', levelData);
    }
  });

  return null;
}
