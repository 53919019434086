import { useCallback } from 'react';

export const useCertificateActions = (
  certId: string,
  name: string,
  date: string,
  courseTitle: string
) => {
  const handleDownload = useCallback(
    async (endpoint: 'jpg' | 'pdf') => {
      try {
        const url = `/api/certificate/${certId}/${endpoint}`;

        window.location.href = url;
      } catch (error) {
        console.error('Download failed:', error);
      }
    },
    [certId]
  );

  const handleSendByEmail = useCallback((): void => {
    const subject = `Coding for Kids - ${courseTitle} Certificate`;
    const body = `Hi there,

I am excited to share that I have completed the ${courseTitle}. I wanted to share this achievement with you!

You can view my certificate here: https://codingforkids.io/certificate/${certId}

Cheers,
${name}`;

    const params = new URLSearchParams({
      subject: subject,
      body: body
    });

    window.open(`mailto:?${params.toString()}`, '_blank');
  }, [name, courseTitle, certId]);

  const handleAddToProfile = useCallback((): void => {
    const issueDate = new Date(date);
    const params = new URLSearchParams({
      startTask: 'CERTIFICATION_NAME',
      name: courseTitle,
      organizationId: '71257632',
      issueYear: issueDate.getFullYear().toString(),
      issueMonth: (issueDate.getMonth() + 1).toString(),
      certUrl: `https://codingforkids.io/certificate/${certId}`,
      certId: encodeURIComponent(certId)
    });

    const linkedInUrl = `https://www.linkedin.com/profile/add?${params.toString()}`;
    window.open(linkedInUrl, '_blank');
  }, [date, certId, courseTitle]);

  return {
    handleDownloadImage: (): Promise<void> => handleDownload('jpg'),
    handleDownloadPDF: (): Promise<void> => handleDownload('pdf'),
    handleSendByEmail,
    handleAddToProfile
  };
};
