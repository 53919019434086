import { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useSnackBarContext } from '../context/SnackBarContext';

export const useVerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const { createSnackBar } = useSnackBarContext();

  const checkEmailVerified = () => {
    switch (searchParams.get('email_verified')) {
      case 'True':
        createSnackBar({
          content: 'Your email has been successfully verified.',
          autoHide: true,
          severity: 'success'
        });
        navigate('/');
        break;
      case 'False':
        createSnackBar({
          content: 'There was an error verifying your email.',
          autoHide: true,
          severity: 'error'
        });
        navigate('/');
        break;
    }
  };

  useEffect(() => {
    checkEmailVerified();
  }, [searchParams]);

  return null;
};
