import Collider from '../@core/Collider';
import GameObject, { GameObjectProps } from '../@core/GameObject';
import Sprite, { SpriteProps } from '../@core/Sprite';
import useGameObject from '../@core/useGameObject';
import useGameObjectEvent from '../@core/useGameObjectEvent';
import useGame from '../@core/useGame';
import useSceneManager from '../@core/useSceneManager';
import { useApiLevelData } from '../context/ApiLevelDataContext';
import { UNEXPECTED_ERRORS } from '../@core/utils/gameErrors';
import { DidCollectEvent } from '../@core/Collectable';
import waitForMs from '../@core/utils/waitForMs';
import Interactable, { InteractionEvent } from '../@core/Interactable';
import { useRef } from 'react';
import { useSound } from '../@core/Sound';
import soundData from '../soundData';
import { Watering } from '../@core/utils/movementHelpers';
import { useCommandError } from '../hooks/useCommandError';
import { XMARK_LIST } from '../@core/utils/xmarkList';

const wateringData = ['trayFill', 'trayEmpty', 'crop'];

function WateringScript() {
  const xmarkState = useRef(false);
  const { getRef } = useGameObject();
  const { publish, findGameObjectByName, findGameObjectsByXY, findCompoundObjectByXY } = useGame();
  const { levelData, setLevelData, inventory } = useSceneManager();
  const { apiLevelData } = useApiLevelData();
  const playWaterSound = useSound(soundData.water);
  const { triggerError } = useCommandError();

  useGameObjectEvent<InteractionEvent>('interaction', async ({ obj, step }) => {
    const waterStep = step as Watering;
    const watering = waterStep.watering;

    if (!watering) return;

    if (!apiLevelData) return;

    const { levelGoals } = apiLevelData;

    const actionPoint = {
      x: getRef().transform.x,
      y: getRef().transform.y
    };
    const dirX = obj.transform.rotationX;
    const dirY = obj.transform.rotationY;

    const wateringPosition = {
      x: actionPoint.x + dirX,
      y: actionPoint.y + dirY
    };

    const wateringObjectGoal = levelGoals.find(
      (goal) =>
        wateringData.includes(goal.data) &&
        XMARK_LIST.includes(goal.type || '') &&
        goal.action === 'water' &&
        goal?.position?.[0] === actionPoint.x &&
        goal?.position?.[1] === actionPoint.y
    );

    if (!wateringObjectGoal) {
      triggerError('Unexpected game error');
      return;
    }

    const targetObjectsPosition = {
      x: wateringObjectGoal.targetPosition?.[0],
      y: wateringObjectGoal.targetPosition?.[1]
    };

    if (!targetObjectsPosition.x || !targetObjectsPosition.y) {
      triggerError('Unexpected game error');
      return;
    }

    const targetObjects = findGameObjectsByXY(targetObjectsPosition.x, targetObjectsPosition.y);

    const waterableObject = targetObjects.find((o) =>
      wateringData.find((d) => o.name?.startsWith(d) || o.layer?.startsWith(d))
    );

    const farmingObject = targetObjects.find(
      (o) =>
        o.layer?.startsWith('farming') ||
        o.layer?.startsWith('wilderness') ||
        o.layer?.startsWith('soil')
    );

    if (
      targetObjectsPosition.x !== wateringPosition.x ||
      targetObjectsPosition.y !== wateringPosition.y
    ) {
      triggerError(UNEXPECTED_ERRORS.water.position);
      return;
    }

    const waterablePart = findCompoundObjectByXY(targetObjectsPosition.x, targetObjectsPosition.y);

    const waterableType = wateringData.find(
      (d) => waterablePart?.name?.startsWith(d) || waterablePart?.layer?.startsWith(d)
    );

    if (!waterablePart && !farmingObject) {
      triggerError(UNEXPECTED_ERRORS.water.position);
      return;
    }

    const inventoryItem = inventory.find((item) => item.type === wateringObjectGoal?.cost?.type);

    const costAmount = wateringObjectGoal?.cost?.amount || 0;

    const inventoryCount = inventoryItem?.count || 0;

    if (costAmount !== 0) {
      if (!inventoryItem || inventoryCount < costAmount) {
        const waterCostType = wateringObjectGoal?.cost?.type || 'canteen';
        triggerError(UNEXPECTED_ERRORS.water[waterCostType]);
        return;
      }
    }

    playWaterSound();

    const newLevelData = levelData.map((item) => {
      if (
        XMARK_LIST.includes(item.type || '') &&
        item.action === 'water' &&
        item.position?.[0] === actionPoint.x &&
        item.position?.[1] === actionPoint.y
      ) {
        return { ...item, score: (item.score += 1) };
      }
      return item;
    });

    setLevelData(newLevelData);

    const playerObject = findGameObjectByName('player');
    const playerInteractable = playerObject?.getComponent('Interactable');
    await playerInteractable.interact({
      position: { x: waterablePart?.transform.x, y: waterablePart?.transform.y },
      step: { state: 'fill' },
      command: `water-${waterableType}`
    });
    await waitForMs(3000);

    await waitForMs(50);
    publish<DidCollectEvent>('did-collect', newLevelData);
    xmarkState.current = !xmarkState.current;
    if (xmarkState.current) {
      getRef().setDisabled(true);
    }
    return await waitForMs(0);
  });

  return null;
}

interface WaterProps {
  props: GameObjectProps;
  state: string;
  spriteData: SpriteProps;
  itemName: string;
}

export default function WaterObject({ props, state, spriteData }: WaterProps) {
  const name = `${state}-${props.x}-${props.y}`;

  return (
    <GameObject name={name} persisted={false} {...props} layer="item">
      <Sprite {...spriteData} state={state} />
      <Collider isTrigger />
      <Interactable type="water" />
      <WateringScript />
    </GameObject>
  );
}
