import React, { forwardRef, useState } from 'react';
import {
  Box,
  Button,
  Dialog,
  DialogContent,
  Grid,
  TextField,
  Typography,
  Zoom
} from '@mui/material';

import { TransitionProps } from '@mui/material/transitions';
import { useMutation, useQueryClient } from 'react-query';
import { useGenericModal } from '../../../context/GenericModalContext';
import { OrganizationService } from '../../../client';
import { Controller, useForm } from 'react-hook-form';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';

interface InviteTeacherData {
  email: string;
}

const Transition = forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Zoom in appear ref={ref} {...props} />;
});

const inviteTeacherSchema = yup.object().shape({
  email: yup.string().email("Email should be valid.").required("Email is required.")
});

export default function InviteTeacherModal() {
  const { openModal: open, setOpenModal: setOpenSignUpModal } = useGenericModal('signUp');
  const queryClient = useQueryClient();

  const { control, handleSubmit } = useForm({
    resolver: yupResolver(inviteTeacherSchema),
    mode: 'onChange'
  });

  const handleClose = () => {
    setOpenSignUpModal(false);
  };

  const inviteTeacherMutation = useMutation(
    (newUser: InviteTeacherData) =>
      OrganizationService.inviteTeacherApiOrganizationInviteTeacherPost(newUser.email),
    {
      onSuccess: () => {
        setOpenSignUpModal(false);
        queryClient.invalidateQueries('teachers');
      }
    }
  );

  const onSubmit = (data: InviteTeacherData) => {
    inviteTeacherMutation.mutate({
      email: data.email
    });
  };

  return (
    <Dialog
      open={open}
      maxWidth="sm"
      onClose={handleClose}
      TransitionComponent={Transition}
      keepMounted
      scroll="paper"
    >
      <DialogContent>
        <Box
          component="div"
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center'
          }}
        >
          <Typography component="h1" variant="h5" sx={{ py: 2 }}>
            Invite a Teacher
          </Typography>
          <Typography variant="body2">
            The invitation will be sent to the teacher's email address.
          </Typography>

          <Box
            component="form"
            onSubmit={handleSubmit(onSubmit)}
            noValidate
            sx={{ mt: 1, width: '100%' }}
          >
            <Grid container columnSpacing={1}>
              <Grid item xs={12}>
                <Controller 
                  name="email"
                  control={control}
                  render={({ field, fieldState: { error } }) => (
                    <TextField
                      margin="normal"
                      required
                      fullWidth
                      id="email"
                      label="Email Address"
                      autoComplete="email"
                      error={!!error}
                      helperText={error ? error.message : null}
                      {...field}
                    />
                  )}
                />
              </Grid>
            </Grid>
            <Button type="submit" fullWidth variant="contained" sx={{ mt: 3 }} size="large">
              Send Invitation
            </Button>

            {inviteTeacherMutation.isError && (
              <Typography color="error" variant="body2">
                Error inviting a teacher. Please try again.
              </Typography>
            )}
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
