import soundData from '../soundData';
import { useSound } from './Sound';
import useGameEvent from './useGameEvent';

export const SoundEventsLayer = () => {
  const playSfx = useSound(soundData.success);

  useGameEvent('finish-level', (isLevelCompleted) => {
    if (isLevelCompleted) {
      playSfx();
    }
  });
  return null;
};
