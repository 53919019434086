import { useQuery } from 'react-query';
import { ApiError, LevelData, LevelService, UserType } from '../client';
import { UserContext, UserTypeMap } from '../pages/App';
import { useContext, useState } from 'react';
import { useSnackBarContext } from '../context/SnackBarContext';
import { useNavigate, useParams } from 'react-router-dom';
import python from '../assets/python-logo-white.png';

export default function useLevels() {
  const { courseSlug, levelSlug, studentId } = useParams();
  const { createSnackBar } = useSnackBarContext();
  const navigate = useNavigate();

  const [activeCourse, setActiveCourse] = useState<string>('python');
  const [activeLevel, setActiveLevel] = useState<string>('intro-level1');

  const courses = [
    {
      value: 'python',
      label: 'Python Development Course',
      firstLevel: 'intro-level1',
      description: 'Learn Python programming language from scratch.',
      chapters: [
        'Variables and Types',
        'Basic Operators',
        'Loops',
        'Lists',
        'Conditions',
        'Functions',
        'Dictionaries',
        'Classes'
      ],
      logo: python
    }
    // {
    //   value: 'advanced-python',
    //   label: 'Advanced Python Development Course',
    //   firstLevel: 'generators-level1',
    //   description: 'Learn advanced, practical concepts in the Python programming language.',
    //   chapters: [
    //     'Generators',
    //     'List Comprehensions'
    //   ],
    //   logo: python
    // }
  ];

  const courseId = courseSlug || activeCourse;
  const levelId = levelSlug || activeLevel;

  const currentUser = useContext(UserContext);
  const userType = UserTypeMap[currentUser?.user_type || UserType._0];

  const { data: courseData, isLoading: courseDataLoading } = useQuery(
    `courseData-${currentUser?.user_id}-${studentId ?? currentUser?.student_id}-${courseId}`,
    async () => {
      return LevelService.getCourseDataRouteApiLevelCourseSlugGet(courseId, studentId);
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      retry: false,
      cacheTime: 10000
    }
  );

  const { data: levelData, isLoading: levelDataLoading } = useQuery<LevelData, ApiError, LevelData>(
    `mapData-${courseId}-${levelId}`,
    async () => {
      return LevelService.getLevelDataRouteApiLevelDataCourseSlugSlugGet(courseId, levelId);
    },
    {
      onError: (error) => {
        switch (error.status) {
          case 403:
            createSnackBar({
              content: error.body.detail,
              autoHide: true,
              severity: 'info'
            });
            break;
          case 402:
            if (userType === 'parent') {
              navigate('/purchase');
            }
            if (userType === 'teacher' || userType === 'admin') {
              navigate('/schools');
            }
            break;
          case 401:
            navigate('/login');
            break;
          default:
            createSnackBar({
              content: 'An error occurred',
              autoHide: true,
              severity: 'error'
            });
            break;
        }
      },
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false,
      retry: false
    }
  );

  return {
    courseData,
    levelData,
    levelDataLoading,
    courseDataLoading,
    courseId,
    levelId,
    activeCourse,
    setActiveCourse,
    activeLevel,
    setActiveLevel,
    courses
  };
}
