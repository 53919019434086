import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import { loginOrgSchema } from '../components/Login/loginSchema';

export const useLoginOrgForm = () => {
  const formMethods = useForm({
    resolver: yupResolver(loginOrgSchema),
    defaultValues: {
      organization_slug: '',
      username: '',
      password: ''
    },
    mode: 'onChange'
  });

  return formMethods;
};
