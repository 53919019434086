import useCheckout from '../components/useCheckout';
import React, { useEffect } from 'react';
import { Container } from '@mui/material';
import { useParams } from 'react-router-dom';

export default function Checkout() {
  const { updateCheckout } = useCheckout();
  const { _ptxn } = useParams();
  useEffect(() => {
    if (_ptxn) {
      updateCheckout(_ptxn);
    }
  }, [_ptxn]);
  return <Container maxWidth="lg" sx={{ p: 2 }}></Container>;
}
