import { useMutation, useQuery, useQueryClient } from 'react-query';
import { PaymentService } from '../client';
import { useContext } from 'react';
import { UserContext } from '../pages/App';

export default function useUnassignedPurchase() {
  const queryClient = useQueryClient();
  const currentUser = useContext(UserContext);

  const { data: hasUnassignedPurchase, isLoading } = useQuery(
    [currentUser?.user_id, 'hasUnassignedPurchase'],
    () => PaymentService.hasUnassignedPurchasesApiPaymentHasUnassignedPurchasesGet(),
    {
      enabled: !!currentUser,
      keepPreviousData: true,
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      refetchInterval: false,
      refetchIntervalInBackground: false
    }
  );

  const assignPurchase = useMutation(
    (studentId: string) => PaymentService.assignPurchaseApiPaymentAssignPurchasePost(studentId),
    {
      onSuccess: () => {
        queryClient.invalidateQueries('hasUnassignedPurchase');
      }
    }
  );

  return { hasUnassignedPurchase, assignPurchase, isLoading };
}
