import { Box, Button, Container, Typography } from '@mui/material';
import { AddEditProfileDetails } from '../../components/Profile/AddEditProfileDetails';
import { useConfirm } from 'material-ui-confirm';
import { useMutation } from 'react-query';
import { PaymentService } from '../../client';
import { useSnackBarContext } from '../../context/SnackBarContext';
import { useContext } from 'react';
import { UserContext } from '../App';

export default function ProfileDetails() {
  const confirm = useConfirm();
  const currentUser = useContext(UserContext);

  const { createSnackBar } = useSnackBarContext();
  const upgradeToOrgMutation = useMutation(
    PaymentService.upgradeToOrganizationApiPaymentUpgradeToOrganizationGet,
    {
      onSuccess: () => {
        createSnackBar({
          content: 'Successfully upgraded to organization',
          severity: 'success',
          autoHide: true
        });
        // hard reload is needed to update the user context
        window.location.href = '/organization/details';
      },
      onError: () => {
        createSnackBar({
          content: 'Failed to upgrade to organization',
          severity: 'error',
          autoHide: true
        });
      }
    }
  );

  const handleUpgradeToOrganization = async () => {
    if (currentUser?.organization_id) return;
    if (currentUser?.is_student) return;

    try {
      await confirm({
        description:
          'Enjoy enhanced features and manage your students more effectively! Do you want to upgrade to organization?',
        title: 'Upgrade to organization',
        confirmationText: 'Yes',
        cancellationText: 'No'
      }).then(() => {
        upgradeToOrgMutation.mutateAsync();
      });
    } catch (error) {
      console.log('Cancelled');
    }
  };

  return (
    <Container maxWidth="lg" sx={{ mt: 3, mb: 14 }}>
      <Box component="div" display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" gutterBottom>
          Profile Details
        </Typography>
        {!currentUser?.organization_id && !currentUser?.is_student && (
          <Button variant="outlined" color="primary" onClick={handleUpgradeToOrganization}>
            Upgrade to organization
          </Button>
        )}
      </Box>

      <AddEditProfileDetails />
    </Container>
  );
}
