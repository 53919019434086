import { DialogActions } from '@mui/material';
import { useGenericModal } from '../context/GenericModalContext';
import ContentMarkdown from './GameUI/ContentMarkdown';
import Modal from './GameUI/Modal';
import PrimaryButton from './GameUI/PrimaryButton';

const GuideModal = () => {
  const { content, image, openModal: open, setOpenModal } = useGenericModal('guide');
  const onClose = () => setOpenModal(false);

  return (
    <Modal open={open} onClose={onClose}>
      <ContentMarkdown sx={{ padding: 2 }} content={content} image={image} />
      <DialogActions sx={{ justifyContent: 'center' }}>
        <PrimaryButton onClick={onClose}>Continue</PrimaryButton>
      </DialogActions>
    </Modal>
  );
};

export default GuideModal;
