import { Stack, Typography } from '@mui/material';
import promo from '../../assets/promo_hor_scale_complete.png';
import certificate from '../../assets/cfk-certificate.jpg';
import React from 'react';
import python from '../../assets/python-logo-white.png';

export default function WhatsIncluded() {
  return (
    <Stack>
      <Typography variant="h6" gutterBottom color="primary">
        What’s Included?
      </Typography>
      <img src={python} style={{ width: '100%', padding: '20px 40px' }} />

      <Typography variant="body1" gutterBottom>
        <strong>Comprehensive Python Modules</strong>
      </Typography>

      <Typography paragraph gutterBottom>
        Over 40 levels of rich, comprehensive chapters covering everything required to learn the
        Python Development language.
      </Typography>
      <img src={promo} alt="Promo" style={{ width: '100%', marginBottom: 20 }} />
      <Typography variant="body1" gutterBottom>
        <strong>Certificate of Completion</strong>
      </Typography>

      <Typography paragraph gutterBottom>
        Celebrate your student’s achievement with a downloadable certificate, showcasing their new
        coding skills.
      </Typography>
      <img src={certificate} alt="Certificate" style={{ width: '100%' }} />
    </Stack>
  );
}
