import Collider from '../@core/Collider';
import GameObject, { GameObjectProps } from '../@core/GameObject';
import Interactable from '../@core/Interactable';
import Moveable from '../@core/Moveable';
import Sprite from '../@core/Sprite';
import CameraFollowScript from '../components/CameraFollowScript';
import CharacterScript from '../components/CharacterScript';
import PlayerScript from '../components/PlayerScript';
import spriteData from '../spriteData';
import SpeechBubble from '../@core/SpeechBubble';

interface PlayerProps {
  gameObjectProps: GameObjectProps;
  character: string;
  mapBounds: { width: number; height: number };
}

export default function Player({ gameObjectProps, character, mapBounds }: PlayerProps) {
  return (
    <GameObject name="player" displayName="Player" layer="character" {...gameObjectProps}>
      <Moveable />
      <Interactable type="player" />
      <Collider />
      <SpeechBubble mapBounds={mapBounds} />
      <CharacterScript>
        <Sprite {...spriteData[character]} />
      </CharacterScript>
      <CameraFollowScript />
      <PlayerScript startingPosition={{ x: gameObjectProps.x || 0, y: gameObjectProps.y || 0 }} />
    </GameObject>
  );
}
