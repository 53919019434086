import React from 'react';
import { Modal, Box, Button, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useGenericModal } from '../../context/GenericModalContext';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 640,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2,
  outline: 'none'
};

export default function UpgradeToOrgModal() {
  const { openModal: open, setOpenModal, content } = useGenericModal('upgrade-to-org-modal');

  return (
    <Modal
      open={open}
      onClose={() => setOpenModal(false)}
      aria-labelledby="upgrade-to-org-modal"
      aria-describedby="modal-modal-description"
    >
      <Paper sx={style}>
        <Typography variant="h6" sx={{ textAlign: 'center', py: 2 }}>
          Upgrade to Organization Tier
        </Typography>
        <Typography variant="body1" sx={{ mt: 1 }}>
          {content}
        </Typography>
        <Box component="div" sx={{ display: 'flex', justifyContent: 'center', mt: 1, mb: 2 }}>
          <Button variant="contained" color="primary" sx={{ mt: 2 }} href="/schools">
            Upgrade to Organization
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
}
