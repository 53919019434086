import { useState } from 'react';
import { DialogActions, DialogContentText, TextField, Typography } from '@mui/material';
import { useGenericModal } from '../context/GenericModalContext';
import Modal from './GameUI/Modal';
import PrimaryButton from './GameUI/PrimaryButton';
import SecondaryButton from './GameUI/SecondaryButton';
import ContentWrapper from './GameUI/ContentWrapper';

interface SaveModalProps {
  code: string;
}

const SaveCodeModal: React.FC<SaveModalProps> = ({ code }) => {
  const { openModal: open, setOpenModal } = useGenericModal('saveCode');
  const [fileName, setFileName] = useState<string>('');
  const [fileNameError, setFileNameError] = useState<boolean>(false);

  const onClose = () => {
    setFileName('');
    setFileNameError(false);
    setOpenModal(false);
  };

  const handleSave = () => {
    setFileNameError(false);

    if (!fileName.trim()) {
      setFileNameError(true);
      return;
    }

    const blob = new Blob([code], { type: 'text/plain' });

    const url = URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;

    a.download = fileName.endsWith('.py') ? fileName : fileName + '.py';

    document.body.appendChild(a);
    a.click();

    URL.revokeObjectURL(url);
    document.body.removeChild(a);

    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <ContentWrapper sx={{ py: 2 }}>
        <Typography variant="h5">Save My Code</Typography>
        <DialogContentText color={'white'} sx={{ marginTop: 2 }}>
          <i>
            Let's save your hard work! Simply type in the box below a descriptive name for your
            code, and then press 'Save'.
          </i>
        </DialogContentText>
        <TextField
          id="outlined-basic"
          label="File Name"
          variant="outlined"
          value={fileName}
          onChange={(e) => {
            setFileName(e.target.value);
            setFileNameError(false);
          }}
          sx={{
            width: '50%',
            marginTop: 2
          }}
        />
        {fileNameError && (
          <Typography variant="body2" sx={{ color: 'red', marginTop: 2 }}>
            Please provide a name for the file.
          </Typography>
        )}
      </ContentWrapper>
      <DialogActions sx={{ justifyContent: 'center' }}>
        <SecondaryButton onClick={onClose} sx={{ width: '120px' }}>
          Cancel
        </SecondaryButton>
        <PrimaryButton onClick={handleSave} sx={{ width: '120px' }}>
          Save
        </PrimaryButton>
      </DialogActions>
    </Modal>
  );
};

export default SaveCodeModal;
