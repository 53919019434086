import { useQuery } from 'react-query';
import { PaymentService } from '../client';

export const useCurrentPlan = () => {
  const result = useQuery('currentPlan', PaymentService.currentPlanApiPaymentCurrentPlanGet, {
    refetchOnWindowFocus: false,
    retry: false,
    refetchInterval: false,
    refetchIntervalInBackground: false,
    refetchOnReconnect: false
  });
  return result;
};
