import { useMutation, useQuery, useQueryClient } from 'react-query';
import { type QuizAnswer, QuizService } from '../../client';
import { useParams } from 'react-router-dom';
import { useContext } from 'react';
import { UserContext } from '../App';
import { AxiosError } from 'axios';
import useLevels from '../../components/useLevels';

export default function useQuizData() {
  const currentUser = useContext(UserContext);
  const queryClient = useQueryClient();

  const { courseId, chapterId } = useParams();
  const { courseData } = useLevels();

  const { data: chapterQuestions, isLoading } = useQuery(
    ['questions', courseId, chapterId],
    () =>
      QuizService.getQuizQuestionsByChapterApiQuizQuestionsCourseIdChapterIdGet(
        courseId ?? '',
        chapterId ?? ''
      ),
    {
      enabled: !!courseId && !!chapterId
    }
  );

  const { data: answerData, isLoading: isAnswerLoading } = useQuery(
    ['answers', courseId],
    () =>
      QuizService.getQuizAnswersByStudentAndCourseApiQuizAnswersStudentIdCourseIdGet(
        currentUser?.student_id ?? '',
        courseId ?? ''
      ),
    {
      enabled: !!currentUser?.student_id && !!courseId
    }
  );

  const answerMutation = useMutation<QuizAnswer, AxiosError, QuizAnswer>(
    QuizService.upsertQuizAnswerApiQuizAnswerPost,
    {
      onSuccess: () => {
        queryClient.invalidateQueries(['answers', courseId]);
      }
    }
  );

  const currentChapter = courseData?.chapters.find((x) => x.id === chapterId);

  const chapterAnswers = answerData?.filter((x) => x.chapter_id == chapterId);

  const chapterComplete =
    chapterAnswers &&
    chapterQuestions &&
    chapterAnswers.length == chapterQuestions.questions.length;

  return {
    answerData,
    isAnswerLoading,
    answerMutation,
    chapterQuestions,
    chapterComplete,
    chapterAnswers,
    currentChapter
  };
}
