import React, { useState, useEffect, useCallback, useContext } from 'react';
import { Drawer, Box, Typography, Button, Divider, TextField, MenuItem } from '@mui/material';
import ConfigureCSVForm from './ConfigureCSVForm';
import DropzoneCSV from './DropzoneCSV';
import DataGridStudentsFromCSV from './DataGridStudentsFromCSV';
import { GridValidRowModel, GridRowSelectionModel, GridColDef } from '@mui/x-data-grid';
import { useSnackBarContext } from '../../../context/SnackBarContext';
import { ArrowForward } from '@mui/icons-material';
import { useConfigureCSVForm } from '../../../hooks/useConfigureCSVForm';
import { OrganizationService, Student } from '../../../client';
import { useMutation, useQueryClient } from 'react-query';
import { Controller } from 'react-hook-form';
import { UserContext } from '../../../pages/App';
import { USER_TYPES } from '../../SignUp/signupSchema';
import useOrganizationTeachers from '../../../hooks/useOrganizationTeachers';
import { useOrgClasses } from '../../../hooks/useOrgClasses';

interface ImportStudentsDrawerProps {
  isOpen: boolean;
  onClose: () => void;
  classId?: string;
}

const ImportStudentsDrawer: React.FC<ImportStudentsDrawerProps> = ({
  classId,
  isOpen,
  onClose
}) => {
  const queryClient = useQueryClient();
  const { createSnackBar } = useSnackBarContext();
  const currentUser = useContext(UserContext);
  const [csvData, setCsvData] = useState<GridValidRowModel[]>([]);
  const [isUploadComplete, setIsUploadComplete] = useState(false);
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);
  const [missingColumns, setMissingColumns] = useState<string[]>([]);
  const [conflictingStudents, setConflictingStudents] = useState<Student[]>([]);
  const [importedStudents, setImportedStudents] = useState<Student[]>([]);
  const { classes } = useOrgClasses();
  const formMethods = useConfigureCSVForm();
  const {
    watch,
    reset,
    control,
    formState: { isValid, errors }
  } = formMethods;
  const isFullNameSplit = watch('isFullNameSplit');
  const separator = watch('separator') || ',';
  const usernameField = watch('username') || 'username';
  const passwordField = watch('password') || 'password';
  const fullNameField = watch('full_name') || 'full_name';
  const firstNameField = watch('first_name') || 'first_name';
  const lastNameField = watch('last_name') || 'last_name';
  const emailField = watch('email');
  const userId = watch('user_id');

  const requiredColumns = [
    usernameField,
    passwordField,
    ...(isFullNameSplit ? [firstNameField, lastNameField] : [fullNameField])
  ];

  const [columns, setColumns] = useState<GridColDef[]>([]);

  const { teachers } = useOrganizationTeachers();

  const importMutation = useMutation(
    OrganizationService.bulkCreateStudentsApiOrganizationBulkCreateStudentsPost,
    {
      onSuccess: (data) => {
        const countCreated = data.created.length;
        setConflictingStudents(data.conflicts);
        setImportedStudents(data.created);
        const conflictingUsernames = data.conflicts;
        if (conflictingUsernames.length > 0 && conflictingUsernames.length < 5) {
          createSnackBar({
            content: `Successfully imported ${countCreated} students. Conflicting usernames: ${conflictingUsernames.join(', ')}`,
            autoHide: true,
            severity: 'info'
          });
        } else if (conflictingUsernames.length >= 5) {
          createSnackBar({
            content: `Successfully imported ${countCreated} students. Conflicting usernames: ${conflictingUsernames.length}`,
            autoHide: true,
            severity: 'info'
          });
        } else {
          createSnackBar({
            content: `Successfully imported ${countCreated} students`,
            autoHide: true,
            severity: 'success'
          });
          onClose();
        }
      },
      onError: () => {
        createSnackBar({
          content: 'Failed to import students',
          autoHide: true,
          severity: 'error'
        });
      }
    }
  );

  useEffect(() => {
    if (!isOpen) {
      setCsvData([]);
      setIsUploadComplete(false);
      reset();
    }
  }, [isOpen, reset]);

  const handleFileUpload = (
    csvData: GridValidRowModel[],
    columns: GridColDef[],
    missingColumns: string[]
  ) => {
    setCsvData(csvData);
    setColumns(columns);
    setMissingColumns(missingColumns);
    setIsUploadComplete(true);
    setSelectedRows(csvData.map((row) => row.id));
  };

  const handleImport = async () => {
    const selectedData = [...csvData.filter((row) => selectedRows.includes(row.id))];
    // skip row ids
    selectedData.forEach((row) => {
      delete row.id;
    });
    console.log('Importing data:', selectedData);
    await importMutation.mutateAsync({
      students: selectedData as Student[],
      user_id: userId || teachers?.[0].id,
      class_id: classId
    });
    queryClient.invalidateQueries([currentUser?.user_id, 'org-students']);
  };

  const handleProcessRowUpdate = (newRow: GridValidRowModel) => {
    setCsvData((prev) => prev.map((row) => (row.id === newRow.id ? newRow : row)));
    return newRow;
  };

  const handleRowSelection = useCallback((selectionModel: GridRowSelectionModel) => {
    setSelectedRows(selectionModel);
  }, []);

  if (!currentUser || !currentUser.user_type) {
    return null;
  }

  return (
    <Drawer anchor="right" open={isOpen} onClose={onClose}>
      <Box
        component="div"
        sx={{
          width: 640,
          maxWidth: '100vw',
          display: 'flex',
          flexDirection: 'column',
          height: '100%'
        }}
      >
        <Box component="div" sx={{ flex: '1 1 auto', px: 2, pt: 10 }}>
          <Typography variant="h5" gutterBottom>
            Import CSV for Adding Students
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <ConfigureCSVForm formMethods={formMethods} />
          <DropzoneCSV
            onFileUpload={handleFileUpload}
            isDisabled={!isValid}
            requiredColumns={requiredColumns}
            emailField={emailField}
            isFullNameSplit={isFullNameSplit}
            firstNameField={firstNameField}
            lastNameField={lastNameField}
            fullNameField={fullNameField}
            usernameField={usernameField}
            passwordField={passwordField}
            separator={separator}
          />
          {[USER_TYPES.admin, USER_TYPES.teacher].includes(currentUser.user_type) && (
            <>
              <Typography variant="h6" gutterBottom>
                Class
              </Typography>
              <Typography variant="body2" gutterBottom>
                Assign the students to a class.
              </Typography>

              <Controller
                name="class_id"
                control={control}
                defaultValue={classId}
                disabled={!!classId}
                render={({ field }) => (
                  <TextField
                    {...field}
                    select
                    margin="normal"
                    fullWidth
                    label="Class"
                    type="text"
                    error={!!errors.class_id}
                    helperText={errors.class_id?.message}
                    required
                  >
                    {classes?.map((classData) => (
                      <MenuItem key="teacher-id" value={classData.id}>
                        {classData.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
            </>
          )}
          <Typography variant="h6" gutterBottom>
            Preview
          </Typography>
          <Typography variant="body2" gutterBottom>
            {isUploadComplete
              ? 'You can select which students to import and edit the fields directly in the table below.'
              : 'Upload a CSV file to preview the data.'}
          </Typography>
          {isUploadComplete && (
            <DataGridStudentsFromCSV
              data={csvData}
              columns={columns}
              onProcessRowUpdate={handleProcessRowUpdate}
              onRowSelectionModelChange={handleRowSelection}
              rowSelectionModel={selectedRows}
            />
          )}
          {importedStudents.length > 0 && (
            <Typography color="success" variant="body2">
              {`Successfully imported ${importedStudents.length} students`}
            </Typography>
          )}
          {conflictingStudents.length > 0 && (
            <Typography color="info" variant="body2">
              {`The following students already exist in the organization: ${conflictingStudents.join(
                ', '
              )}`}
            </Typography>
          )}
        </Box>
        <Box component="div" display="flex" justifyContent="space-between" gap={3} sx={{ p: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleImport}
            disabled={csvData.length === 0 || importMutation.isLoading}
          >
            {importMutation.isLoading ? `Importing...` : `Import`}
          </Button>
          <Button variant="outlined" startIcon={<ArrowForward />} color="primary" onClick={onClose}>
            Close
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
};

export default ImportStudentsDrawer;
