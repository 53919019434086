import React from 'react';
import { Item, LevelData } from '../client';
import spriteData from '../spriteData';
import ItemCollect from '../entities/ItemCollect';
import ItemFinish from '../entities/ItemFinish';
import ItemPush from '../entities/ItemPush';
import ItemSpeak from '../entities/ItemSpeak';
import ItemBuild from '../entities/ItemBuild';
import ItemWater from '../entities/ItemWater';
import ItemCollectWater from '../entities/ItemCollectWater';
import ItemOpen from '../entities/ItemOpen';
import ItemClose from '../entities/ItemClose';
import ItemPlace from '../entities/ItemPlace';
import Chest from '../entities/Chest';
import Door from '../entities/Door';
import Gate from '../entities/Gate';
import Tray from '../entities/Tray';
import ItemCombine from '../entities/ItemCombine';
import ItemCollectObject from '../entities/ItemCollectObject';
import ItemPlant from '../entities/ItemPlant';
import TrayFood from '../entities/TrayFood';

interface ItemsLayerProps {
  levelData: LevelData;
}
export default function ItemsLayer({ levelData }: ItemsLayerProps) {
  if (!levelData) return null;

  const { items, levelGoals } = levelData;

  const renderCollectItem = (item: Item) => {
    const { type, sprite, position } = item;
    const levelGoal = levelGoals.find(
      (goal) => goal.action === 'collect' && goal.type === type && goal.data
    );

    if (!levelGoal?.data)
      return (
        <ItemCollect
          props={{ x: position[0], y: position[1] }}
          state={type}
          spriteData={spriteData[sprite]}
          itemName={type}
        />
      );
    switch (levelGoal?.data) {
      case 'water':
        return (
          <ItemCollectWater
            props={{ x: position[0], y: position[1] }}
            state={type}
            spriteData={spriteData[sprite]}
            itemName={type}
          />
        );
      default:
        return (
          <ItemCollectObject
            props={{ x: position[0], y: position[1] }}
            state={type}
            spriteData={spriteData[sprite]}
            itemName={type}
          />
        );
    }
  };

  const renderItem = (item: Item) => {
    const { action, type, sprite, position, compound } = item;
    switch (action) {
      case 'collect':
        return renderCollectItem(item);
      case 'finish':
        return (
          <ItemFinish
            props={{ x: position[0], y: position[1] }}
            state={type}
            spriteData={spriteData[sprite]}
            itemName={type}
          />
        );
      case 'push':
        return (
          <ItemPush
            props={{ x: position[0], y: position[1] }}
            state={type}
            spriteData={spriteData[sprite]}
            itemName={type}
          />
        );
      case 'speak':
        return (
          <ItemSpeak
            props={{ x: position[0], y: position[1] }}
            state={type}
            spriteData={spriteData[sprite]}
            itemName={type}
          />
        );
      case 'build':
        return (
          <ItemBuild
            props={{ x: position[0], y: position[1] }}
            state={type}
            spriteData={spriteData[sprite]}
            itemName={type}
          />
        );
      case 'water':
        return (
          <ItemWater
            props={{ x: position[0], y: position[1] }}
            state={type}
            spriteData={spriteData[sprite]}
            itemName={type}
          />
        );
      case 'open':
        return (
          <ItemOpen
            props={{ x: position[0], y: position[1] }}
            state={type}
            spriteData={spriteData[sprite]}
            itemName={type}
          />
        );
      case 'close':
        return (
          <ItemClose
            props={{ x: position[0], y: position[1] }}
            state={type}
            spriteData={spriteData[sprite]}
            itemName={type}
          />
        );
      case 'place':
        return (
          <ItemPlace
            props={{ x: position[0], y: position[1] }}
            state={type}
            spriteData={spriteData[sprite]}
            itemName={type}
          />
        );
      case 'open-close-chest':
        return (
          <Chest
            props={{ x: position[0], y: position[1] }}
            state={type}
            spriteData={spriteData[sprite]}
            itemName={type}
            compound={compound}
          />
        );
      case 'open-close-door':
        return (
          <Door
            props={{ x: position[0], y: position[1] }}
            state={type}
            spriteData={spriteData[sprite]}
            itemName={type}
          />
        );
      case 'open-close-gate':
        return (
          <Gate
            props={{ x: position[0], y: position[1] }}
            state={type}
            spriteData={spriteData[sprite]}
            itemName={type}
            compound={compound}
          />
        );
      case 'water-tray':
        return (
          <Tray
            props={{ x: position[0], y: position[1] }}
            state={type}
            spriteData={spriteData[sprite]}
            itemName={type}
            compound={compound}
          />
        );
      case 'food-tray':
        return (
          <TrayFood
            props={{ x: position[0], y: position[1] }}
            state={type}
            spriteData={spriteData[sprite]}
            itemName={type}
            compound={compound}
          />
        );
      case 'combine':
        return (
          <ItemCombine
            props={{ x: position[0], y: position[1] }}
            state={type}
            spriteData={spriteData[sprite]}
            itemName={type}
          />
        );
      case 'plant':
        return (
          <ItemPlant
            props={{ x: position[0], y: position[1] }}
            state={type}
            spriteData={spriteData[sprite]}
            itemName={type}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      {items?.map((item, index) => {
        const { action, type } = item;
        const key = `${type}-${index}-${action}`;
        return <React.Fragment key={key}>{renderItem(item)}</React.Fragment>;
      })}
    </>
  );
}
