import { useState, useRef, useLayoutEffect } from 'react';
import HtmlOverlay from './HtmlOverlay';
import { SpeakingEvent } from './Moveable';
import useGameObjectEvent from './useGameObjectEvent';
import waitForMs from './utils/waitForMs';
import { Primitive } from './utils/commandHelper';
import { CSSProperties } from 'react';
import useGame from './useGame';

interface SpeechBubbleProps {
  mapBounds: { width: number; height: number };
}

export default function SpeechBubble({ mapBounds }: SpeechBubbleProps) {
  const [speakingPhrase, setSpeakingPhrase] = useState<Primitive | null>(null);
  const bubbleRef = useRef<HTMLDivElement>(null);
  const { findGameObjectByName } = useGame();

  useGameObjectEvent<SpeakingEvent>('speaking', ({ phrase, isSpeaking }) => {
    if (!phrase) return;
    waitForMs(0).then(() => {
      setSpeakingPhrase(phrase);
    });
    waitForMs(2500).then(() => {
      setSpeakingPhrase(null);
      isSpeaking.current = false;
    });
  });

  const bubbleStyle: CSSProperties = {
    position: 'absolute',
    zIndex: 5,
    fontFamily: 'Urbanist, sans-serif',
    fontSize: '18px',
    fontWeight: 600,
    backgroundColor: 'rgba(255,255,255,0.9)',
    padding: '10px',
    color: 'black',
    borderRadius: '10px',
    minWidth: '50px',
    maxWidth: '200px',
    width: 'max-content',
    wordWrap: 'break-word',
    wordBreak: 'keep-all',
    whiteSpace: 'normal',
    textAlign: 'center'
  };

  const calculatePosition = () => {
    setTimeout(() => {
      if (bubbleRef.current) {
        const playerObject = findGameObjectByName('player');
        const playerX = playerObject.transform.x;
        const playerY = playerObject.transform.y;
        const bubbleElement = bubbleRef.current;
        let right;
        let left;

        if (playerX <= 2) {
          left = 0;
          bubbleElement.style.left = `${left}px`;
        } else if (playerX >= mapBounds.width - 2) {
          right = -30;
          bubbleElement.style.right = `${right}px`;
        } else {
          bubbleElement.style.left = '25px';
          bubbleElement.style.transform = 'translateX(-50%)';
        }
        if (playerY >= mapBounds.height - 2) {
          bubbleElement.style.top = '-50px';
        } else {
          bubbleElement.style.bottom = '5px';
        }
      }
    }, 0);
  };

  useLayoutEffect(() => {
    if (speakingPhrase) {
      calculatePosition();
    }
  }, [speakingPhrase]);

  return (
    <>
      {speakingPhrase && (
        <HtmlOverlay>
          <div id="speech-bubble" ref={bubbleRef} style={bubbleStyle}>
            {speakingPhrase}
          </div>
        </HtmlOverlay>
      )}
    </>
  );
}
