import { useEffect, useMemo, useCallback } from 'react';
import { useThree } from '@react-three/fiber';
import * as THREE from 'three';

THREE.Cache.enabled = true;

export interface SoundProps {
  src: string | string[];
  volume?: number;
}

const pickSrc = (src: string) => {
  const sources = Array.isArray(src) ? src : [src];
  return sources[Math.floor(sources.length * Math.random())];
};

export function useSound({ src, volume = 1 }: SoundProps) {
  const source = useMemo(() => pickSrc(src as string), [src]);

  const { camera } = useThree();

  const play = useCallback(() => {
    const listener = new THREE.AudioListener();
    camera.add(listener);

    const sound = new THREE.Audio(listener);

    const audioLoader = new THREE.AudioLoader();
    audioLoader.load(source, function (buffer) {
      sound.setBuffer(buffer);
      sound.setLoop(false);
      sound.setVolume(volume);
      sound.play();
    });
  }, [source, camera]);

  return play;
}

export default function Sound(props: SoundProps) {
  const { camera } = useThree();
  const listener = new THREE.AudioListener();
  camera.add(listener);

  useEffect(() => {
    const sound = new THREE.Audio(listener);

    const audioLoader = new THREE.AudioLoader();
    audioLoader.load(props.src as string, function (buffer) {
      sound.setBuffer(buffer);
      sound.setLoop(false);
      sound.setVolume(0.5);
      sound.play();
    });
  }, []);

  return null;
}
