import * as yup from 'yup';

export const configureCSVSchema = yup.object().shape({
  username: yup.string().required('Username column name is required'),
  password: yup.string().required('Password column name is required'),
  email: yup.string(),
  full_name: yup.string().when('isFullNameSplit', {
    is: false,
    then: (schema) => schema.required('Full name column name is required'),
    otherwise: (schema) => schema.notRequired()
  }),
  first_name: yup.string().when('isFullNameSplit', {
    is: true,
    then: (schema) => schema.required('First name column name is required'),
    otherwise: (schema) => schema.notRequired()
  }),
  last_name: yup.string().when('isFullNameSplit', {
    is: true,
    then: (schema) => schema.required('Last name column name is required'),
    otherwise: (schema) => schema.notRequired()
  }),
  separator: yup.string().required('Separator is required'),
  isFullNameSplit: yup.boolean().required(),
  user_id: yup.string(),
  class_id: yup.string().required('Class is required')
});
