import { useRef } from 'react';
import useComponentRegistry, { ComponentRef } from './useComponentRegistry';
import useGame from './useGame';
import useGameObject from './useGameObject';
import { GameObjectRef, Position } from './GameObject';
import { PubSubEvent } from './utils/createPubSub';
import { Path, useOutput } from '../context/OutputContext';
import { UNEXPECTED_ERRORS } from './utils/gameErrors';
import { useCommandError } from '../hooks/useCommandError';
import { XMARK_LIST } from './utils/xmarkList';

export interface InteractablePath {
  position: Position;
  step: Path;
  command: string;
}
export interface InteractableXmarkPath {
  position: Position;
  step: Path;
  command: string;
}
export interface InteractableObject {
  position?: Position;
  obj: GameObjectRef;
  step: Path;
  command: string;
}
export type WillInteractEvent = PubSubEvent<'will-interact', InteractablePath>;
export type InteractionEvent = PubSubEvent<'interaction', InteractableObject>;
export type DidInteractEvent = PubSubEvent<'did-interact', InteractablePath>;

export type InteractionCallback = ({ obj, step }: InteractableObject) => Promise<any> | void;

export type InteractableRef = ComponentRef<
  'Interactable',
  {
    interact: ({ position, step }: InteractablePath) => Promise<boolean>;
    interactOnXMark: ({ position, step, command }: InteractableXmarkPath) => Promise<boolean>;
    onInteract: (ref: GameObjectRef, step: Path, command: string) => Promise<void>;
    canInteract: () => boolean;
    canReceiveInteraction: () => boolean;
    getInteractionType: () => string;
  }
>;

export default function Interactable({ type: initialType = 'default' }) {
  const { resetCommandsQueue } = useOutput();
  const { findGameObjectsByXY } = useGame();
  const { getRef, publish, hasSubscriptions } = useGameObject();
  const canInteract = useRef(true);
  const interactionType = useRef(initialType);
  const { triggerError } = useCommandError();

  useComponentRegistry<InteractableRef>('Interactable', {
    // this is executed on the game object that *initiates* an interaction
    async interact({ position, step, command }) {
      const interactable = findGameObjectsByXY(position.x, position.y)
        .map((obj) => obj.getComponent<InteractableRef>('Interactable'))
        .find(
          (component) =>
            component?.canReceiveInteraction() && component.getInteractionType() === command
        );

      if (!interactable) return false;

      publish<WillInteractEvent>('will-interact', { position, step, command });
      canInteract.current = false;
      await interactable.onInteract(getRef(), step, command);

      canInteract.current = true;
      publish<DidInteractEvent>('did-interact', { position, step, command });
      return true;
    },
    async interactOnXMark({ position, step, command }) {
      const actionObject = findGameObjectsByXY(position.x, position.y).find((obj) => {
        const name = obj.name;
        const actionInteractable = obj?.getComponent<InteractableRef>('Interactable');

        return (
          XMARK_LIST.some((xmark) => name?.includes(xmark)) &&
          actionInteractable?.getInteractionType() === command
        );
      });

      if (!actionObject) {
        triggerError(UNEXPECTED_ERRORS.xmark.position(command));
        return false;
      }
      const interactable = findGameObjectsByXY(position.x, position.y)
        .map((obj) => obj.getComponent<InteractableRef>('Interactable'))
        .find(
          (component) =>
            component?.canReceiveInteraction() && component.getInteractionType() === command
        );
      if (!interactable) return false;

      publish<WillInteractEvent>('will-interact', { position, step, command });
      canInteract.current = false;
      await interactable.onInteract(getRef(), step, command);
      canInteract.current = true;
      publish<DidInteractEvent>('did-interact', { position, step, command });
      return true;
    },
    // this is executed on the game object that *receives* an interaction
    async onInteract(gameObject, step, command) {
      if (canInteract.current) {
        canInteract.current = false;
        publish<WillInteractEvent>('will-interact', {
          position: gameObject.transform,
          step,
          command
        });
        await publish<InteractionEvent>('interaction', { obj: gameObject, step, command });
        publish<DidInteractEvent>('did-interact', {
          position: gameObject.transform,
          step,
          command
        });
        canInteract.current = true;
      }
    },
    canInteract() {
      return canInteract.current;
    },
    canReceiveInteraction() {
      return canInteract.current && hasSubscriptions<InteractionEvent>('interaction') > 0;
    },
    getInteractionType() {
      return interactionType.current;
    }
  });

  return null;
}
