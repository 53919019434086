import React, { Component } from 'react';
import { Route, Navigate, useNavigate, useLocation, Outlet } from 'react-router-dom';
import { useQuery, useQueryClient } from 'react-query';
import { AuthService } from '../client';

export default function ProtectedRoute({ redirectPath = '/login' }) {
  const queryClient = useQueryClient();

  const { data: currentUser, isLoading } = useQuery('currentUser', AuthService.currentUserApiAuthCurrentGet);

  const isAuthenticated = !!currentUser?.email;

  if (isLoading) {
    return null;
  }
  if (!isAuthenticated) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
}
