import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { feedbackSchema } from '../components/Feedback/FeedbackSchema';
import { useContext } from 'react';
import { UserContext } from '../pages/App';

export const useFeedbackForm = () => {
  const formMethods = useForm({
    resolver: yupResolver(feedbackSchema),
    mode: 'onChange',
    defaultValues: {
      rating: 5,
      feedback: ''
    }
  });
  return formMethods;
};
