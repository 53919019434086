import { Box, Container, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { AddEditOrgStudentForm } from '../../../components/Organization/Student/AddEditOrgStudentForm';

export default function AddEditStudentUser() {
  const { studentId } = useParams();
  return (
    <Container maxWidth="lg" sx={{ mt: 3, mb: 14 }}>
      <Box component="div" display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" gutterBottom>
          {studentId ? 'Edit Student' : 'Add Student'}
        </Typography>
      </Box>
      <AddEditOrgStudentForm />
    </Container>
  );
}
