import {
  Box,
  Button,
  Paper,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Chip
} from '@mui/material';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { PaymentService } from '../../client';
import { Loader } from '../Loader';
import useCheckout, { PADDLE_SUBSCRIPTION_PRICE_ID } from '../useCheckout';
import { useConfirm } from 'material-ui-confirm';
import { useSnackBarContext } from '../../context/SnackBarContext';
import { useStudentsByPurchase } from '../../hooks/useStudentsByPurchase';
import { UserContext } from '../../pages/App';
import { useContext } from 'react';

export default function BillingDetails() {
  const queryClient = useQueryClient();
  const { openCheckout } = useCheckout();
  const { data: students, isLoading: isStudentsLoading } = useStudentsByPurchase();

  const currentUser = useContext(UserContext);

  const { data: currentSubscription, isLoading } = useQuery(
    'current-subscription',
    PaymentService.currentSubscriptionApiPaymentCurrentSubscriptionGet
  );

  const confirm = useConfirm();
  const { createSnackBar } = useSnackBarContext();

  const cancelSubscriptionMutation = useMutation(
    PaymentService.cancelSubscriptionApiPaymentCancelSubscriptionPost,
    {
      onSuccess: () => {
        queryClient.invalidateQueries('current-subscription');
        createSnackBar({
          content: 'Subscription cancelled successfully',
          severity: 'success',
          autoHide: true
        });
      }
    }
  );

  if (isLoading)
    return (
      <Paper sx={{ p: 5, mt: 4 }}>
        <Loader />
      </Paper>
    );

  const handleCancelSubscription = () => {
    confirm({
      description: `Are you sure you want to cancel your subscription?`,
      title: 'Cancel subscription',
      confirmationText: 'Yes',
      cancellationText: 'No'
    })
      .then(() => {
        try {
          cancelSubscriptionMutation.mutate();
        } catch (error) {
          createSnackBar({
            content: 'Failed to cancel subscription',
            severity: 'error',
            autoHide: true
          });
        }
      })
      .catch(() => {
        console.log('cancelled');
      });
  };

  return (
    <>
      <Paper sx={{ pb: 5, px: 5, mt: 4, pt: 2 }}>
        <Typography variant="h6">Subscriptions</Typography>
        {!currentSubscription ? (
          <Box component="div" sx={{ mt: 2 }}>
            <Typography variant="body2" gutterBottom>
              You are not subscribed to any plan.
            </Typography>
            <Button
              variant="contained"
              color="primary"
              onClick={() => openCheckout(PADDLE_SUBSCRIPTION_PRICE_ID)}
              sx={{ mt: 2 }}
            >
              Subscribe
            </Button>
          </Box>
        ) : currentSubscription.scheduled_cancellation ? (
          <Box component="div" sx={{ mt: 2 }}>
            <Typography variant="body2" gutterBottom>
              Your subscription is scheduled to be cancelled{' '}
              {new Date(currentSubscription.scheduled_cancellation).toLocaleDateString()}
            </Typography>
          </Box>
        ) : (
          <Box component="div" sx={{ mt: 2 }}>
            <Typography variant="body2" gutterBottom>
              Your subscription is active started at{' '}
              {new Date(currentSubscription.purchased_at).toLocaleDateString()}
            </Typography>
            <Button
              variant="outlined"
              color="error"
              sx={{ mt: 2 }}
              onClick={handleCancelSubscription}
            >
              Cancel Subscription
            </Button>
          </Box>
        )}
      </Paper>
      {!currentUser?.organization_id &&
        (!students || isStudentsLoading ? (
          <Loader />
        ) : (
          <Paper sx={{ pb: 5, px: 5, mt: 4 }}>
            <Box component="div" sx={{ mt: 2 }}>
              <Typography variant="h6" sx={{ py: 2 }}>
                Purchased Courses
              </Typography>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Full Name</TableCell>
                      <TableCell>Username</TableCell>
                      <TableCell>Courses</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {students.map(({ student, courses }) => (
                      <TableRow key={student.id}>
                        <TableCell>{student.full_name || 'N/A'}</TableCell>
                        <TableCell>{student.username}</TableCell>
                        <TableCell>
                          {courses.map((course) => (
                            <Chip key={course} label={course} sx={{ marginRight: 1 }} />
                          ))}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Paper>
        ))}
    </>
  );
}
