import { useGenericModal } from '../context/GenericModalContext';
import { useOutput } from '../context/OutputContext';

export const useCommandError = () => {
  const { resetCommandsQueue } = useOutput();
  const { setContent: setError, setOpenModal: setOpenErrorModal } = useGenericModal('error');

  const triggerError = async (error: string) => {
    resetCommandsQueue();
    setError(error || 'Unexpected game error');
    setOpenErrorModal(true);
  };

  return { triggerError };
};
