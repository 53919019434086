import { useQuery, useQueryClient } from 'react-query';
import { AuthService } from '../client';
import { ErrorResponse, useNavigate } from 'react-router-dom';
import { AxiosError } from 'axios';

export const useCurrentUser = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();

  const { data: currentUser, isLoading: isCurrentUserLoading } = useQuery(
    'currentUser',
    AuthService.currentUserApiAuthCurrentGet,
    {
      retry: false,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      onSuccess: async (fetchedUser) => {
        queryClient.invalidateQueries('courseData');
        queryClient.invalidateQueries(
          `courseData-${fetchedUser?.user_id}-${fetchedUser?.student_id}`
        );
      },
      onError: async (error: AxiosError<ErrorResponse>) => {
        if (error.response && error.response.status === 401) {
          // User is not authenticated
          navigate('/login');
          return;
        }
      }
    }
  );

  return { currentUser, isCurrentUserLoading };
};
