import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Container,
  Divider,
  Grid,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  PaperProps,
  Stack,
  Typography
} from '@mui/material';

import certificate from '../assets/cfk-certificate.jpg';
import codingforkids from '../assets/codingforkids-cat.png';
import python from '../assets/python-logo-white.png';
import useCheckout, {
  PADDLE_LARGE_ORG_PRICE_ID,
  PADDLE_SMALL_ORG_PRICE_ID
} from '../components/useCheckout';
import {
  AdminPanelSettings,
  Check,
  ColorLens,
  Construction,
  Mail,
  School,
  SentimentSatisfiedAlt,
  SportsEsports,
  SupervisorAccount,
  TrendingUp,
  Verified
} from '@mui/icons-material';
import React, { useContext } from 'react';
import WhatsIncluded from '../components/Purchase/WhatsIncluded';
import { useCurrentPlan } from '../hooks/useCurrentPlan';
import { useUpgradePlan } from '../hooks/useUpgradePlan';
import { useConfirm } from 'material-ui-confirm';
import UpgradePrice from '../components/Purchase/UpgradePrice';
import { UserContext } from './App';
import { useUpgradeToOrg } from '../hooks/useUpgradeToOrg';

const PricingBox: React.FC<PaperProps> = ({ children, ...props }) => {
  return (
    <Paper
      elevation={3}
      sx={{
        padding: 2,
        margin: 3,
        gap: 3,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}
      {...props}
    >
      {children}
    </Paper>
  );
};

export default function InstitutionalPurchase() {
  const { openCheckout } = useCheckout();
  const confirm = useConfirm();
  const currentUser = useContext(UserContext);
  const upgradePlanMutation = useUpgradePlan();
  const upgradeToOrgMutation = useUpgradeToOrg();

  const { data: currentPlan, isLoading: isPlanLoading } = useCurrentPlan();

  const handleSubscribe = (subscription: string) => {
    if (!currentUser?.organization_id) {
      confirm({
        title: 'Upgrade to Organization',
        description: 'You need to upgrade to an organization to subscribe to this plan.',
        confirmationText: 'Yes, Upgrade to Organization',
        cancellationText: 'Cancel'
      })
        .then(() => {
          upgradeToOrgMutation.mutateAsync();
        })
        .catch(() => {
          // Do nothing
        });

      return;
    }
    if (currentPlan.subscription) {
      confirm({
        title: 'Upgrade Plan',
        description: 'Are you sure you want to upgrade your plan?',
        confirmationText: 'Yes, Upgrade Plan',
        cancellationText: 'Cancel'
      })
        .then(() => {
          upgradePlanMutation.mutateAsync(subscription);
        })
        .catch(() => {
          // Do nothing
        });

      return;
    }

    if (subscription === 'small_organization') {
      openCheckout(PADDLE_SMALL_ORG_PRICE_ID);
    } else if (subscription === 'large_organization') {
      openCheckout(PADDLE_LARGE_ORG_PRICE_ID);
    }
  };

  return (
    <Container maxWidth="lg" sx={{ p: 2 }}>
      <div style={{ display: 'flex', gap: 4, alignItems: 'center' }}>
        <Typography variant="h4" gutterBottom align="center" sx={{ paddingY: '40px' }}>
          Empower Your Institution with Coding for Kids!
        </Typography>
      </div>

      <Grid container>
        <Grid item xs={12} md={8}>
          <Typography variant="h6" gutterBottom color="primary">
            Join the Revolution in Education!
          </Typography>
          <Typography paragraph>
            Bring the exciting world of coding to your institution with Coding for Kids! Designed to
            engage and educate, this course offers an interactive and fun way for students to learn
            coding with Python, a leading programming language, and great for beginners.
          </Typography>

          <Typography variant="h6" gutterBottom color="primary">
            Why Choose Python for Your Institution?
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <List>
            <ListItem>
              <ListItemIcon>
                <SentimentSatisfiedAlt fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary="Accessible Learning"
                secondary="Python’s simple syntax makes coding accessible and enjoyable for students."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <Construction fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary="Practical Skills"
                secondary="Python is used in various professional fields, providing real-world skills."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <ColorLens fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary="Encourages Creativity"
                secondary="Coding fosters creativity and critical thinking in students."
              />
            </ListItem>
          </List>

          <Typography variant="h6" gutterBottom color="primary">
            Institutions Tier Features:
          </Typography>
          <Divider sx={{ mb: 2 }} />
          <List>
            <ListItem>
              <ListItemIcon>
                <SportsEsports fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary="Interactive Learning"
                secondary="Engaging games and challenges make learning Python fun."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <TrendingUp fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary="Self-Paced Modules"
                secondary="Students can learn at their own pace, making it suitable for various age groups."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <SupervisorAccount fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary="Track Progress"
                secondary="Teachers can monitor and celebrate student progress."
              />
            </ListItem>
            <ListItem>
              <ListItemIcon>
                <AdminPanelSettings fontSize="large" />
              </ListItemIcon>
              <ListItemText
                primary="Teacher Administration"
                secondary="Administrators can manage and track teachers and their students."
              />
            </ListItem>
          </List>
        </Grid>
        <Grid item xs={12} md={4}>
          <WhatsIncluded />
        </Grid>
      </Grid>

      <Typography variant="h4" align="center" sx={{ my: 5 }} id="prices">
        Organizational Plans
      </Typography>
      <Container maxWidth="md">
        <Stack sx={{ textAlign: 'center', alignItems: 'center', my: 2 }}>
          <Typography variant="body1" align="center" id="prices" gutterBottom>
            Have any questions about the organizational tiers? Email us!
          </Typography>
          <Button
            variant="contained"
            color="primary"
            size="large"
            startIcon={<Mail />}
            component={Link}
            href="mailto:support@codingforkids.io"
            target="_blank"
          >
            Contact Us
          </Button>
        </Stack>
      </Container>
      <Container maxWidth="md">
        <Grid container>
          <Grid item xs={12} md={6}>
            <PricingBox>
              <Typography variant="h4" align="center">
                Small Organization
              </Typography>
              <Typography align="center" color="text.secondary">
                Perfect for small institutions.
              </Typography>
              <Typography variant="h4" align="center" color="primary">
                US $49
                <Box component="span" sx={{ fontSize: '0.75em', color: 'text.primary' }}>
                  /month
                </Box>
              </Typography>
              <Stack justifyContent="center">
                {isPlanLoading ? (
                  <Box
                    component="div"
                    sx={{ display: 'flex', gap: 1, flexDirection: 'row', alignItems: 'center' }}
                  >
                    <CircularProgress size={32} />
                  </Box>
                ) : currentPlan?.subscription === 'small_organization' ? (
                  <Chip icon={<Check />} label="Subscribed" color="primary" variant="outlined" />
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    size="large"
                    disabled={
                      currentPlan?.subscription === 'large_organization' ||
                      upgradePlanMutation.isLoading
                    }
                    onClick={() => handleSubscribe('small_organization')}
                  >
                    {!currentPlan?.subscription || currentPlan?.subscription !== 'parent'
                      ? 'Subscribe'
                      : 'Upgrade'}
                  </Button>
                )}
                {currentPlan?.subscription === 'parent' ? (
                  <UpgradePrice planName="small_organization" />
                ) : null}
              </Stack>
              <Divider />
              <List sx={{ width: '100%' }}>
                <ListItem>
                  <ListItemIcon>
                    <Verified color="primary" />
                  </ListItemIcon>
                  <ListItemText>Up to 200 students</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Verified color="primary" />
                  </ListItemIcon>
                  <ListItemText>Full access to over 50 levels</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Verified color="primary" />
                  </ListItemIcon>
                  <ListItemText>Provide course certificates</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Verified color="primary" />
                  </ListItemIcon>
                  <ListItemText>Unlimited time access</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Verified color="primary" />
                  </ListItemIcon>
                  <ListItemText>Teacher administration</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Verified color="primary" />
                  </ListItemIcon>
                  <ListItemText>Student administration</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Verified color="primary" />
                  </ListItemIcon>
                  <ListItemText>Course quizzes</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Verified color="disabled" />
                  </ListItemIcon>
                  <ListItemText sx={{ textDecoration: 'line-through', color: 'text.secondary' }}>
                    Enterprise Support
                  </ListItemText>
                </ListItem>
              </List>
            </PricingBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <PricingBox>
              <Typography variant="h4" align="center">
                Large Organization
              </Typography>
              <Typography align="center" color="text.secondary">
                Ideal for large institutions of all sizes.
              </Typography>
              <Typography variant="h4" align="center" color="primary">
                US $149
                <Box component="span" sx={{ fontSize: '0.75em', color: 'text.primary' }}>
                  /month
                </Box>
              </Typography>
              <Stack justifyContent="center">
                {isPlanLoading ? (
                  <Box
                    component="div"
                    sx={{ display: 'flex', gap: 1, flexDirection: 'row', alignItems: 'center' }}
                  >
                    <CircularProgress size={32} />
                  </Box>
                ) : currentPlan?.subscription === 'large_organization' ? (
                  <Chip icon={<Check />} label="Subscribed" color="primary" variant="outlined" />
                ) : (
                  <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    disabled={upgradePlanMutation.isLoading}
                    onClick={() => handleSubscribe('large_organization')}
                  >
                    {!['parent', 'small_organization'].includes(currentPlan?.subscription)
                      ? 'Subscribe'
                      : 'Upgrade'}
                  </Button>
                )}
                {['parent', 'small_organization'].includes(currentPlan?.subscription) ? (
                  <UpgradePrice planName="large_organization" />
                ) : null}
              </Stack>
              <Divider />
              <List sx={{ width: '100%' }}>
                <ListItem>
                  <ListItemIcon>
                    <Verified color="primary" />
                  </ListItemIcon>
                  <ListItemText>Unlimited students</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Verified color="primary" />
                  </ListItemIcon>
                  <ListItemText>Full access to over 50 levels</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Verified color="primary" />
                  </ListItemIcon>
                  <ListItemText>Provide course certificates</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Verified color="primary" />
                  </ListItemIcon>
                  <ListItemText>Unlimited time access</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Verified color="primary" />
                  </ListItemIcon>
                  <ListItemText>Teacher administration</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Verified color="primary" />
                  </ListItemIcon>
                  <ListItemText>Student administration</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Verified color="primary" />
                  </ListItemIcon>
                  <ListItemText>Course quizzes</ListItemText>
                </ListItem>
                <ListItem>
                  <ListItemIcon>
                    <Verified color="primary" />
                  </ListItemIcon>
                  <ListItemText>Enterprise Support</ListItemText>
                </ListItem>
              </List>
            </PricingBox>
          </Grid>
        </Grid>
      </Container>
    </Container>
  );
}
