import Collider from '../@core/Collider';
import GameObject, { GameObjectProps, Position } from '../@core/GameObject';
import Interactable, { InteractionEvent } from '../@core/Interactable';
import Sprite, { SpriteProps } from '../@core/Sprite';
import useGameObject from '../@core/useGameObject';
import useGameObjectEvent from '../@core/useGameObjectEvent';
import waitForMs from '../@core/utils/waitForMs';
import useGame from '../@core/useGame';
import { useSound } from '../@core/Sound';
import soundData from '../soundData';
import { UNEXPECTED_ERRORS } from '../@core/utils/gameErrors';
import { useCommandError } from '../hooks/useCommandError';

function PushScript() {
  const { forceUpdate, getRef } = useGameObject();
  const { findGameObjectsByXY } = useGame();
  const { triggerError } = useCommandError();

  const playSfx = useSound(soundData.push);

  useGameObjectEvent<InteractionEvent>('interaction', async ({ step }) => {
    const typedStep = step as { push: boolean; direction: number[]; position: Position };
    if (!typedStep.push) return;

    const { direction, position } = typedStep;

    const targetPosition = {
      x: position.x + direction[0],
      y: position.y + direction[1]
    };

    const targetObjects = findGameObjectsByXY(targetPosition.x, targetPosition.y);

    const collidable = targetObjects.find((obj) => {
      return obj.getComponent('Collider');
    });

    if (collidable) {
      triggerError(UNEXPECTED_ERRORS.push.collision);
      return false;
    }

    getRef().transform.setX(targetPosition.x);
    getRef().transform.setY(targetPosition.y);

    playSfx();
    return await waitForMs(100).then(() => forceUpdate());
  });

  return null;
}
interface ItemPushProps {
  props: GameObjectProps;
  state: string;
  spriteData: SpriteProps;
  itemName: string;
}

export default function ItemPush({ props, state: initialState, spriteData }: ItemPushProps) {
  const name = `${initialState}-${props.x}-${props.y}`; // fallback name required for persisted flag

  return (
    <GameObject name={name} persisted={false} {...props} layer="item">
      <Sprite {...spriteData} state={initialState} />

      <Interactable type="push" />
      <Collider />
      <PushScript />
    </GameObject>
  );
}
