import { useParams } from 'react-router-dom';
import { Box, Container, Divider, Typography } from '@mui/material';
import StudentCertificates from './profile/StudentCertificates';
import React from 'react';
import { Loader } from '../components/Loader';

export default function MyCertificates() {
  const { studentId } = useParams();
  if (!studentId) {
    return <Loader />;
  }
  return (
    <Container maxWidth="md" sx={{ display: 'flex', gap: 4, flexDirection: 'column', pt: 4 }}>
      <Typography variant="h4">My Certificates</Typography>
      <Divider />
      <StudentCertificates studentId={studentId} />
    </Container>
  );
}
