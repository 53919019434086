import React, { useState, useEffect } from 'react';
import { useQuery, useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  TextField,
  Typography,
  Container,
  Box,
  Paper,
  CircularProgress,
  Autocomplete
} from '@mui/material';
import { UserService, Organization, User, Student, AuthService, LoginResponse } from '../client';
import { useSnackBarContext } from '../context/SnackBarContext';

export default function Impersonate() {
  const [organizationQuery, setOrganizationQuery] = useState('');
  const [emailQuery, setEmailQuery] = useState('');
  const [usernameQuery, setUsernameQuery] = useState('');
  const [selectedOrganization, setSelectedOrganization] = useState<Organization | null>(null);
  const [selectedUser, setSelectedUser] = useState<User | null>(null);
  const [selectedStudent, setSelectedStudent] = useState<Student | null>(null);

  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { createSnackBar } = useSnackBarContext();

  const organization_id = selectedOrganization ? selectedOrganization.id : undefined;

  const { data: organizations, isLoading: isOrgLoading } = useQuery(
    ['searchOrganizations', organizationQuery],
    () => UserService.searchOrganizationsApiUserSearchOrganizationsGet(organizationQuery),
    { enabled: !!organizationQuery }
  );

  const {
    data: users,
    isLoading: isUserLoading,
    refetch: refetchUsers
  } = useQuery(
    ['searchEmails', emailQuery, organization_id],
    () => UserService.searchEmailsApiUserSearchEmailsGet(emailQuery, organization_id),
    { enabled: false } // Disable automatic refetch
  );

  const {
    data: students,
    isLoading: isStudentLoading,
    refetch: refetchStudents
  } = useQuery(
    ['searchStudentUsernames', usernameQuery, organization_id],
    () =>
      UserService.searchStudentUsernamesApiUserSearchStudentUsernamesGet(
        usernameQuery,
        organization_id
      ),
    { enabled: false } // Disable automatic refetch
  );

  useEffect(() => {
    if (emailQuery) {
      refetchUsers();
    }
  }, [emailQuery, organization_id, refetchUsers]);

  useEffect(() => {
    if (usernameQuery) {
      refetchStudents();
    }
  }, [usernameQuery, organization_id, refetchStudents]);

  const impersonateUserMutation = useMutation<LoginResponse, Error, { userId: string }>(
    (data) => AuthService.impersonateUserApiAuthImpersonateUserPost(data.userId),
    {
      onSuccess: (loginResponse) => {
        queryClient.invalidateQueries('currentUser');
        if (loginResponse.last_played) {
          navigate(
            `/play/${loginResponse.last_played.course_id}/${loginResponse.last_played.level_slug}`
          );
        } else {
          navigate('/');
        }
      },
      onError: () => {
        createSnackBar({
          content: 'Impersonation failed, please contact support.',
          autoHide: true,
          severity: 'error'
        });
      }
    }
  );

  const impersonateStudentMutation = useMutation<LoginResponse, Error, { studentId: string }>(
    (data) => AuthService.impersonateStudentApiAuthImpersonateStudentPost(data.studentId),
    {
      onSuccess: (loginResponse) => {
        queryClient.invalidateQueries('currentUser');
        if (loginResponse.last_played) {
          navigate(
            `/play/${loginResponse.last_played.course_id}/${loginResponse.last_played.level_slug}`
          );
        } else {
          navigate('/');
        }
      },
      onError: () => {
        createSnackBar({
          content: 'Impersonation failed, please contact support.',
          autoHide: true,
          severity: 'error'
        });
      }
    }
  );

  const handleImpersonateUser = () => {
    if (selectedUser && selectedUser.id) {
      impersonateUserMutation.mutate({ userId: selectedUser.id });
    }
  };

  const handleImpersonateStudent = () => {
    if (selectedStudent && selectedStudent.id) {
      impersonateStudentMutation.mutate({ studentId: selectedStudent.id });
    }
  };

  return (
    <Container
      component="main"
      maxWidth="xs"
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '100%' }}
    >
      <Paper
        sx={{
          p: 3,
          marginTop: 8,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: 2
        }}
      >
        <Typography component="h1" variant="h5">
          Impersonate User or Student
        </Typography>
        <Autocomplete
          fullWidth
          options={organizations || []}
          getOptionLabel={(option) => option.name}
          loading={isOrgLoading}
          onInputChange={(event, value) => setOrganizationQuery(value)}
          onChange={(event, value) => setSelectedOrganization(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Organization"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isOrgLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Autocomplete
          fullWidth
          options={users || []}
          getOptionLabel={(option) => option.email}
          loading={isUserLoading}
          onInputChange={(event, value) => setEmailQuery(value)}
          onChange={(event, value) => setSelectedUser(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Email"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isUserLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />

        <Button
          type="button"
          fullWidth
          variant="contained"
          sx={{ mt: 3 }}
          onClick={handleImpersonateUser}
          disabled={!selectedUser || impersonateUserMutation.isLoading}
        >
          {impersonateUserMutation.isLoading ? 'Impersonating...' : 'Impersonate User'}
        </Button>

        <Autocomplete
          fullWidth
          options={students || []}
          getOptionLabel={(option) => option.username}
          loading={isStudentLoading}
          onInputChange={(event, value) => setUsernameQuery(value)}
          onChange={(event, value) => setSelectedStudent(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Search Student Username"
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {isStudentLoading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
        <Button
          type="button"
          fullWidth
          variant="contained"
          sx={{ mt: 3 }}
          onClick={handleImpersonateStudent}
          disabled={!selectedStudent || impersonateStudentMutation.isLoading}
        >
          {impersonateStudentMutation.isLoading ? 'Impersonating...' : 'Impersonate Student'}
        </Button>
        {impersonateUserMutation.isError && (
          <Typography color="error" variant="body2" sx={{ mt: 2 }}>
            Error impersonating user. Please try again.
          </Typography>
        )}
        {impersonateStudentMutation.isError && (
          <Typography color="error" variant="body2" sx={{ mt: 2 }}>
            Error impersonating student. Please try again.
          </Typography>
        )}
      </Paper>
    </Container>
  );
}
