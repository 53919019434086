import React, { useEffect } from 'react';
import { Box, DialogContent, DialogContentText } from '@mui/material';
import { Replay, Login, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { LottieRefCurrentProps, useLottie } from 'lottie-react';
import LevelCompleteAnimation from '../resources/animations/level-complete.json';
import { useOutput } from '../context/OutputContext';
import { useGenericModal } from '../context/GenericModalContext';
import { useCode } from '../context/CodeContext';
import { useCurrentUser } from '../hooks/useCurrentUser';
import useLevels from './useLevels';
import SecondaryButton from './GameUI/SecondaryButton';
import PrimaryButton from './GameUI/PrimaryButton';
import Modal3D from './GameUI/Modal3D';
import RibbonHeader from './GameUI/RibbonHeader';
import ChapterProgressBarAnimated from './ChapterProgressBarAnimated';

interface LevelCompleteModalProps {
  nextLevel?: string;
  currentLevel?: string;
  codeTemplate: string;
}

const lottieStyle: { [key: string]: unknown } = {
  pointerEvents: 'none',
  position: 'absolute',
  top: '50%',
  left: '50%',
  zIndex: 1400,
  transform: 'translate(-50%, -50%)',
  maxWidth: '700px',
  height: '700px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center'
};

const LevelCompleteModal: React.FC<LevelCompleteModalProps> = ({
  nextLevel,
  currentLevel,
  codeTemplate
}) => {
  const { courseId } = useLevels();
  const { currentUser } = useCurrentUser();
  const { openModal: open, setOpenModal: setOpenLevelCompleteModal } =
    useGenericModal('level-complete');
  const { setResetLevel } = useOutput();
  const { setCode } = useCode();
  const lottieRef = React.useRef<LottieRefCurrentProps>(null);
  const { View: LottieView, goToAndPlay } = useLottie({
    animationData: LevelCompleteAnimation,
    loop: false,
    autoplay: false,
    style: lottieStyle,
    lottieRef
  });

  useEffect(() => {
    if (open) {
      setTimeout(() => {
        goToAndPlay(0);
      }, 10);
    }
  }, [open]);

  const handleClose = () => {
    setOpenLevelCompleteModal(false);
  };

  const handleReplay = () => {
    setCode(codeTemplate);
    setResetLevel(true);
    setOpenLevelCompleteModal(false);
  };

  if (!nextLevel || !currentLevel) return null;
  return (
    <>
      <Box component="div">{LottieView}</Box>
      <Modal3D
        open={open}
        onClose={handleClose}
        maxWidth="xs"
        disableEscapeKeyDown
        sx={{ '& .MuiDialog-paper': { minWidth: '350px' } }}
      >
        <RibbonHeader>YOU MADE IT!</RibbonHeader>
        <DialogContent sx={{ padding: '12px' }}>
          <DialogContentText
            sx={{
              fontFamily: 'LDFComicSansBold',
              fontSize: '58px',
              textTransform: 'uppercase',
              textAlign: 'center',
              color: '#F1E5C6',
              textShadow: '2px 2px 4px #A37B5E',
              webkitTextStroke: '1px #A37B5E'
            }}
          >
            Level {currentLevel ? parseInt(currentLevel.match(/\d+/)?.[0] || '0', 10) : 0}
          </DialogContentText>
        </DialogContent>
        <div className="ribbonRounded">
          {'Complete'.split('').map((char, index) => (
            <span key={index} className="char" style={{ animationDelay: `${1.6 + 0.1 * index}s ` }}>
              {char}
            </span>
          ))}
        </div>
        <Box component="div" sx={{ p: '6px', mt: '60px', mx: '2px', background: '#c5a77b' }}>
          <ChapterProgressBarAnimated />
        </Box>
        <Box component="div" sx={{ p: '20px', display: 'flex', flexDirection: 'row', gap: '16px' }}>
          <SecondaryButton
            onClick={handleReplay}
            sx={{
              width: '100%'
            }}
            startIcon={<Replay />}
          >
            Replay
          </SecondaryButton>
          {!currentUser && (
            <PrimaryButton
              href="/signup"
              sx={{
                width: '100%'
              }}
              startIcon={<Login />}
            >
              Sign Up
            </PrimaryButton>
          )}
        </Box>
        <Box
          component="div"
          sx={{ px: '20px', pb: '20px', display: 'flex', flexDirection: 'column', gap: '16px' }}
        >
          <PrimaryButton
            href={`/play/${courseId}/${nextLevel}`}
            sx={{
              width: '100%'
            }}
            endIcon={<KeyboardDoubleArrowRight sx={{ mt: '3px' }} />}
          >
            Continue
          </PrimaryButton>
        </Box>
      </Modal3D>
    </>
  );
};

export default LevelCompleteModal;
