import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, Typography, LinearProgress, useTheme } from '@mui/material';
import { useSnackBarContext } from '../../../context/SnackBarContext';
import { GridValidRowModel, GridColDef } from '@mui/x-data-grid';

interface DropzoneCSVProps {
  onFileUpload: (
    csvData: GridValidRowModel[],
    columns: GridColDef[],
    missingColumns: string[]
  ) => void;
  isDisabled: boolean;
  requiredColumns: string[];
  emailField?: string;
  isFullNameSplit?: boolean;
  firstNameField?: string;
  lastNameField?: string;
  fullNameField?: string;
  usernameField: string;
  passwordField: string;
  separator: string;
}

const DropzoneCSV: React.FC<DropzoneCSVProps> = ({
  onFileUpload,
  isDisabled,
  requiredColumns,
  emailField,
  isFullNameSplit,
  firstNameField,
  lastNameField,
  fullNameField,
  usernameField,
  passwordField,
  separator
}) => {
  const { createSnackBar } = useSnackBarContext();
  const theme = useTheme();
  const [file, setFile] = useState<File | null>(null);
  const [uploadProgress, setUploadProgress] = useState<number | null>(null);
  const [missingColumns, setMissingColumns] = useState<string[]>([]);
  const [presentColumns, setPresentColumns] = useState<string[]>([]);
  const [parseError, setParseError] = useState<string | null>(null);

  const processCSVData = (text: string) => {
    const rows = text.trim().split('\n');
    const headers = rows[0].split(separator);
    const csvData = rows.slice(1).map((row, index) => {
      const columns = row.split(separator);
      const dataRow: any = { id: index + 1 };

      if (isFullNameSplit) {
        const firstNameIndex = headers.indexOf(firstNameField || '');
        const lastNameIndex = headers.indexOf(lastNameField || '');
        if (firstNameIndex !== -1 && lastNameIndex !== -1) {
          dataRow['full_name'] = `${columns[firstNameIndex]} ${columns[lastNameIndex]}`;
        }
      } else {
        const fullNameIndex = headers.indexOf(fullNameField || '');
        if (fullNameIndex !== -1) {
          dataRow['full_name'] = columns[fullNameIndex];
        }
      }

      const usernameIndex = headers.indexOf(usernameField);
      const passwordIndex = headers.indexOf(passwordField);
      if (usernameIndex !== -1) {
        dataRow['username'] = columns[usernameIndex];
      }
      if (passwordIndex !== -1) {
        dataRow['password'] = columns[passwordIndex];
      }
      if (emailField) {
        const emailIndex = headers.indexOf(emailField);
        if (emailIndex !== -1) {
          dataRow['email'] = columns[emailIndex];
        }
      }
      return dataRow;
    });

    const dynamicColumns: GridColDef[] = [
      { field: 'username', headerName: 'Username', width: 150, editable: true },
      { field: 'full_name', headerName: 'Full Name', width: 150, editable: true },
      { field: 'password', headerName: 'Password', width: 150, editable: true },
      { field: 'email', headerName: 'Email', width: 200, editable: true }
    ];

    const missingColumns = requiredColumns.filter((column) => !headers.includes(column));
    const presentColumns = headers;

    return { csvData, columns: dynamicColumns, missingColumns, presentColumns };
  };

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      if (isDisabled) return;

      const uploadedFile = acceptedFiles[0];
      setFile(uploadedFile);
      setUploadProgress(0);
      setMissingColumns([]);
      setPresentColumns([]);
      setParseError(null);

      const reader = new FileReader();
      reader.onload = (e) => {
        const text = e.target?.result as string;
        if (text) {
          try {
            const { csvData, columns, missingColumns, presentColumns } = processCSVData(text);

            if (missingColumns.length === requiredColumns.length) {
              throw new Error(
                `Failed to parse CSV. Please ensure the separator is "${separator}" and try again.`
              );
            }

            setMissingColumns(missingColumns);
            setPresentColumns(presentColumns);
            onFileUpload(csvData, columns, missingColumns);

            createSnackBar({
              content:
                missingColumns.length > 0
                  ? `Missing required columns in CSV: ${missingColumns.join(', ')}`
                  : 'File uploaded successfully',
              autoHide: true,
              severity: missingColumns.length > 0 ? 'error' : 'success'
            });
          } catch (error) {
            const errorMessage =
              error instanceof Error ? error.message : 'An unknown error occurred';
            setParseError(errorMessage);
            onFileUpload([], [], []);
            createSnackBar({
              content: errorMessage,
              autoHide: true,
              severity: 'error'
            });
          }
        } else {
          createSnackBar({
            content: 'Failed to read file',
            autoHide: true,
            severity: 'error'
          });
        }
        setUploadProgress(100);
      };
      reader.onerror = () => {
        createSnackBar({
          content: 'Failed to read file',
          autoHide: true,
          severity: 'error'
        });
        setUploadProgress(100);
      };
      reader.readAsText(uploadedFile);
    },
    [
      onFileUpload,
      createSnackBar,
      isDisabled,
      requiredColumns,
      emailField,
      isFullNameSplit,
      firstNameField,
      lastNameField,
      fullNameField,
      usernameField,
      passwordField,
      separator
    ]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: { 'text/csv': ['.csv'] },
    disabled: isDisabled
  });

  return (
    <Box component="div" sx={{ mt: 1 }}>
      <Typography variant="h6" gutterBottom>
        Upload CSV
      </Typography>
      <Box
        component="div"
        {...getRootProps()}
        sx={{
          cursor: isDisabled ? 'not-allowed' : 'pointer',
          textAlign: 'center',
          p: 3,
          mb: 2,
          border: `2px dashed ${isDisabled ? theme.palette.error.main : 'grey'}`,
          backgroundColor: 'transparent'
        }}
      >
        <input {...getInputProps()} />
        {isDragActive ? (
          <Typography>Drop the files here...</Typography>
        ) : isDisabled ? (
          <Typography color="error">
            Please fill in all required fields before uploading.
          </Typography>
        ) : (
          <Typography>Drag 'n' drop a CSV file here, or click to select one</Typography>
        )}
      </Box>
      {file && (
        <Box component="div" mb={2}>
          <Typography variant="body2">File name: {file.name}</Typography>
          <Typography variant="body2">File size: {file.size} bytes</Typography>
          {uploadProgress !== null && (
            <Box component="div" mt={2}>
              <LinearProgress variant="determinate" value={uploadProgress} />
            </Box>
          )}
        </Box>
      )}
      {parseError && (
        <Typography color="error" variant="body2">
          {parseError}
        </Typography>
      )}
      {missingColumns.length > 0 && !parseError && (
        <Typography color="error" variant="body2">
          Missing required columns in CSV: {missingColumns.join(', ')}.<br />
          You have next columns in your CSV: {presentColumns.join(', ')}.<br />
          Please, correct mistakes and try again.
        </Typography>
      )}
    </Box>
  );
};

export default DropzoneCSV;
