import { Box, Button, Container, Paper, Typography } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridToolbar
} from '@mui/x-data-grid';
import { Delete, Edit, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { useOrgClasses } from '../../../hooks/useOrgClasses';
import { useNavigate } from 'react-router-dom';
import { useConfirm } from 'material-ui-confirm';

export default function Classes() {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { classes, deleteClass } = useOrgClasses();

  const handleEditClick = async (params: GridRowParams) => {
    navigate(`/organization/classes/${params.id}/edit`);
  };

  const handleDeleteClick = async (params: GridRowParams) => {
    try {
      await confirm({
        description: `Are you sure you want to remove ${params.row.name}?`,
        title: 'Remove class',
        confirmationText: 'Yes',
        cancellationText: 'No'
      });
    } catch (e) {
      return;
    }

    await deleteClass.mutateAsync(`${params.id}`);
  };

  const classesColumns: GridColDef[] = [
    {
      field: 'name',
      headerName: 'Name',
      flex: 1,
      type: 'string'
    },
    {
      field: 'created_at',
      headerName: 'Created At',
      minWidth: 250,
      type: 'string',
      valueFormatter: (v) => new Date(v.value).toLocaleString()
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 150,
      cellClassName: 'actions',
      getActions: (params) => {
        return [
          <GridActionsCellItem
            icon={<Edit />}
            label="Edit"
            className="textPrimary"
            onClick={() => handleEditClick(params)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<Delete />}
            label="Delete"
            onClick={() => handleDeleteClick(params)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<KeyboardDoubleArrowRight color="primary" />}
            label="Open"
            onClick={() => navigate(`/organization/classes/${params.row.id}`)}
            color="inherit"
          />
        ];
      }
    }
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 3, mb: 14 }}>
      <Box component="div" display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" gutterBottom>
          Classes
        </Typography>
        <Box component="div" display="flex" gap={2} alignItems="center">
          <Button variant="contained" color="primary" href="/organization/classes/add">
            Add Class
          </Button>
        </Box>
      </Box>
      <Box sx={{ height: 500, width: '100%', mt: 2, mb: 2 }} component={Paper}>
        {classes && (
          <DataGrid
            slots={{ toolbar: GridToolbar }}
            columns={classesColumns}
            rows={classes}
            onCellDoubleClick={(e) => navigate(`/organization/classes/${e.row.id}`)}
          ></DataGrid>
        )}
      </Box>
    </Container>
  );
}
