import {
  FormControlLabel,
  Box,
  Button,
  FormGroup,
  Grid,
  Paper,
  Stack,
  TextField,
  Checkbox,
  FormHelperText,
  FormControl
} from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from 'react-query';
import { useParams } from 'react-router-dom';
import { OrganizationService, CreateClassForm } from '../../../client';
import { Loader } from '../../Loader';
import { useOrgClasses } from '../../../hooks/useOrgClasses';
import React, { useEffect, useMemo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { classSchema } from './classSchema';

export const AddEditClassForm = () => {
  const { classId } = useParams();

  const { addClass, updateClass } = useOrgClasses();

  const { data: classData, isLoading: isClassLoading } = useQuery(
    ['class', classId],
    () => OrganizationService.getOrganizationClassApiOrganizationClassesClassIdGet(classId || ''),
    {
      enabled: !!classId
    }
  );

  const formMethods = useForm({
    resolver: yupResolver(classSchema),
    mode: 'onChange',
    defaultValues: {
      name: '',
      no_skip: false,
      quiz: false
    }
  });

  useEffect(() => {
    if (!classData) {
      return;
    }
    formMethods.reset({
      name: classData.name,
      no_skip: classData.no_skip ?? true,
      quiz: classData.quiz ?? true
    });
  }, [classData]);
  const {
    handleSubmit,
    control,
    formState: { errors, isSubmitting }
  } = formMethods;

  const onSubmit = async (data: CreateClassForm) => {
    try {
      if (!classId) {
        await addClass.mutateAsync(data);
        return;
      }
      await updateClass.mutateAsync({ id: classId, data });
    } catch (error) {
      console.log(error);
    }
  };

  if (classId && isClassLoading) return <Loader />;
  if (classId && !classData) return <Box component="div">Class user not found</Box>;

  return (
    <Paper sx={{ p: 5, mt: 4 }}>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Box component="div" display="flex" flexDirection="row" gap={2} sx={{ width: '100%' }}>
              <Stack gap={4} flex={1}>
                <Controller
                  name="name"
                  control={control}
                  render={({ field: { value, onChange } }) => (
                    <TextField
                      error={!!errors.name}
                      helperText={errors.name?.message}
                      fullWidth
                      label="Class Name"
                      margin="none"
                      value={value}
                      onChange={onChange}
                    />
                  )}
                />
                <FormGroup>
                  <Controller
                    name="no_skip"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <FormControl error={!!errors.no_skip}>
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox />}
                            label="Unlock only the next incomplete chapter"
                            checked={value}
                            onChange={onChange}
                          />
                          <FormHelperText>{errors.no_skip?.message}</FormHelperText>
                        </FormGroup>
                      </FormControl>
                    )}
                  />

                  <Controller
                    name="quiz"
                    control={control}
                    render={({ field: { value, onChange } }) => (
                      <FormControl error={!!errors.quiz}>
                        <FormGroup>
                          <FormControlLabel
                            control={<Checkbox value={value} onChange={onChange} />}
                            label="Have students take a quiz at the end of each chapter"
                            checked={value}
                            onChange={onChange}
                          />
                          <FormHelperText>{errors.quiz?.message}</FormHelperText>
                        </FormGroup>
                      </FormControl>
                    )}
                  />
                </FormGroup>
              </Stack>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box component="div" display="flex" justifyContent="flex-end" mt={2}>
              <Button type="submit" variant="contained" color="primary" disabled={isSubmitting}>
                {isSubmitting || addClass.isLoading || updateClass.isLoading ? 'Saving...' : 'Save'}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Paper>
  );
};
