import { Box, Button, Paper } from '@mui/material';
import {
  DataGrid,
  GridActionsCellItem,
  GridColDef,
  GridRowParams,
  GridToolbar
} from '@mui/x-data-grid';
import { Delete, Edit, KeyboardDoubleArrowRight } from '@mui/icons-material';
import { useConfirm } from 'material-ui-confirm';
import { useNavigate } from 'react-router-dom';

import StudentAvatar from '../../StudentAvatar';
import { Loader } from '../../Loader';
import useOrganizationStudents from '../../../hooks/useOrganzationStudents';
import { useOrgClasses } from '../../../hooks/useOrgClasses';
import ImportStudentsDrawer from './ImportStudentsDrawer';
import { useState } from 'react';

export const OrganizationStudentsList = ({ classId }: { classId?: string }) => {
  const confirm = useConfirm();
  const navigate = useNavigate();
  const { students, isStudentsLoading, deleteOrgStudentUserMutation } = useOrganizationStudents();
  const { classes } = useOrgClasses();

  const filteredStudents = classId ? students?.filter((x) => x.class_id === classId) : students;
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);

  if (isStudentsLoading) return <Loader />;
  if (filteredStudents === undefined) return <Loader />;

  const handleEditClick = async (params: GridRowParams) => {
    navigate(`/organization/students/${params.id}/edit`);
  };

  const handleDeleteClick = async (params: GridRowParams) => {
    try {
      await confirm({
        description: `Are you sure you want to remove ${params.row.username}?`,
        title: 'Remove student',
        confirmationText: 'Yes',
        cancellationText: 'No'
      });
    } catch (e) {
      return;
    }

    if (typeof params.id === 'string') {
      await deleteOrgStudentUserMutation.mutateAsync(params.id);
    }
  };

  const studentsColumns: GridColDef[] = [
    {
      field: 'username',
      headerName: 'Username',
      flex: 1,
      renderCell: (params) => (
        <Box component="div" display="flex" alignItems="center" gap={2}>
          <StudentAvatar student={params.row} size={32} variant="body2" />
        </Box>
      )
    },
    {
      field: 'full_name',
      headerName: 'Full Name',
      flex: 1,
      type: 'string'
    },
    {
      field: 'class_id',
      headerName: 'Class',
      flex: 1,
      type: 'string',
      valueGetter: (params) => classes?.find((x) => x.id === params.value)?.name
    },
    {
      field: 'levels_completed',
      headerName: 'Levels Completed',
      flex: 1,
      type: 'number'
    },
    {
      field: 'actions',
      type: 'actions',
      headerName: 'Actions',
      width: 150,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<Edit />}
          label="Edit"
          onClick={() => handleEditClick(params)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<Delete />}
          label="Delete"
          onClick={() => handleDeleteClick(params)}
          color="inherit"
        />,
        <GridActionsCellItem
          icon={<KeyboardDoubleArrowRight color="primary" />}
          label="Open"
          onClick={() => navigate(`/organization/students/${params.row.id}`)}
          color="inherit"
        />
      ]
    }
  ];

  return (
    <>
      <Box component="div" display="flex" justifyContent="flex-end" alignItems="center" mt={2}>
        <Box component="div" display="flex" gap={2}>
          <Button variant="outlined" color="primary" onClick={() => setIsDrawerOpen(true)}>
            Import CSV
          </Button>
          <Button
            variant="contained"
            color="primary"
            href={
              classId
                ? `/organization/students/add?classId=${classId}`
                : '/organization/students/add'
            }
          >
            Add Student
          </Button>
        </Box>
      </Box>
      <Box sx={{ height: 500, width: '100%', mt: 2, mb: 2 }} component={Paper}>
        <DataGrid
          slots={{ toolbar: GridToolbar }}
          columns={studentsColumns}
          rows={filteredStudents}
          onCellDoubleClick={(e) => navigate(`/organization/students/${e.row.id}`)}
        />
      </Box>
      <ImportStudentsDrawer
        classId={classId}
        isOpen={isDrawerOpen}
        onClose={() => setIsDrawerOpen(false)}
      />
    </>
  );
};
