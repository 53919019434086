import { Link, useNavigate, useParams } from 'react-router-dom';
import { Button, Card, CardContent, CardMedia, Grid, Typography } from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import KeyboardReturnOutlined from '@mui/icons-material/KeyboardReturnOutlined';
import { useCertificateActions } from './CertificateActions';
import { useQuery } from 'react-query';
import { CertificateService } from '../../client';

const CertificateViewer = () => {
  const navigate = useNavigate();
  const { certificateId } = useParams<{ certificateId: string }>();
  const { data: certificate } = useQuery(
    'certificates',
    () => CertificateService.getCertificateDataApiCertificateCertIdGet(certificateId ?? ''),
    {
      enabled: !!certificateId
    }
  );

  const { handleDownloadPDF, handleDownloadImage, handleSendByEmail, handleAddToProfile } =
    useCertificateActions(
      certificate?.id ?? '',
      certificate?.student_name ?? '',
      certificate?.completion_date ?? '',
      certificate?.course_name ?? ''
    );

  if (!certificate) {
    return (
      <Typography variant="h6" sx={{ margin: 2 }}>
        Certificate not found.
      </Typography>
    );
  }

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={7}>
        <Typography gutterBottom variant="h5" component="div" sx={{ margin: 2 }}>
          {certificate.course_name}
          <hr />
        </Typography>
        <Card sx={{ backgroundColor: 'transparent' }}>
          <CardContent>
            <CardMedia
              component="img"
              image={`/api/certificate/${certificate.id}/jpg`}
              alt={certificate.course_name}
              sx={{ width: '100%', height: 'auto' }}
            />
          </CardContent>
        </Card>
      </Grid>
      <Grid item xs={12} sm={4}>
        <Card sx={{ backgroundColor: 'transparent' }}>
          <CardContent>
            <Grid container direction="column" spacing={2} mt={8}>
              <Typography color="textSecondary" mt={2} mx={2}>
                This certificate is issued to {certificate.student_name} for completing the{' '}
                {certificate.course_name}.
              </Typography>
              <Typography color="textSecondary" mx={2} mt={2}>
                Issued on {new Date(certificate.completion_date).toDateString()}
              </Typography>
              <Grid item container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    startIcon={<DownloadIcon />}
                    onClick={() => handleDownloadPDF()}
                    fullWidth
                  >
                    Download as PDF
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    startIcon={<DownloadIcon />}
                    onClick={() => handleDownloadImage()}
                    fullWidth
                  >
                    Download as Image
                  </Button>
                </Grid>
              </Grid>
              <Grid item container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    variant="outlined"
                    startIcon={<EmailIcon />}
                    onClick={() => handleSendByEmail()}
                    fullWidth
                  >
                    Send by Email
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    variant={'outlined'}
                    startIcon={<LinkedInIcon />}
                    onClick={() => handleAddToProfile()}
                    fullWidth
                  >
                    Add to Profile
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default CertificateViewer;
