import { useParams } from 'react-router-dom';
import { Container, Divider, Typography } from '@mui/material';
import React from 'react';
import { Loader } from '../components/Loader';
import StudentBadges from './profile/StudentBadges';
import useLevels from '../components/useLevels';

export default function MyBadges() {
  const { studentId } = useParams();
  // TODO: add course selector
  const { courseData, courseId } = useLevels();

  const activeBadges = [];
  let allChapters = true;
  for (const chapter of courseData?.chapters ?? []) {
    if (chapter.level_data.every((x) => x.completed)) {
      activeBadges.push(`chapter-${chapter.id}`);
    } else {
      allChapters = false;
    }
  }

  if (allChapters) {
    activeBadges.push(`course-${courseId}`);
  }

  if (!studentId) {
    return <Loader />;
  }
  return (
    <Container maxWidth="md" sx={{ display: 'flex', gap: 4, flexDirection: 'column', pt: 4 }}>
      <Typography variant="h4">My Badges</Typography>
      <Divider />
      <StudentBadges activeBadges={activeBadges} />
    </Container>
  );
}
