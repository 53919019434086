import Box from '@mui/material/Box';
import { NavLink } from 'react-router-dom';
import React from 'react';
import Typography from '@mui/material/Typography';

export default function Footer() {
  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        gap: 1,
        paddingX: 1,
        paddingY: 2,
        justifyContent: 'center',
        color: 'white',
        flexDirection: 'column',
        borderTop: '1px solid #444'
      }}
    >
      <Typography variant="body2">Copyright &copy; 2024 codingforkids.io</Typography>
      <Typography variant="body2">
        Read our <NavLink to="/tos">Terms of Service</NavLink> and{' '}
        <NavLink to="/privacy">Privacy Policy</NavLink>
      </Typography>
    </Box>
  );
}
