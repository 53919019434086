import { Container, Typography, List, ListItem, Divider, Link } from '@mui/material';

export default function PrivacyPolicy() {
  return (
    <Container maxWidth="md" sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Privacy Policy - Coding for Kids
      </Typography>

      <Typography variant="h6">Last Updated: Feb 17, 2024</Typography>
      <Divider sx={{ my: 2 }} />

      <Typography paragraph>
        Welcome to Coding for Kids. This Privacy Policy explains how we collect, use, disclose, and
        safeguard your information when you visit our website and use our service. We respect your
        privacy and are committed to protecting it through our compliance with this policy.
      </Typography>

      <Typography variant="h5">Collection of Information</Typography>
      <List>
        <ListItem>
          <Typography>
            <strong>Email and Registration Data:</strong> We collect your email address when you
            sign up for our service.
          </Typography>
        </ListItem>
        <ListItem>
          <Typography>
            <strong>Course progress</strong>: We track the progress of your children's course
            completion and any score associated with your child's performance.
          </Typography>
        </ListItem>
      </List>

      <Typography variant="h5">Use of Information</Typography>
      <List>
        <ListItem>
          <Typography>
            The information we collect is used solely for the purpose of providing and improving our
            service. We do not use your information for any other purpose.
          </Typography>
        </ListItem>
      </List>

      <Typography variant="h5">Disclosure of Information</Typography>
      <List>
        <ListItem>
          <Typography>
            We do not sell, rent, or otherwise share your personal information with third parties
            for their marketing purposes.
          </Typography>
        </ListItem>
      </List>

      <Typography variant="h5">Google Analytics</Typography>
      <List>
        <ListItem>
          <Typography>
            We use Google Analytics to help understand how our customers use the Site. You can read
            more about how Google uses your Personal Information here:{' '}
            <Link href="https://www.google.com/intl/en/policies/privacy/">
              https://www.google.com/intl/en/policies/privacy/
            </Link>
            . You can also opt-out of Google Analytics here:{' '}
            <Link href="https://tools.google.com/dlpage/gaoptout">
              https://tools.google.com/dlpage/gaoptout
            </Link>
            .
          </Typography>
        </ListItem>
      </List>

      <Typography variant="h5">Data Security</Typography>
      <List>
        <ListItem>
          <Typography>
            We implement a variety of security measures to maintain the safety of your personal
            information when you enter, submit, or access your personal information.
          </Typography>
        </ListItem>
      </List>

      <Typography variant="h5">Changes to This Privacy Policy</Typography>
      <Typography paragraph>
        We may update our Privacy Policy from time to time. We will notify you of any changes by
        posting the new Privacy Policy on this page. You are advised to review this Privacy Policy
        periodically for any changes.
      </Typography>

      <Typography variant="h5">Contact Us</Typography>
      <Typography paragraph>
        Company name: <p>LearnX Ltd.</p>
      </Typography>
      <Typography paragraph>
        If you have any questions about this Privacy Policy, please contact us at{' '}
        <Link href="mailto:contact@codingforkids.io">contact@codingforkids.io</Link>.
      </Typography>
    </Container>
  );
}
