/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ChatGPTRequest } from '../models/ChatGPTRequest';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ChatgptService {

    /**
     * Generate Tip
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static generateTipApiChatgptGenerateTipPost(
        requestBody: ChatGPTRequest,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/chatgpt/generate-tip',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

}
