import { Stack, Typography } from '@mui/material';
import CourseProgress from '../../components/CourseProgress';
import { DataGrid, GridCellParams, GridColDef } from '@mui/x-data-grid';
import React from 'react';
import { CourseData, LevelDataSummary } from '../../client';

export default function StudentProgress({ courseData }: { courseData: CourseData }) {
  const rows: (LevelDataSummary & { chapterName: string; chapterId: number; levelId: number })[] =
    [];
  for (const [chapterId, chapter] of courseData.chapters.entries()) {
    for (const [levelId, level] of chapter.level_data.entries()) {
      rows.push({
        ...level,
        chapterName: chapter.name,
        chapterId: chapterId + 1,
        levelId: levelId + 1
      });
    }
  }

  const columns: GridColDef[] = [
    { field: 'completed', headerName: 'Completed', width: 100, type: 'boolean' },
    {
      field: 'chapterName',
      headerName: 'Chapter',
      width: 200,
      flex: 1,
      valueGetter: (params: GridCellParams) => `${params.row.chapterId} - ${params.row.chapterName}`
    },
    {
      field: 'levelId',
      headerName: 'Level',
      flex: 1,
      valueGetter: (params: GridCellParams) => `${params.row.levelId} - ${params.row.name}`
    },
    {
      field: 'last_updated',
      headerName: 'Last Attempt',
      width: 200,
      valueGetter: (params: GridCellParams) =>
        params.row.last_updated ? new Date(params.row.last_updated).toDateString() : 'Never'
    }
  ];
  return (
    <Stack gap={2}>
      <Typography variant="h5">{courseData.name}</Typography>
      <CourseProgress courseData={courseData} />
      <DataGrid
        rowHeight={36}
        columns={columns}
        rows={rows}
        getRowId={(row) => row.slug}
        sx={{ maxHeight: 400 }}
      />
    </Stack>
  );
}
