import { Box, Card } from '@mui/material';
import AvatarSelectorModal from './AvatarSelectionModal';
import Typography from '@mui/material/Typography';
import { useGenericModal } from '../../context/GenericModalContext';

export default function ProfileImageWithSelection({
  selectedAvatar,
  setSelectedAvatar,
  size = 'large'
}: {
  selectedAvatar: string;
  setSelectedAvatar: (avatar: string) => void;
  size?: 'small' | 'large';
}) {
  const { setOpenModal: setSelectModalOpen } = useGenericModal('avatar-selector-modal');

  return (
    <Box
      component="div"
      flexShrink={0}
      sx={size === 'large' ? { width: 160, height: 160 } : { width: 128, height: 128 }}
    >
      <AvatarSelectorModal
        currentAvatar={selectedAvatar}
        onSelect={(avatar: string) => {
          setSelectModalOpen(false);
          setSelectedAvatar(avatar);
        }}
      />
      <Card
        onClick={() => setSelectModalOpen(true)}
        sx={{
          p: 1,
          borderColor: '#666',
          borderWidth: 1,
          borderStyle: 'solid',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          cursor: 'pointer',
          gap: 1,
          '&:hover': {
            backgroundColor: 'rgba(255,255,255,0.1)'
          }
        }}
      >
        <img
          src={`/assets/characters/portraits/${selectedAvatar}.png`}
          style={size === 'large' ? { width: 128, height: 128 } : { width: 82, height: 82 }}
        />
        <Typography variant="body2" color="text.secondary">
          Choose Avatar
        </Typography>
      </Card>
    </Box>
  );
}
