import { Container, Divider, Link, Typography } from '@mui/material';

export default function TermsOfService() {
  return (
    <Container maxWidth="md" sx={{ p: 2 }}>
      <Typography variant="h4" gutterBottom>
        Terms of Service - Coding for Kids
      </Typography>
      <Typography variant="h6">Last Updated: Feb 17, 2024</Typography>
      <Divider sx={{ my: 2 }} />

      <Typography variant="h6" gutterBottom>
        1. Introduction
      </Typography>
      <Typography paragraph>
        Welcome to Coding for Kids, an interactive online platform dedicated to teaching coding to
        children aged 7 to 16 through engaging games. By using our services, you agree to these
        Terms of Service.
      </Typography>

      <Typography variant="h6" gutterBottom>
        2. User Registration and Account Management
      </Typography>
      <Typography paragraph>
        Children under the age of 13 are required to have their parent or legal guardian register on
        their behalf. Parents can create and manage child accounts, including tracking progress and
        setting goals.
      </Typography>

      <Typography variant="h6" gutterBottom>
        3. Course Access and Certification
      </Typography>
      <Typography paragraph>
        Our platform offers a mix of free and paid content. Each course purchase allows one child to
        access the course and earn a certificate upon completion. Certificates are issued in PDF and
        image formats, downloadable from our website and sent via email.
      </Typography>

      <Typography variant="h6" gutterBottom>
        4. Payment and Refund Policy
      </Typography>
      <Typography paragraph>
        Payment for each course must be made separately. A refund can be requested as long as the
        course has not been completed and the certificate has not been issued. To receive a refund,
        please contact us at{' '}
        <Link href="mailto:contact@codingforkids.io">contact@codingforkids.io</Link>.
      </Typography>

      <Typography variant="h6" gutterBottom>
        5. Privacy and Data Protection
      </Typography>
      <Typography paragraph>
        We are committed to protecting the privacy of our users, especially children. Our privacy
        policy provides detailed information about how we collect, use, and protect personal data.
      </Typography>

      <Typography variant="h6" gutterBottom>
        6. Acceptable Use
      </Typography>
      <Typography paragraph>
        Users must adhere to our guidelines for acceptable use of the platform, ensuring a safe and
        respectful environment for all participants.
      </Typography>

      <Typography variant="h6" gutterBottom>
        7. Changes to Terms of Service
      </Typography>
      <Typography paragraph>
        We reserve the right to modify these terms at any time. Continued use of our services after
        such changes constitutes acceptance of the new terms.
      </Typography>

      <Typography variant="h6" gutterBottom>
        8. Contact Information
      </Typography>
      <Typography paragraph>
        For any questions or concerns regarding these Terms of Service, please contact us at{' '}
        <Link href="mailto:contact@codingforkids.io">contact@codingforkids.io</Link>.
      </Typography>

      <Typography variant="h6" gutterBottom>
        9. Governing Law
      </Typography>
      <Typography paragraph>
        These terms are governed by [Your Country's] laws. Any disputes arising from these terms
        will be subject to the jurisdiction of [Your Country's] courts.
      </Typography>

      {/* Section 10 */}
      <Typography variant="h6" gutterBottom>
        10. Contact Information
      </Typography>
      <Typography paragraph>
        Company name: <p>LearnX Ltd.</p>
      </Typography>
      <Typography variant="body1" gutterBottom>
        For any questions regarding these Terms of Service, please contact us at&nbsp;
        <Link href="mailto:contact@codingforkids.io">contact@codingforkids.io</Link>.
      </Typography>
    </Container>
  );
}
