import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { orgStudentSchema } from '../components/Organization/Student/orgStudentSchema';
import { useStudentUsernameExists } from './useStudentUsernameExists';
import { useEffect } from 'react';
import { useEmailExists } from './useEmailExists';
import { Student } from '../client';

export const useAddEditOrgStudentForm = (student: Student | undefined) => {
  const formMethods = useForm({
    resolver: yupResolver(orgStudentSchema),
    mode: 'onChange'
  });

  const email = formMethods.watch('email');
  const username = formMethods.watch('username');

  const { emailExists, isLoading } = useEmailExists(email!);
  const { usernameExists, isUsernameLoading } = useStudentUsernameExists(username!);

  useEffect(() => {
    if (email === student?.email) return;
    if (email && !isLoading) {
      if (emailExists) {
        formMethods.setError('email', {
          type: 'manual',
          message: 'Email already exists'
        });
      } else {
        if (formMethods.formState.errors.email?.type === 'manual') {
          formMethods.clearErrors('email');
        }
      }
    }
  }, [email, emailExists, isLoading, formMethods.setError, formMethods.clearErrors]);

  useEffect(() => {
    if (username === student?.username) return;
    if (username && !isUsernameLoading) {
      if (usernameExists) {
        formMethods.setError('username', {
          type: 'manual',
          message: 'Student username already exists'
        });
      } else {
        if (formMethods.formState.errors.username?.type === 'manual') {
          formMethods.clearErrors('username');
        }
      }
    }
  }, [username, usernameExists, isUsernameLoading, formMethods.setError, formMethods.clearErrors]);

  return formMethods;
};
