import Collider from '../@core/Collider';
import GameObject, { GameObjectProps } from '../@core/GameObject';
import Interactable, { InteractionEvent } from '../@core/Interactable';
import Sprite, { SpriteProps, SpriteRef } from '../@core/Sprite';
import useGameObject from '../@core/useGameObject';
import useGameObjectEvent from '../@core/useGameObjectEvent';
import waitForMs from '../@core/utils/waitForMs';

function ChestScript() {
  const { getComponent, forceUpdate } = useGameObject();

  useGameObjectEvent<InteractionEvent>('interaction', async ({ step }) => {
    const typedStep = step as { state: string };
    if (!typedStep.state) return;

    switch (typedStep.state) {
      case 'open':
        getComponent<SpriteRef>('Sprite').setIsInteracting(true);
        getComponent<SpriteRef>('Sprite').setState('chestOpened');
        getComponent<SpriteRef>('Sprite').setFrameWidth(16);
        getComponent<SpriteRef>('Sprite').setFrameHeight(32);
        getComponent<SpriteRef>('Sprite').setOrigin('bottom-left');

        break;
      case 'close':
        getComponent<SpriteRef>('Sprite').setIsInteracting(true);
        getComponent<SpriteRef>('Sprite').setState('chestClosed');
        getComponent<SpriteRef>('Sprite').setFrameWidth(16);
        getComponent<SpriteRef>('Sprite').setFrameHeight(16);
        getComponent<SpriteRef>('Sprite').setOrigin('top-left');
        break;
      default:
        getComponent<SpriteRef>('Sprite').setIsInteracting(false);
    }

    return await waitForMs(0).then(() => forceUpdate());
  });

  return null;
}
interface ChestProps {
  props: GameObjectProps;
  state: string;
  spriteData: SpriteProps;
  itemName: string;
  compound?: number[][];
}

export default function Chest({ props, state: initialState, spriteData, compound }: ChestProps) {
  const name = `${initialState}-${props.x}-${props.y}`; // fallback name required for persisted flag

  const positions = compound?.map(([x, y]) => ({ x, y }));
  return (
    <GameObject
      name={name}
      persisted={false}
      {...props}
      layer="chest"
      isCompound
      positions={positions}
    >
      <Sprite
        {...spriteData}
        state={initialState}
        isInteracting={false}
        frameWidth={16}
        frameHeight={initialState === 'chestClosed' ? 16 : 32}
        frameTime={50}
        origin={initialState === 'chestClosed' ? 'top-left' : 'bottom-left'}
      />
      <Interactable type={`open-close-${initialState}`} />
      <Collider />
      <ChestScript />
    </GameObject>
  );
}
