import useGameEvent from './useGameEvent';
import { PubSubEvent } from './utils/createPubSub';
import useSceneManager from './useSceneManager';

export type FinishLevelEvent = PubSubEvent<'finish-level', boolean>;

const LevelComplete = () => {
  const { checkCurrentLevelCompleted } = useSceneManager();

  useGameEvent('did-collect', async () => {
    checkCurrentLevelCompleted();
  });

  return null;
};

export default LevelComplete;
