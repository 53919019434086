import { useQuery } from 'react-query';
import { ProfileService } from '../client';

export const useCheckStudentsLimit = () => {
  const result = useQuery(
    'students-limit',
    ProfileService.checkStudentsLimitPerUserApiProfileCheckStudentsLimitGet,
    {
        retry: false,
        refetchOnReconnect: false,
        refetchInterval: false,
        refetchIntervalInBackground: false,
    }
  );

  return result;
};
