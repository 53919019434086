import { Box, Paper, ToggleButton, ToggleButtonGroup } from '@mui/material';

export default function MobileFooter({
  mobileToggle,
  setMobileToggle
}: {
  mobileToggle: 'code' | 'game';
  setMobileToggle: React.Dispatch<React.SetStateAction<'code' | 'game'>>;
}) {
  return (
    <Paper
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        right: 0,
        p: 2,
        zIndex: 5,
        display: 'flex',
        justifyContent: 'center'
      }}
    >
      <Box component="div" sx={{ width: 300, maxWidth: '100%' }}>
        <ToggleButtonGroup
          fullWidth
          size="small"
          color="primary"
          value={mobileToggle}
          exclusive
          onChange={(e, v) => setMobileToggle(v)}
          aria-label="Platform"
        >
          <ToggleButton value="code">Code</ToggleButton>
          <ToggleButton value="game">Game</ToggleButton>
        </ToggleButtonGroup>
      </Box>
    </Paper>
  );
}
