import { Box, Typography } from '@mui/material';
import * as icons from '@mui/icons-material';

export default function StudentBadge({
  name,
  icon,
  active
}: {
  name: string;
  icon: string;
  active: boolean;
}) {
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  const IconObject = icons[icon];
  return (
    <Box component="div" sx={{ width: 200 }}>
      <Box
        component="div"
        sx={{
          position: 'relative',
          height: 200,
          opacity: active ? 1 : 0.5
        }}
      >
        <Box
          component="div"
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 320.49 437.58"
            style={{
              width: 160,
              height: 200
            }}
          >
            <polygon
              points="104.48 437.58 79.57 375.65 21.68 408.87 93.87 200.67 176.67 229.38 104.48 437.58"
              style={{ fill: active ? '#eb464a' : 'gray', strokeWidth: 0 }}
            />
            <polygon
              points="216.01 437.58 240.92 375.65 298.81 408.87 226.62 200.67 143.82 229.38 216.01 437.58"
              style={{ fill: active ? '#eb464a' : 'gray', strokeWidth: 0 }}
            />
            <polygon
              points="226.62 200.67 143.82 229.38 180.28 334.52 186.29 329.53 218.38 340.27 234.86 310.71 264.3 309.34 226.62 200.67"
              style={{ fill: '#000', opacity: 0.3, strokeWidth: 0 }}
            />
            <polygon
              points="93.87 200.67 56.19 309.34 85.63 310.71 102.11 340.27 134.2 329.53 140.21 334.52 176.67 229.38 93.87 200.67"
              style={{ fill: '#000', opacity: 0.3, strokeWidth: 0 }}
            />
            <polygon
              points="160.25 319.12 134.2 297.51 102.11 308.26 85.63 278.7 51.83 277.12 47.14 243.61 16.19 229.92 23.92 196.98 0 173.04 19.11 145.11 5.46 114.15 33.37 95.01 31.82 61.21 64.76 53.45 75.53 21.37 109.04 26.03 130.67 0 160.25 16.46 189.82 0 211.45 26.03 244.96 21.37 255.73 53.45 288.67 61.21 287.12 95.01 315.03 114.15 301.38 145.11 320.49 173.04 296.57 196.98 304.3 229.92 273.35 243.61 268.66 277.12 234.86 278.7 218.38 308.26 186.29 297.51 160.25 319.12"
              style={{ fill: active ? '#fec514' : 'darkgray', strokeWidth: 0 }}
            />
          </svg>
        </Box>

        <Box
          component="div"
          sx={{
            position: 'absolute',
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}
        >
          <IconObject
            sx={{
              position: 'absolute',
              fontSize: 80,
              marginTop: '-52px'
            }}
          />
        </Box>
      </Box>
      <Typography sx={{ textAlign: 'center', marginTop: 2 }}>{name}</Typography>
    </Box>
  );
}
