import { useContext, useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { debounce } from 'lodash-es';

import { ProfileService } from '../client';
import { UserContext } from '../pages/App';

export const useStudentUsernameExists = (username: string) => {
  const currentUser = useContext(UserContext);

  const [debouncedUsername, setDebouncedUsername] = useState(username);

  useEffect(() => {
    const handler = debounce(() => {
      setDebouncedUsername(username);
    }, 800);

    handler();
    setDebouncedUsername('');

    return () => {
      handler.cancel();
    };
  }, [username]);

  const { data, isLoading } = useQuery(
    ['student-username-exists', username],
    () =>
      ProfileService.checkStudentUsernameExistsApiProfileCheckStudentUsernameExistsGet(
        username,
        currentUser?.organization_id !== '' ? currentUser?.organization_id : undefined
      ),
    {
      enabled: !!debouncedUsername,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      retry: false,
      cacheTime: 800
    }
  );

  return { usernameExists: data || false, isUsernameLoading: isLoading };
};
