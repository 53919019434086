import useLevels from '../../useLevels';
import { MenuItem, Paper, Stack, TextField } from '@mui/material';
import { DataGrid, GridColDef, GridToolbar } from '@mui/x-data-grid';
import React, { useEffect } from 'react';
import { useQuery } from 'react-query';
import { QuizService, StudentQuizResult } from '../../../client';

export default function QuizResults({ classId }: { classId?: string }) {
  const { courseId, courseData } = useLevels();
  const [selectedChapter, setSelectedChapter] = React.useState<string>('');

  const chaptersWithQuiz = courseData?.chapters.filter((c) => c.quiz_exists);

  const { data: quizResults } = useQuery(
    ['quizResults', classId, courseId, selectedChapter],
    () =>
      QuizService.getClassSummaryApiQuizClassSummaryClassIdCourseIdChapterIdGet(
        classId ?? '',
        courseId,
        selectedChapter
      ),
    {
      enabled: !!classId
    }
  );

  useEffect(() => {
    if (chaptersWithQuiz) {
      setSelectedChapter(chaptersWithQuiz[0].id);
    }
  }, [courseData?.chapters]);

  const columns: GridColDef<StudentQuizResult>[] = [
    {
      field: 'username',
      headerName: 'User name',
      width: 200,
      flex: 1
    },
    {
      field: 'correct_answers',
      headerName: 'Correct Answers',
      type: 'number',
      width: 150
    },
    {
      field: 'answered_answers',
      headerName: 'Answered Answers',
      type: 'number',
      width: 150
    },
    {
      field: 'total_answers',
      headerName: 'Total Answers',
      type: 'number',
      width: 150
    },
    {
      field: 'score',
      headerName: 'Score',
      type: 'number',
      width: 150,
      valueGetter: (params) =>
        ((params.row.correct_answers / params.row.total_answers) * 100).toFixed(0)
    }
  ];

  return (
    <Stack gap={2}>
      <Paper>
        <TextField
          select
          fullWidth
          label="Select Chapter"
          value={selectedChapter}
          onChange={(e) => setSelectedChapter(e.target.value)}
        >
          {chaptersWithQuiz?.map((chapter) => (
            <MenuItem key={chapter.id} value={chapter.id}>
              {chapter.name}
            </MenuItem>
          ))}
        </TextField>
      </Paper>
      <Paper>
        <DataGrid
          slots={{ toolbar: GridToolbar }}
          rowHeight={36}
          columns={columns}
          rows={quizResults ?? []}
          sx={{ minHeight: 400 }}
          getRowId={(row) => row.student_id}
          autoHeight
        />
      </Paper>
    </Stack>
  );
}
