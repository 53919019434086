import anime from 'animejs';
import { MutableRefObject, useEffect, useRef } from 'react';
import { Position } from './GameObject';
import useCollisionTest from './useCollisionTest';
import useComponentRegistry, { ComponentRef } from './useComponentRegistry';
import useGame from './useGame';
import useGameObject from './useGameObject';
import { PubSubEvent } from './utils/createPubSub';
import waitForMs from './utils/waitForMs';
import { PushablePosition, Speech } from './utils/movementHelpers';
import { Primitive } from './utils/commandHelper';

export type Direction = -1 | 0 | 1;
export type MoveDirection = [Direction, Direction];

export type AttemptMoveEvent = PubSubEvent<'attempt-move', Position>;
export type CannotMoveEvent = PubSubEvent<'cannot-move', PushablePosition>;
export type WillMoveEvent = PubSubEvent<'will-move', Position>;
export type WillChangePositionEvent = PubSubEvent<'will-change-position', Position>;
export type DidMoveEvent = PubSubEvent<'did-move', Position>;
export type DidChangePositionEvent = PubSubEvent<'did-change-position', Position>;
export type RotatingEvent = PubSubEvent<
  'rotating',
  { direction: Direction[]; isRotating: MutableRefObject<boolean> }
>;
export type MovingEvent = PubSubEvent<
  'moving',
  {
    currentPosition: Position;
    nextPosition: Position;
    direction: MoveDirection;
    facingDirection: Direction[];
  }
>;
interface SpeakingData {
  phrase: Primitive;
  isSpeaking: React.MutableRefObject<boolean>;
  x: number;
  y: number;
}

export type SpeakingEvent = PubSubEvent<'speaking', SpeakingData>;

export type MoveableRef = ComponentRef<
  'Moveable',
  {
    canMove: (position?: Position) => boolean;
    canSpeak: () => boolean;
    isMoving: () => boolean;
    blockMovement: (delayMs: number) => Promise<void>;
    move: (position: Position, type?: 'move' | 'push' | 'jump' | 'speak') => Promise<boolean>;
    rotate: (direction: Direction[]) => Promise<boolean>;
    speak: ({ phrase }: Speech) => Promise<boolean>;
  }
>;

interface Props {
  isStatic?: boolean;
}

export default function Moveable({ isStatic = false }: Props) {
  const {
    settings: { movementDuration },
  } = useGame();
  const { transform, publish, nodeRef } = useGameObject();
  const canMove = useRef(!isStatic);
  const canSpeak = useRef(false);
  const testCollision = useCollisionTest();
  const nextPosition = useRef({ x: transform.x, y: transform.y });
  const facingDirection = useRef<Direction[]>([0, -1]);
  const movingDirection = useRef<MoveDirection>([0, 0]);
  const isSpeaking = useRef(false);
  const isRotating = useRef(false);

  const api = useComponentRegistry<MoveableRef>('Moveable', {
    canMove(position) {
      if (isStatic) return false;
      if (position && !testCollision(position)) return false;
      return canMove.current;
    },
    isMoving() {
      return !isStatic && !canMove.current;
    },
    canSpeak() {
      return canSpeak.current;
    },
    async blockMovement(delayMs) {
      canMove.current = false;
      await waitForMs(delayMs);
      canMove.current = true;
    },
    async move(targetPosition, type = 'move') {
      if (isStatic) return false;
      if (!canMove.current) return false;

      const isJumping = type === 'jump';
      const isForced = isJumping;

      publish<AttemptMoveEvent>('attempt-move', targetPosition);

      if (!testCollision(targetPosition)) {
        publish<CannotMoveEvent>('cannot-move', targetPosition as PushablePosition);
        await api.blockMovement(movementDuration / 2);
        return false;
      }

      publish<WillChangePositionEvent>('will-change-position', targetPosition);
      !isForced && publish<WillMoveEvent>('will-move', targetPosition)

      const dirX = (targetPosition.x - transform.x) as Direction;
      const dirY = (targetPosition.y - transform.y) as Direction;
      nextPosition.current = targetPosition;
      movingDirection.current = [dirX, dirY];
      facingDirection.current =
        [transform.rotationX as Direction, transform.rotationY as Direction] ||
        facingDirection.current;

      canMove.current = false;

      const fromX = transform.x;
      const fromY = transform.y;
      const toX = targetPosition.x;
      const toY = targetPosition.y;

      if (nodeRef.current) {
        anime.remove(nodeRef.current.position);

        await anime({
          targets: nodeRef.current.position,
          x: [fromX, toX],
          y: [fromY, toY],
          duration: movementDuration,
          easing: 'linear',
          // begin() {},
          update() {
            !isForced &&
              publish<MovingEvent>('moving', {
                currentPosition: nodeRef.current?.position || transform,
                nextPosition: targetPosition,
                direction: movingDirection.current,
                facingDirection: facingDirection.current
              });
          },
          complete() {
            if (dirX) transform.setX(targetPosition.x);
            if (dirY) transform.setY(targetPosition.y);
          }
        }).finished;
     }

      canMove.current = true;

      publish<DidChangePositionEvent>('did-change-position', {
        ...targetPosition
      });
      !isForced && publish<DidMoveEvent>('did-move', nextPosition.current);

      return true;
    },
    async rotate(direction) {
      transform.setRotationX(direction[0]);
      transform.setRotationY(direction[1]);
      publish<RotatingEvent>('rotating', { direction, isRotating });
      while (isRotating.current) {
        await waitForMs(100);
      }
      return true;
    },
    async speak({ phrase }) {
      isSpeaking.current = true;

      publish<SpeakingEvent>('speaking', {
        phrase: `${phrase}`,
        isSpeaking,
        x: transform.x,
        y: transform.y
      });

      while (isSpeaking.current) {
        await waitForMs(100);
      }
      return true;
    }
  });

  useEffect(() => {
    const node = nodeRef.current;
    // clean up running animation
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return () => anime.remove(node.position);
  }, [nodeRef]);

  return null;
}
