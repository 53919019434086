import { useMutation, useQueryClient } from 'react-query';
import { LevelService, LevelStatusUpdate } from '../client';
import { useContext } from 'react';
import { UserContext } from '../pages/App';
import useLevels from './useLevels';
import { useParams } from 'react-router-dom';

export default function useLevelCompletion() {
  const { courseSlug } = useParams();

  const queryClient = useQueryClient();
  const currentUser = useContext(UserContext);
  const updateLevelCompletion = useMutation(
    (updatedLevelStatus: LevelStatusUpdate) => {
      return LevelService.upsertLevelCompletionApiLevelUpdateSlugPost(
        courseSlug ?? 'python',
        updatedLevelStatus
      );
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(`courseData-${currentUser?.user_id}`);
      }
    }
  );
  return { updateLevelCompletion };
}
