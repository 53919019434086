import React from 'react';
import { Box, IconButton } from '@mui/material';
import { styled, SxProps } from '@mui/system';
import { IconX } from '@tabler/icons-react';
import { useTipContext } from '../context/TipProvider';

const BorderImageBox = styled(Box)(({ borderImage }: { borderImage: string }) => ({
  borderStyle: 'solid',
  borderWidth: '24px',
  borderImage: `url(${borderImage}) 24 repeat`,
  color: 'black',
  position: 'relative'
}));

export default function TipBox({
  children,
  sx,
  name
}: {
  children: React.ReactNode;
  sx: SxProps;
  name: string;
}) {
  const { closeTip, currentTip } = useTipContext();
  if (currentTip !== name) {
    return null;
  }
  return (
    <BorderImageBox borderImage="/assets/sprites/tip-square.png" sx={sx}>
      <IconButton
        sx={{
          position: 'absolute',
          top: -24,
          right: -24,
          '&:hover': { backgroundColor: 'transparent' }
        }}
        size="small"
        onClick={closeTip}
      >
        <IconX style={{ width: 16, color: 'black' }} />
      </IconButton>
      <Box component="div" sx={{ backgroundColor: '#E8CFA6' }}>
        {children}
      </Box>
    </BorderImageBox>
  );
}
