import * as yup from 'yup';

export const resetPasswordSchema = yup.object({
  new_password: yup.string().required("New Password is required").test({
    name: 'password-validation',
    message: 'Password must be at least 6 characters long',
    test: function (value) {
      const confirmPassword = this.parent.confirmPassword;
      if (!confirmPassword) return true;
      return !!value && value.length >= 6;
    }
  }),
  confirm_password: yup.string().required("Confirm Password is required").test({
    name: 'confirmPassword-validation',
    message: 'Passwords must match',
    test: function (value) {
      const password = this.parent.new_password;
      return password === value;
    }
  }),
});
