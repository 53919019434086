import { useQuery } from 'react-query';
import { PaymentService } from '../client';

export const usePreviewUpgrade = (planName: string) => {
  const previewUpgradePrice = useQuery(
    `preview-upgrade-${planName}`,
    () =>
      PaymentService.previewSubscriptionUpgradeApiPaymentPreviewSubscriptionUpgradeGet(planName),
    {
      enabled: !!planName,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
    }
  );

  return previewUpgradePrice;
};
