import React, { useState } from 'react';
import { Modal, Box, Grid, Button, Paper, Card } from '@mui/material';
import Typography from '@mui/material/Typography';
import { useGenericModal } from '../../context/GenericModalContext';

interface AvatarSelectionModalProps {
  onSelect: (avatar: string) => void;
  currentAvatar: string;
}

export const avatars = [
  'Rabbit',
  'Boy',
  'Boy2',
  'Detective',
  'FarmerBoy',
  'Girl',
  'Girl2',
  'Glasses',
  'Goblin',
  'Kid1',
  'Kid2',
  'Knight',
  'Lady',
  'Lady2',
  'Luimberjack',
  'OldMan',
  'OldMan2',
  'Punk',
  'Viking',
  'Wizard1',
  'Wizard2'
];

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 640,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 2
};

export default function AvatarSelectorModal({
  onSelect,
  currentAvatar
}: AvatarSelectionModalProps) {
  const { openModal: open, setOpenModal } = useGenericModal('avatar-selector-modal');
  const [selectedAvatar, setSelectedAvatar] = useState<string>(currentAvatar);

  const handleSelect = (avatar: string) => {
    setSelectedAvatar(avatar);
  };

  const handleConfirm = () => {
    onSelect(selectedAvatar);
    setOpenModal(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpenModal(false)}
      aria-labelledby="select-avatar-modal"
      aria-describedby="modal-modal-description"
    >
      <Paper sx={style}>
        <Typography variant="h6" sx={{ textAlign: 'center', py: 2 }}>
          Choose an Avatar
        </Typography>
        <Grid container spacing={2}>
          {avatars.map((avatar, index) => (
            <Grid item xs={2} key={index}>
              <Card
                onClick={() => setSelectedAvatar(avatar)}
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  borderColor: avatar === selectedAvatar ? 'primary.main' : '#666',
                  borderStyle: 'solid',
                  borderWidth: 1,
                  pt: 2,
                  '&:hover': { cursor: 'pointer', backgroundColor: 'rgba(255,255,255,0.1)' }
                }}
              >
                <img
                  src={`/assets/characters/portraits/${avatar}.png`}
                  style={{ width: 64, height: 64 }}
                />
              </Card>
            </Grid>
          ))}
        </Grid>
        <Box component="div" sx={{ display: 'flex', justifyContent: 'flex-end', mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleConfirm}
            disabled={!selectedAvatar}
            sx={{ mt: 2 }}
          >
            Choose Avatar
          </Button>
        </Box>
      </Paper>
    </Modal>
  );
}
