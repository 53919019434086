export interface UnexpectedErrorType {
  [key: string]: any;
}

export const UNEXPECTED_ERRORS: UnexpectedErrorType = {
  speak: {
    data: (phrase: string) => {
      return `That’s the wrong word you used with the \`speak()\` function. In order to call out, you must write **“${phrase}”** exactly as written, like this: \`player.speak(”${phrase}”)\``;
    },
    position:
      'In order to call out successfully, head for and stand on an X mark before using `speak()` function.',
    wrongPhrase: "You're trying to speak to the wrong phrase."
  },
  push: {
    position:
      'You can only push rocks in this exercise, and to do so you must stand in front of them and face them in the direction you wish to push using:\n\n`player.turn_left()` and `player.turn_right()`.',
    collision: 'You can not push the object further in this direction.'
  },
  move: {
    wall: 'Wrong way, you bumped into a wall.\n\nIf you use the: `player.move_forward()` function too many steps in a direction you can accidentally bump into a wall or object. Make sure you move the right number of steps so you don’t bump into a wall.\n\nAlso be sure to face the direction you want to walk using: `player.turn_left()` and `player.turn_right()` especially to turn corners or maneuver through obstacles.'
  },
  build: {
    position:
      'You can’t build a bridge in this location, head for the X mark in the map and face the bridge gap in order to build the bridge.',
    data: (structure: string) => {
      return `In order to build successfully, you must write the word **“${structure}”** in the build() function correctly, like this: \`player.build(”${structure}”)\`\n\nTry again.`;
    },
    log: 'You need to collect 4 logs in order to build a bridge, collect all four and try again.',
    plank: 'You need to collect 4 planks in order to build a road, collect all four and try again.',
    stone: 'You need to collect 4 stones in order to build a road, collect all four and try again.'
  },
  water: {
    position: 'You need to stand on an X mark and face target in order to use `water()` function.',
    canteen:
      'You need to pick up the water canteen or refill it in order to use the `water()` function.'
  },
  collect: {
    water: {
      position:
        'You need to stand on an X mark and face target in order to use `collect(”water”)` function.',
      data: 'In order to collect water successfully, you must write the word **“water”** in the collect() function correctly, like this: `player.collect(”water”)`\n\nTry again.'
    },
    object: {
      position:
        'You need to stand on an X mark and face target in order to use `collect()` function.'
    }
  },
  open: {
    position: 'You need to stand on an X mark and face target in order to use `open()` function.',
    state:
      'The target is already open, you can’t use the `open()` function on a target that’s already open. Did you mean to use `close()` function?'
  },
  close: {
    position: 'You need to stand on an X mark and face target in order to use `close()` function.',
    state:
      'The target is already closed, you can’t use the `close()` function on a target that’s already closed. Did you mean to use `open()` function?'
  },
  assign: {
    variable: {
      isNotEqual: (variableName: string) => {
        return `The value of ${variableName} is not equal to the variable \`“${variableName}”\` value required in the level.`;
      }
    }
  },
  compare: {
    variable: {
      isNotEqual: (variableName: string) => {
        return `The value of ${variableName} is not equal to the expected value. Please check if you removed proper items.`;
      }
    }
  },
  place: {
    position: 'You need to stand on an X mark and face target in order to use `place()` function.',
    data: (placeObject: number) => {
      return `In order to place successfully, you must put the correct amount of collected objects: ${placeObject}`;
    },
    object: "You can't place this object here",
    number: 'Collect all the items in order to place here',
    string: "You can't place this object here",
    collect: "You don't have enough items to place here"
  },
  xmark: {
    position: (functionName: string) =>
      `You need to stand on an X mark in order to use \`"${functionName}()"\` function.`
  },
  combine: {
    position: "You can't combine here, please head for the X mark in the map.",
    data: 'The list of items to combine is not correct.',
    collect: "You don't have enough items to combine."
  },
  plant: {
    position: 'You need to stand on an X mark and face target in order to use `plant()` function.',
    data: (placeObject: number) => {
      return `In order to plant successfully, you must put the correct amount of collected objects: ${placeObject}`;
    },
    object: "You can't plant this object here",
    number: 'Collect all the items in order to plant here',
    string: "You can't plant this object here",
    collect: "You don't have enough items to plant here"
  }
};
