import { useState } from 'react';
import Collider from '../@core/Collider';
import GameObject, { GameObjectProps } from '../@core/GameObject';
import Sprite, { SpriteProps } from '../@core/Sprite';
import useGameEvent from '../@core/useGameEvent';
import useGameObject from '../@core/useGameObject';
import waitForMs from '../@core/utils/waitForMs';
import useGame from '../@core/useGame';

interface GrainStackProps {
  props: GameObjectProps;
  state: string;
  spriteData: SpriteProps;
  itemName: string;
}

export default function GrainStack({ props, state, spriteData }: GrainStackProps) {
  const [grainStackPosition, setGrainStackPosition] = useState({ x: props.x, y: props.y });
  const [name, setName] = useState(`${state}-${props.x}-${props.y}`);
  const [disabled, setDisabled] = useState(true);
  const { getRef, forceUpdate } = useGameObject();
  const { findGameObjectsByXY } = useGame();
  const [grainStackState, setGrainStackState] = useState('grainstack');

  useGameEvent('build', (position) => {
    if (grainStackPosition.x !== position.x || grainStackPosition.y !== position.y) return;

    const newGrainStackState = position.positionType === 'horizontal' ? 'grainstack' : 'grainstack';

    setGrainStackState(newGrainStackState);
    setName(`${newGrainStackState}-${position.x}-${position.y}`);
    waitForMs(0).then(() => forceUpdate());

    const obj = findGameObjectsByXY(position.x, position.y);
    obj.forEach((o) => {
      const collider = o.getComponent('Collider');
      if (collider) {
        o.getComponent('Collider').setWalkable(true);
      }
    });

    getRef().setDisabled(false);
    setDisabled(false);
    setGrainStackPosition({ ...position });
    waitForMs(0).then(() => forceUpdate());
  });

  return (
    <GameObject
      key={name}
      name={name}
      persisted={false}
      {...props}
      {...grainStackPosition}
      layer="grainstack"
      disabled={disabled}
    >
      <Sprite {...spriteData} state={grainStackState} />
      <Collider isTrigger />
    </GameObject>
  );
}
