import React, { useEffect } from 'react';
import {
  Box,
  TextField,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
  Grid,
  FormControl,
  FormLabel
} from '@mui/material';
import { Controller, UseFormReturn } from 'react-hook-form';

interface ConfigureCSVFormProps {
  formMethods: UseFormReturn<any>;
}

const ConfigureCSVForm: React.FC<ConfigureCSVFormProps> = ({ formMethods }) => {
  const { control, watch, setValue, trigger } = formMethods;
  const isFullNameSplit = watch('isFullNameSplit');

  useEffect(() => {
    trigger(['first_name', 'last_name', 'full_name']);
  }, [isFullNameSplit, trigger]);

  return (
    <Box component="form" noValidate autoComplete="off">
      <Typography variant="h6" gutterBottom>
        Configure CSV
      </Typography>
      <Typography variant="body2" gutterBottom>
        Here you can configure the CSV file import settings. You can specify how the full name is
        structured, set the column names used in your CSV for username, password, and email (if
        present), and define the separator used in the CSV file.
      </Typography>
      <Grid container columnSpacing={2} sx={{ mt: 2 }}>
        <Grid item xs={12}>
          <Controller
            name="isFullNameSplit"
            control={control}
            render={({ field }) => (
              <FormControl>
                <FormLabel id="full-name-type">Please choose whether your name field contains the full name or split to first and last name:</FormLabel>
                <RadioGroup
                  row
                  aria-labelledby="full-name-type"
                  aria-label="fullNameSplit"
                  {...field}
                  onChange={(e) => {
                    field.onChange(e);
                    setValue('isFullNameSplit', e.target.value === 'true');
                  }}
                >
                  <FormControlLabel value={false} control={<Radio />} label="One field (Full Name)" />
                  <FormControlLabel value={true} control={<Radio />} label="Two fields (First and Last Name)" />
                </RadioGroup>
              </FormControl>
            )}
          />
        </Grid>

        {!isFullNameSplit ? (
          <Grid item xs={12}>
            <Controller
              name="full_name"
              control={control}
              render={({ field, fieldState: { error } }) => (
                <TextField
                  {...field}
                  margin="normal"
                  required
                  label="Full Name"
                  variant="outlined"
                  fullWidth
                  error={!!error}
                  helperText={error && error.message}
                  autoComplete="off"
                />
              )}
            />
          </Grid>
        ) : (
          <>
            <Grid item xs={6}>
              <Controller
                name="first_name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    label="First Name"
                    variant="outlined"
                    fullWidth
                    error={!!error}
                    helperText={error && error.message}
                    autoComplete="off"
                  />
                )}
              />
            </Grid>
            <Grid item xs={6}>
              <Controller
                name="last_name"
                control={control}
                render={({ field, fieldState: { error } }) => (
                  <TextField
                    {...field}
                    margin="normal"
                    required
                    label="Last Name"
                    variant="outlined"
                    fullWidth
                    error={!!error}
                    helperText={error && error.message}
                    autoComplete="off"
                  />
                )}
              />
            </Grid>
          </>
        )}
        <Grid item xs={6}>
          <Controller
            name="username"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                margin="normal"
                required
                label="Username"
                variant="outlined"
                fullWidth
                error={!!error}
                helperText={error && error.message}
                autoComplete="off"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="password"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                margin="normal"
                required
                label="Password"
                variant="outlined"
                fullWidth
                error={!!error}
                helperText={error && error.message}
                autoComplete="off"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="email"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                margin="normal"
                label="Email"
                variant="outlined"
                fullWidth
                error={!!error}
                helperText={error ? error.message : 'Email column name in CSV if present'}
                autoComplete="off"
              />
            )}
          />
        </Grid>
        <Grid item xs={6}>
          <Controller
            name="separator"
            control={control}
            render={({ field, fieldState: { error } }) => (
              <TextField
                {...field}
                margin="normal"
                required
                label="Separator"
                variant="outlined"
                fullWidth
                error={!!error}
                helperText={error ? error.message : 'CSV separator, default is comma'}
                autoComplete="off"
              />
            )}
          />
        </Grid>
      </Grid>
    </Box>
  );
};

export default ConfigureCSVForm;
