/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Webhook } from '../models/Webhook';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PaymentService {

    /**
     * Paddle
     * @param requestBody
     * @returns any Successful Response
     * @throws ApiError
     */
    public static paddleApiPaymentPaddlePost(
        requestBody: Webhook,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payment/paddle',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Has Unassigned Purchases
     * @returns boolean Successful Response
     * @throws ApiError
     */
    public static hasUnassignedPurchasesApiPaymentHasUnassignedPurchasesGet(): CancelablePromise<boolean> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payment/has-unassigned-purchases',
        });
    }

    /**
     * Assign Purchase
     * @param studentId
     * @returns any Successful Response
     * @throws ApiError
     */
    public static assignPurchaseApiPaymentAssignPurchasePost(
        studentId: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payment/assign-purchase',
            query: {
                'student_id': studentId,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Current Subscription
     * @returns any Successful Response
     * @throws ApiError
     */
    public static currentSubscriptionApiPaymentCurrentSubscriptionGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payment/current-subscription',
        });
    }

    /**
     * Cancel Subscription
     * @returns any Successful Response
     * @throws ApiError
     */
    public static cancelSubscriptionApiPaymentCancelSubscriptionPost(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payment/cancel-subscription',
        });
    }

    /**
     * Current Plan
     * @returns any Successful Response
     * @throws ApiError
     */
    public static currentPlanApiPaymentCurrentPlanGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payment/current-plan',
        });
    }

    /**
     * Upgrade Subscription
     * @param planName
     * @returns any Successful Response
     * @throws ApiError
     */
    public static upgradeSubscriptionApiPaymentUpgradeSubscriptionPost(
        planName: string,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/payment/upgrade-subscription',
            query: {
                'plan_name': planName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Preview Subscription Upgrade
     * @param planName
     * @returns any Successful Response
     * @throws ApiError
     */
    public static previewSubscriptionUpgradeApiPaymentPreviewSubscriptionUpgradeGet(
        planName: string,
    ): CancelablePromise<Record<string, any>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payment/preview-subscription-upgrade',
            query: {
                'plan_name': planName,
            },
            errors: {
                422: `Validation Error`,
            },
        });
    }

    /**
     * Upgrade To Organization
     * @returns any Successful Response
     * @throws ApiError
     */
    public static upgradeToOrganizationApiPaymentUpgradeToOrganizationGet(): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/payment/upgrade-to-organization',
        });
    }

}
