import { DialogActions } from '@mui/material';
import { useGenericModal } from '../context/GenericModalContext';
import Modal from './GameUI/Modal';
import PrimaryButton from './GameUI/PrimaryButton';
import ContentMarkdown from './GameUI/ContentMarkdown';

const GoToSignUpModal = () => {
  const { openModal: open, setOpenModal } = useGenericModal('go-to-sign-up-modal');

  const onClose = () => {
    setOpenModal(false);
    document.location.href = '/signup';
  };
  const content = 'Please, sign up to continue. You will be able to save your progress and code.';

  return (
    <Modal open={open} onClose={onClose}>
      <ContentMarkdown content={content} />
      <DialogActions sx={{ justifyContent: 'center' }}>
        <PrimaryButton onClick={onClose} sx={{ width: '280px' }}>
          Sign Up
        </PrimaryButton>
      </DialogActions>
    </Modal>
  );
};

export default GoToSignUpModal;
