import React, { useEffect } from 'react';
import { Box, Stack, Tooltip, Typography } from '@mui/material';
import { usePreviewUpgrade } from '../../hooks/usePreviewUpgrade';
import { Help } from '@mui/icons-material';

interface UpgradePriceProps {
  planName: string;
}
const UpgradePrice: React.FC<UpgradePriceProps> = ({ planName }) => {
  const previewUpgradePrice = usePreviewUpgrade(planName);

  if (!previewUpgradePrice.data || previewUpgradePrice.isLoading) {
    return null;
  }

  const { immediate_transaction } = previewUpgradePrice.data.data;
  if (!immediate_transaction) {
    return null;
  }
  const { details } = immediate_transaction;
  const { totals, line_items } = details;

  const formatPrice = (amount: string) => {
    return (parseInt(amount, 10) / 100).toFixed(2);
  };
  return (
    <Box component="div" mt={1}>
      <Stack flexDirection="row" alignItems="center" gap={1}>
        <Typography color="text.secondary" variant="body2" textAlign="center">
          Price to upgrade: $ {formatPrice(totals.total)}
        </Typography>
        <Tooltip
          title={
            <Typography variant="body2">
              You are being charged a prorated amount for the changes made to your subscription.
              Proration is the calculation of charges based on the proportion of the billing period
              that the service was used.
            </Typography>
          }
          placement="bottom"
        >
          <Help sx={{ fontSize: 16 }} />
        </Tooltip>
      </Stack>
    </Box>
  );
};

export default UpgradePrice;
