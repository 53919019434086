import GameObject, { GameObjectProps, Position } from '../@core/GameObject';
import Sprite, { SpriteProps } from '../@core/Sprite';
import useGameObject from '../@core/useGameObject';
import useGameObjectEvent from '../@core/useGameObjectEvent';
import useSceneManager from '../@core/useSceneManager';
import useGame from '../@core/useGame';
import { PubSubEvent } from '../@core/utils/createPubSub';
import { useApiLevelData } from '../context/ApiLevelDataContext';
import { UNEXPECTED_ERRORS } from '../@core/utils/gameErrors';
import { DidCollectEvent } from '../@core/Collectable';
import waitForMs from '../@core/utils/waitForMs';
import Interactable, { InteractionEvent } from '../@core/Interactable';
import { useRef } from 'react';
import { Plant } from '../@core/utils/movementHelpers';
import { useCommandError } from '../hooks/useCommandError';
import { checkDataIsEqual } from '../@core/utils/commandHelper';
import { useSound } from '../@core/Sound';
import soundData from '../soundData';
import { XMARK_LIST } from '../@core/utils/xmarkList';

export interface TypedPosition extends Position {
  positionType: 'horizontal' | 'vertical';
}

export type PlantObjectEvent = PubSubEvent<'plant-object', TypedPosition>;

function ItemPlantScript() {
  const xmarkState = useRef(false);
  const { getRef } = useGameObject();
  const { publish } = useGame();

  const { levelData, setLevelData, inventory, setInventoryData } = useSceneManager();
  const { apiLevelData } = useApiLevelData();

  const { triggerError } = useCommandError();
  const playSowSeedsSound = useSound(soundData.sowSeeds);

  useGameObjectEvent<InteractionEvent>('interaction', async ({ obj, step }) => {
    const plantStep = step as Plant;
    const plant = plantStep.plantObject;

    if (!plant) return;

    if (!apiLevelData) return;

    const { levelGoals } = apiLevelData;

    const actionPoint = {
      x: getRef().transform.x,
      y: getRef().transform.y
    };
    const dirX = obj.transform.rotationX;
    const dirY = obj.transform.rotationY;

    const storagePosition = {
      x: actionPoint.x + dirX,
      y: actionPoint.y + dirY
    };

    const plantObjectGoals = levelGoals.filter(
      (goal) =>
        XMARK_LIST.includes(goal.type || '') &&
        goal.action === 'plant' &&
        goal?.position?.[0] === actionPoint.x &&
        goal?.position?.[1] === actionPoint.y
    );

    const plantObjectGoal = plantObjectGoals.find(
      (goal) =>
        checkDataIsEqual(goal.data, plantStep.plantObject) || goal.data === plantStep?.plantData
    );

    if (!plantObjectGoal) {
      triggerError(UNEXPECTED_ERRORS.plant.object);
      return;
    }

    if (
      plantObjectGoal?.targetPosition?.[0] !== storagePosition.x ||
      plantObjectGoal?.targetPosition?.[1] !== storagePosition.y
    ) {
      triggerError(UNEXPECTED_ERRORS.plant.position);
      return;
    }

    const inventoryItem = inventory.find((item) => item.type === plantObjectGoal?.cost?.type);

    const costAmount = plantObjectGoal?.cost?.amount || 0;

    const plantObject = plantStep.plantObject;
    const isPlantObjectNumber = typeof plantObject === 'number';
    const isPlantObjectString = typeof plantObject === 'string';

    const isNumberObjectPlantable = isPlantObjectNumber && plantObject === inventoryItem?.count;
    const isStringObjectPlantable =
      isPlantObjectString && checkDataIsEqual(plantObject, plantObjectGoal.data);

    if (isPlantObjectNumber && !isNumberObjectPlantable && costAmount !== 0) {
      triggerError(UNEXPECTED_ERRORS.plant.number);
      return;
    }

    if (isPlantObjectString && !isStringObjectPlantable) {
      triggerError(UNEXPECTED_ERRORS.plant.string);
      return;
    }

    if (
      isPlantObjectString &&
      inventoryItem?.count !== plantObjectGoal?.cost?.amount &&
      costAmount !== 0
    ) {
      triggerError(UNEXPECTED_ERRORS.plant.collect);
      return;
    }

    const newLevelData = levelData.map((item) => {
      if (
        XMARK_LIST.includes(item.type || '') &&
        item.action === 'plant' &&
        item.position?.[0] === actionPoint.x &&
        item.position?.[1] === actionPoint.y
      ) {
        return { ...item, score: (item.score += 1) };
      }
      return item;
    });

    setLevelData(newLevelData);

    setInventoryData(
      inventory.map((item) => {
        if (item.type === inventoryItem?.type && isNumberObjectPlantable) {
          return { ...item, count: 0 };
        } else if (item.type === inventoryItem?.type && isStringObjectPlantable) {
          return { ...item, count: (item.count -= 1) };
        }
        return item;
      })
    );

    playSowSeedsSound();
    await waitForMs(500);
    publish<DidCollectEvent>('did-collect', newLevelData);

    xmarkState.current = !xmarkState.current;

    if (xmarkState.current) {
      getRef().setDisabled(true);
    }
    return waitForMs(400);
  });

  return null;
}

interface ItemPlantProps {
  props: GameObjectProps;
  state: string;
  spriteData: SpriteProps;
  itemName: string;
}

export default function ItemPlant({ props, state, spriteData, itemName }: ItemPlantProps) {
  const name = `${state}-${props.x}-${props.y}`; // fallback name required for persisted flag

  return (
    <GameObject name={name} persisted={false} {...props} layer="item">
      <Sprite {...spriteData} state={state} />
      <Interactable type="plant" />
      <ItemPlantScript />
    </GameObject>
  );
}
