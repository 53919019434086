import { Primitive } from 'react-hook-form';
import { GameObjectContextValue, Position } from '../GameObject';
import { Direction } from '../Moveable';
import { Data } from './commandHelper';

export interface Command {
  moveForward?: boolean;
  moveBackward?: boolean;
  turnLeft?: boolean;
  turnRight?: boolean;
  steps?: number;
  moveLeft?: boolean;
  moveRight?: boolean;
  moveUp?: boolean;
  moveDown?: boolean;
  push?: boolean;
  speak?: boolean;
  phrase?: Data;
  build?: boolean;
  structure?: Data;
  watering?: boolean;
  collectSubject?: string;
  collect?: boolean;
  open?: boolean;
  close?: boolean;
  place?: boolean;
  placeObject?: Data;
  combine?: boolean;
  combineObject?: Data;
  plant?: boolean;
  plantObject?: Data;
  plantData?: Primitive;
}

export interface Speech {
  phrase: Data;
}

export interface Building {
  structure: Data;
}

export interface Watering {
  watering: boolean;
}

export interface Collecting {
  collectSubject: string;
  collect: boolean;
}

export interface Open {
  open: boolean;
}

export interface Close {
  close: boolean;
}

export interface Place {
  place: boolean;
  placeObject: Data;
}

export interface Push {
  push: boolean;
}

export interface Combining {
  combine: boolean;
  combineObject: Data;
}

export interface Plant {
  plant: boolean;
  plantObject: Data;
  plantData?: Primitive;
}

export const handleMovementAndTurning = async (
  command: Command,
  currentDir: Direction[]
): Promise<Direction[][] | undefined> => {
  const directions: Direction[][] = [
    [0, -1], // Down
    [-1, 0], // Left
    [0, 1], // Up
    [1, 0] // Right
  ];

  if (command.turnLeft || command.turnRight) {
    let currentDirectionIndex = directions.findIndex(
      (dir) => dir[0] === currentDir[0] && dir[1] === currentDir[1]
    );
    if (command.turnRight) {
      currentDirectionIndex = (currentDirectionIndex + 1) % directions.length;
    } else if (command.turnLeft) {
      currentDirectionIndex = (currentDirectionIndex - 1 + directions.length) % directions.length;
    }
    currentDir = directions[currentDirectionIndex];

    return [currentDir];
  }
  return;
};

export interface PushablePosition extends Position {
  pushable: boolean;
  direction?: Direction[];
}
export const handlePushing = async (
  command: Command,
  transform: GameObjectContextValue['transform']
): Promise<PushablePosition[] | undefined> => {
  if (command.push) {
    const nextPosition = {
      x: transform.x + transform.rotationX,
      y: transform.y + transform.rotationY,
      pushable: true,
      direction: [transform.rotationX, transform.rotationY] as Direction[]
    };

    return [nextPosition];
  }

  return;
};

export const handleStepsWalking = async (
  steps: number,
  currentDir: Direction[],
  transform: GameObjectContextValue['transform'],
  moveForward?: boolean,
  moveBackward?: boolean
): Promise<Position[]> => {
  const stepsPositions: Position[] = [];
  let playerPosition: Position = { x: transform.x, y: transform.y };

  const stepDirectionMultiplier = moveForward ? 1 : moveBackward ? -1 : 0;

  for (let i = 1; i <= steps; i++) {
    const nextPositionX = playerPosition.x + currentDir[0] * stepDirectionMultiplier;
    const nextPositionY = playerPosition.y + currentDir[1] * stepDirectionMultiplier;
    playerPosition = { x: nextPositionX, y: nextPositionY };
    stepsPositions.push({ ...playerPosition });
  }

  return stepsPositions;
};

export const handleSingleStepMovement = async (
  direction: Position,
  transform: GameObjectContextValue['transform'],
  setPath: React.Dispatch<React.SetStateAction<(Position | Direction[])[]>>,
  setPathOverlayEnabled: React.Dispatch<React.SetStateAction<boolean>>,
  testCollision: (position: Position) => boolean,
  tileUtils: (position: Position) => {
    add: (dir: { x: number; y: number }) => Position;
    equals: (position: Position) => boolean;
  }
): Promise<Position[] | undefined> => {
  const nextPosition = tileUtils(transform).add(direction);

  if (tileUtils(nextPosition).equals(transform)) {
    // No movement necessary
    return;
  }

  if (!testCollision(nextPosition)) {
    // Collision detected, do not proceed with movement
    return;
  }

  // Update the player's position to the next position
  transform.setX(nextPosition.x);
  transform.setY(nextPosition.y);

  // Set the path to the next position for visual representation
  //   setPath([nextPosition]);

  //   setPathOverlayEnabled(false);
  return [nextPosition];
};

export const handleSpeaking = async (phrase: Data): Promise<Speech[]> => {
  return [{ phrase }];
};

export const handleBuilding = async (structure: Data): Promise<Building[]> => {
  return [{ structure }];
};

export const handleWatering = async (): Promise<Watering[]> => {
  return [{ watering: true }];
};

export const handleCollecting = async (collectSubject: string): Promise<Collecting[]> => {
  return [{ collectSubject: collectSubject, collect: true }];
};

export const handleOpening = async (): Promise<Open[]> => {
  return [{ open: true }];
};

export const handlePlacing = async (object: Data): Promise<Place[]> => {
  return [{ place: true, placeObject: object }];
};

export const handleClosing = async (): Promise<Close[]> => {
  return [{ close: true }];
};

export const handleCombining = async (object: Data): Promise<Combining[]> => {
  return [{ combine: true, combineObject: object }];
};

export const handlePlanting = async (plantObject: Data, plantData: Primitive): Promise<Plant[]> => {
  return [{ plant: true, plantObject, plantData }];
};

export const needsToTurn = (command: Command): boolean => {
  return Boolean(command.turnLeft || command.turnRight);
};

export const needsToMove = (command: Command): boolean => {
  return Boolean(command.moveForward || command.moveBackward);
};

export const needsToPush = (command: Command): boolean => {
  return Boolean(command.push);
};

export const needsToSpeak = (command: Command): boolean => {
  return Boolean(command.speak);
};

export const needsToBuild = (command: Command): boolean => {
  return Boolean(command.structure);
};

export const needsToWater = (command: Command): boolean => {
  return Boolean(command.watering);
};

export const needsToCollect = (command: Command): boolean => {
  return Boolean(command.collectSubject && command.collect);
};

export const needsToOpen = (command: Command): boolean => {
  return Boolean(command.open);
};

export const needsToClose = (command: Command): boolean => {
  return Boolean(command.close);
};

export const needsToPlace = (command: Command): boolean => {
  return Boolean(command.place && command.placeObject);
};

export const needsToCombine = (command: Command): boolean => {
  return Boolean(command.combine && command.combineObject);
};

export const needsToPlant = (command: Command): boolean => {
  return Boolean(command.plant && command.plantObject && command.plantData);
};
