'use client';

import { Menu, MenuOpen } from '@mui/icons-material';
import { AppBar, Box, IconButton, Toolbar } from '@mui/material';
import { useSidebar } from '../context/SidebarContext';
import LogoWithText from './LogoWithText';
import AccountMenu from './AccountMenu';


export default function Header() {
  const { handleDrawerOpen, handleDrawerClose, openSidebar } = useSidebar();
  return (
    <AppBar component="header" position="fixed">
      <Toolbar>
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={openSidebar ? handleDrawerClose : handleDrawerOpen}
          edge="start"
        >
          {openSidebar ? <MenuOpen /> : <Menu />}
        </IconButton>
        <Box
          component="div"
          sx={{
            flexGrow: 1,
            width: '100%',
            justifyContent: { xs: 'center', md: 'start' },
            display: 'flex'
          }}
        >
          <LogoWithText />
        </Box>
        <AccountMenu />
      </Toolbar>
    </AppBar>
  );
}
