import { useEffect, useState } from 'react';

import { Position } from '../@core/GameObject';
import { CommandInQueue, useOutput } from '../context/OutputContext';
import PlayerPathOverlay from './PlayerPathOverlay';
import useGameObject from '../@core/useGameObject';
import useGameLoop from '../@core/useGameLoop';
import usePointerClick from '../@core/usePointerClick';
import usePathfinding from '../@core/usePathfinding';
import usePointer from '../@core/usePointer';
import usePyodide from '../hooks/usePyodide';
import { useMovementControls } from '../@core/useMovementControls';
import { InteractableRef } from '../@core/Interactable';
import { Direction, MoveableRef } from '../@core/Moveable';
import {
  Command,
  handleMovementAndTurning,
  handleStepsWalking,
  handlePushing,
  needsToMove,
  needsToPush,
  needsToTurn,
  needsToSpeak,
  handleSpeaking,
  needsToBuild,
  handleBuilding,
  handleWatering,
  needsToWater,
  handleCollecting,
  needsToCollect,
  needsToOpen,
  handleOpening,
  needsToClose,
  handleClosing,
  needsToPlace,
  handlePlacing,
  needsToCombine,
  handleCombining,
  needsToPlant,
  handlePlanting
} from '../@core/utils/movementHelpers';
import useSceneManager from '../@core/useSceneManager';
import { setPlayerInitialPosition } from '../@core/utils/playerHelper';
import { useParams } from 'react-router-dom';
import { useGenericModal } from '../context/GenericModalContext';
import useGame from '../@core/useGame';
import waitForMs from '../@core/utils/waitForMs';
import { UNEXPECTED_ERRORS } from '../@core/utils/gameErrors';
import { useCommandError } from '../hooks/useCommandError';

interface ActionMap {
  [key: string]: () => Promise<boolean>;
}

interface ActionMapTypes {
  [key: string]: boolean;
}
interface PlayerScriptProps {
  startingPosition: Position;
}

const PlayerScript = ({ startingPosition }: PlayerScriptProps) => {
  const { slug } = useParams();
  const { pyodide } = usePyodide();
  const {
    isCodeExecuted,
    returnToStart,
    setReturnToStart,
    resetLevel,
    setResetLevel,
    commandsInQueue,
    commandInProgress,
    setCommandsInQueue,
    setCommandInProgress,
    resetCommandsQueue,
    path,
    setPath,
    isProcessingLocked
  } = useOutput();
  const { resetScene, levelData } = useSceneManager();
  const { getComponent, transform } = useGameObject();
  const { findGameObjectByName, findGameObjectsByXY } = useGame();
  const movementControls = useMovementControls();
  const findPath = usePathfinding();
  const pointer = usePointer();
  const [pathOverlayEnabled, setPathOverlayEnabled] = useState(true);
  const { setOpenModal: setOpenErrorModal, setContent: setError } = useGenericModal('error');
  const { triggerError } = useCommandError();
  const [isPointer, setIsPointer] = useState(false);

  useEffect(() => {
    (async () => {
      if (returnToStart) {
        resetCommandsQueue();
        setPlayerInitialPosition({ transform, startingPosition, getComponent });
        setReturnToStart(false);
        setError('');
        setOpenErrorModal(false);
      } else if (resetLevel) {
        setPlayerInitialPosition({ transform, startingPosition, getComponent });
        await resetScene();
        setResetLevel(false);
      }
    })();
  }, [returnToStart, resetLevel]);

  useEffect(() => {
    (async () => {
      if (isCodeExecuted) {
        if (!pyodide) return;

        const commands = pyodide.globals.get('commands');

        if (commands.length === 0) return;

        await createCommandQueue(commands || []);
        pyodide.globals.set('commands', []);
      }
    })();
  }, [isCodeExecuted]);

  const createCommandQueue = async (commands: Command[]) => {
    const queue: CommandInQueue[] = [];
    for (let i = 0; i < commands.length; i++) {
      const command: CommandInQueue = {
        command: commands[i],
        status: 'pending',
        path: [],
        id: i + 1
      };
      queue.push(command);
    }
    setCommandsInQueue(queue);
    setCommandInProgress(1);
  };

  const processCommands = async (currentCommand: CommandInQueue) => {
    const turningDir = needsToTurn(currentCommand.command)
      ? await handleMovementAndTurning(currentCommand.command, [
          transform.rotationX,
          transform.rotationY
        ] as Direction[])
      : [];

    if (turningDir && turningDir.length > 0) {
      currentCommand.path = turningDir;
    }

    const stepsPath = needsToMove(currentCommand.command)
      ? await handleStepsWalking(
          currentCommand.command.steps || 1,
          [transform.rotationX, transform.rotationY] as Direction[],
          transform,
          currentCommand.command.moveForward,
          currentCommand.command.moveBackward
        )
      : [];

    if (stepsPath && stepsPath.length > 0) {
      currentCommand.path = stepsPath;
    }

    const pushPath = needsToPush(currentCommand.command)
      ? await handlePushing(currentCommand.command, transform)
      : [];

    if (pushPath && pushPath.length > 0) {
      currentCommand.path = pushPath;
    }

    const speakPath = needsToSpeak(currentCommand.command)
      ? await handleSpeaking(currentCommand.command.phrase || '')
      : [];

    if (speakPath && speakPath.length > 0) {
      currentCommand.path = speakPath;
    }

    const buildPath = needsToBuild(currentCommand.command)
      ? await handleBuilding(currentCommand.command.structure || '')
      : [];

    if (buildPath && buildPath.length > 0) {
      currentCommand.path = buildPath;
    }

    const waterPath = needsToWater(currentCommand.command) ? await handleWatering() : [];

    if (waterPath && waterPath.length > 0) {
      currentCommand.path = waterPath;
    }

    const collectPath = needsToCollect(currentCommand.command)
      ? await handleCollecting(currentCommand.command.collectSubject || '')
      : [];

    if (collectPath && collectPath.length > 0) {
      currentCommand.path = collectPath;
    }

    const openPath = needsToOpen(currentCommand.command) ? await handleOpening() : [];

    if (openPath && openPath.length > 0) {
      currentCommand.path = openPath;
    }

    const closePath = needsToClose(currentCommand.command) ? await handleClosing() : [];

    if (closePath && closePath.length > 0) {
      currentCommand.path = closePath;
    }

    const placePath = needsToPlace(currentCommand.command)
      ? await handlePlacing(currentCommand.command.placeObject || '')
      : [];

    if (placePath && placePath.length > 0) {
      currentCommand.path = placePath;
    }

    const combinePath = needsToCombine(currentCommand.command)
      ? await handleCombining(currentCommand.command.combineObject || '')
      : [];

    if (combinePath && combinePath.length > 0) {
      currentCommand.path = combinePath;
    }

    const plantPath = needsToPlant(currentCommand.command)
      ? await handlePlanting(
          currentCommand.command.plantObject || '',
          currentCommand.command.plantData
        )
      : [];

    if (plantPath && plantPath.length > 0) {
      currentCommand.path = plantPath;
    }

    return currentCommand;
  };

  useGameLoop(async () => {
    const nextCommand = commandsInQueue.find(
      (c) => c.id === commandInProgress && c.status === 'pending'
    );
    if (!nextCommand) return;
    const command = await processCommands(nextCommand);
    if (!command) return;
    setCommandsInQueue((current) =>
      current.map((c) => (c.id === command.id ? { ...c, status: 'progress' } : c))
    );
    setCommandInProgress(command.id);
    if (isProcessingLocked.current) return;
    setPath(command.path);
    isProcessingLocked.current = true;
  });

  usePointerClick((event) => {
    if (event.button === 0 && slug === 'creative') {
      setIsPointer(true);
      try {
        const nextPath = findPath({ to: pointer });
        if (path.length > 0) {
          nextPath.unshift(transform);
        }
        setPath(nextPath);
        setPathOverlayEnabled(true);
      } catch {
        // pointer out of bounds
        setPath([]);
        setIsPointer(false);
      }
    }
  });

  useEffect(() => {
    if (path.length === 0) {
      setCommandsInQueue((currentCommands) => {
        const currentCommand = currentCommands.find((c) => c.id === commandInProgress);
        const nextCommand = currentCommands.find((c) => c.id === commandInProgress + 1);

        if (currentCommand) {
          currentCommand.status = 'completed';
        }

        if (nextCommand) {
          setCommandInProgress(nextCommand.id);
        } else {
          setCommandInProgress(0);
          isProcessingLocked.current = false;
          return [];
        }
        isProcessingLocked.current = false;
        return currentCommands;
      });
      return;
    }

    const moveAlongPath = async () => {
      const nextStep = path[0];
      if (!nextStep) return;

      const moveable = getComponent<MoveableRef>('Moveable');
      const interactable = getComponent<InteractableRef>('Interactable');

      const actionTypes: ActionMapTypes = {
        position: 'x' in nextStep && 'y' in nextStep && !('pushable' in nextStep) && !isPointer,
        direction: 'length' in nextStep && nextStep.length === 2,
        pushable: 'pushable' in nextStep && nextStep.pushable === true,
        speak: 'phrase' in nextStep,
        build: 'structure' in nextStep,
        water: 'watering' in nextStep,
        collect: 'collectSubject' in nextStep,
        open: 'open' in nextStep,
        close: 'close' in nextStep,
        place: 'placeObject' in nextStep,
        combine: 'combineObject' in nextStep,
        plant: 'plantObject' in nextStep && 'plantData' in nextStep,
        pointer: 'x' in nextStep && 'y' in nextStep && !('pushable' in nextStep) && isPointer
      };

      const actionMap: ActionMap = {
        position: async () => moveable && (await moveable.move(nextStep as Position)),
        direction: async () => moveable && moveable.rotate(nextStep as Direction[]),
        pushable: async () => {
          const playerObject = findGameObjectByName('player');
          const playerInteractable = playerObject?.getComponent('Interactable');

          const directionX = playerObject.transform.rotationX;
          const directionY = playerObject.transform.rotationY;

          const targetPosition = {
            x: playerObject.transform.x + directionX,
            y: playerObject.transform.y + directionY
          };

          const targetObjects = findGameObjectsByXY(targetPosition.x, targetPosition.y);

          const pushable = targetObjects.find((obj) => {
            return obj.getComponent('Interactable')?.getInteractionType() === 'push';
          });

          if (!pushable) {
            triggerError(UNEXPECTED_ERRORS.push.position);
            return false;
          }

          await playerInteractable.interact({
            position: {
              x: playerObject.transform.x + directionX,
              y: playerObject.transform.y + directionY
            },
            step: { push: true, direction: [directionX, directionY], position: targetPosition },
            command: 'push'
          });
          await waitForMs(200);
          return true;
        },
        speak: async () => {
          return (
            interactable &&
            interactable.canInteract() &&
            interactable.interactOnXMark({
              position: { x: transform.x, y: transform.y },
              step: nextStep,
              command: 'speak'
            })
          );
        },
        build: async () => {
          return (
            interactable &&
            interactable.canInteract() &&
            interactable.interactOnXMark({
              position: { x: transform.x, y: transform.y },
              step: nextStep,
              command: 'build'
            })
          );
        },
        water: async () => {
          return (
            interactable &&
            interactable.canInteract() &&
            interactable.interactOnXMark({
              position: { x: transform.x, y: transform.y },
              step: nextStep,
              command: 'water'
            })
          );
        },
        collect: async () =>
          interactable &&
          interactable.canInteract() &&
          interactable.interactOnXMark({
            position: { x: transform.x, y: transform.y },
            step: nextStep,
            command: 'collect'
          }),
        open: async () =>
          interactable &&
          interactable.canInteract() &&
          interactable.interactOnXMark({
            position: { x: transform.x, y: transform.y },
            step: nextStep,
            command: 'open'
          }),

        close: async () =>
          interactable &&
          interactable.canInteract() &&
          interactable.interactOnXMark({
            position: { x: transform.x, y: transform.y },
            step: nextStep,
            command: 'close'
          }),
        place: async () =>
          interactable &&
          interactable.canInteract() &&
          interactable.interactOnXMark({
            position: { x: transform.x, y: transform.y },
            step: nextStep,
            command: 'place'
          }),
        combine: async () =>
          interactable &&
          interactable.canInteract() &&
          interactable.interactOnXMark({
            position: { x: transform.x, y: transform.y },
            step: nextStep,
            command: 'combine'
          }),
        plant: async () =>
          interactable &&
          interactable.canInteract() &&
          interactable.interactOnXMark({
            position: { x: transform.x, y: transform.y },
            step: nextStep,
            command: 'plant'
          }),
        pointer: async () => {
          return await getComponent<MoveableRef>('Moveable')?.move(nextStep as Position);
        }
      };

      for (const [type, handler] of Object.entries(actionMap)) {
        if (actionTypes[type]) {
          const actionPerformed = await handler();
          if (actionPerformed) {
            setPath((currentPath) => {
              if (isPointer && currentPath.length <= 1) {
                setIsPointer(false);
                return [];
              }
              return currentPath.slice(1);
            });
          }
        }
      }
    };

    moveAlongPath();
  }, [path, getComponent]);

  const overlayPath = path.filter((p) => 'x' in p && 'y' in p) as Position[];

  return (
    <PlayerPathOverlay path={overlayPath} pathVisible={pathOverlayEnabled} pointer={pointer} />
  );
};

export default PlayerScript;
