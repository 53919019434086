import Collider from '../@core/Collider';
import GameObject, { GameObjectProps, Position } from '../@core/GameObject';
import Interactable, { InteractionEvent } from '../@core/Interactable';
import Sprite, { SpriteProps, SpriteRef } from '../@core/Sprite';
import useGameObject from '../@core/useGameObject';
import useGameObjectEvent from '../@core/useGameObjectEvent';
import waitForMs from '../@core/utils/waitForMs';

function TrayScript() {
  const { getComponent, forceUpdate } = useGameObject();

  useGameObjectEvent<InteractionEvent>('interaction', async ({ step }) => {
    const typedStep = step as { state: string };
    if (!typedStep.state) return;

    switch (typedStep.state) {
      case 'fill':
        getComponent<SpriteRef>('Sprite').setIsInteracting(true);
        break;
      case 'empty':
        getComponent<SpriteRef>('Sprite').setIsInteracting(true);
        break;
      default:
        getComponent<SpriteRef>('Sprite').setIsInteracting(false);
    }

    return await waitForMs(0).then(() => forceUpdate());
  });

  return null;
}
interface TrayProps {
  props: GameObjectProps;
  state: string;
  spriteData: SpriteProps;
  itemName: string;
  compound?: number[][];
}

export default function Tray({ props, state: initialState, spriteData, compound }: TrayProps) {
  const name = `${initialState}-${props.x}-${props.y}`; // fallback name required for persisted flag

  const positions = compound?.map(([x, y]) => ({ x, y }));
  return (
    <GameObject
      name={name}
      persisted={false}
      {...props}
      layer="tray"
      isCompound
      positions={positions}
    >
      <Sprite
        {...spriteData}
        state={initialState}
        isInteracting={false}
        frameWidth={32}
        frameHeight={16}
        frameTime={700}
      />
      <Interactable type={`water-${initialState}`} />
      <Collider />
      <TrayScript />
    </GameObject>
  );
}
