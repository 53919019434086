import React from 'react';
import { Box, Tooltip, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import useLevels from './useLevels';
import { LevelDataSummary } from '../client';
import { useGenericModal } from '../context/GenericModalContext';

interface ProgressTooltipProps {
  level: LevelDataSummary;
  levelNumber: number;
}

const ProgressTooltip = ({ level, levelNumber }: ProgressTooltipProps) => (
  <div>
    <Typography variant="body2">
      Level {levelNumber}: {level.name}
    </Typography>
  </div>
);

const ChapterProgressBarStatic: React.FC = () => {
  const { setOpenModal: setOpenLevelCompleteModal } = useGenericModal('level-complete');
  const { courseData, levelData } = useLevels();
  const currentLevel = levelData?.slug;
  const currentChapter = courseData?.chapters.find((chapter) =>
    chapter.level_data.some((level) => level.slug === currentLevel)
  );

  const levels = currentChapter?.level_data || [];

  const handleBlockClick = (levelSlug: string) => {
    setOpenLevelCompleteModal(false);
    window.location.href = `/play/${courseData?.id}/${levelSlug}`;
  };

  return (
    <Box
      component="div"
      sx={{
        display: 'flex',
        gap: '8px',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      {levels.map((level, index) => (
        <Tooltip
          title={<ProgressTooltip level={level} levelNumber={index + 1} />}
          arrow
          key={index}
        >
          <Box
            component="div"
            sx={{
              position: 'relative',
              width: '26px',
              height: '26px',
              backgroundColor: level.completed ? 'primary.dark' : 'divider',
              borderRadius: '4px',
              transition: 'transform 0.125s ease-in-out',
              cursor: 'pointer',
              '&:hover': {
                transform: 'scale(1.1) !important'
              }
            }}
            onClick={() => handleBlockClick(level.slug)}
          >
            {level.completed && (
              <StarIcon
                sx={{
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  fontSize: '16px',
                  color: '#FFF',                 
                }}
              />
            )}
          </Box>
        </Tooltip>
      ))}
    </Box>
  );
};

export default ChapterProgressBarStatic;
