import React from 'react';
import {
  Card,
  CardContent,
  Typography,
  Button,
  Grid,
  CardMedia,
  Container,
  Box
} from '@mui/material';
import DownloadIcon from '@mui/icons-material/Download';
import ViewIcon from '@mui/icons-material/Visibility';
import EmailIcon from '@mui/icons-material/Email';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { useCertificateActions } from './CertificateActions';
import { Link } from 'react-router-dom';
import { Certificate } from '../../client';

export default function CertificateCard({ certificate }: { certificate: Certificate }) {
  const { handleDownloadPDF, handleDownloadImage, handleSendByEmail, handleAddToProfile } =
    useCertificateActions(
      certificate.id,
      certificate.student_name,
      certificate.completion_date,
      certificate.course_name
    );

  return (
    <Card raised sx={{ display: 'flex' }}>
      <CardMedia
        component="img"
        image={`/api/certificate/${certificate.id}/thumbnail`}
        alt={`Certificate for ${certificate.student_name}`}
        sx={{ width: 350 }}
      />
      <CardContent>
        <Typography gutterBottom variant="h6" component="div">
          {certificate.course_name}
        </Typography>

        <Typography color="textSecondary" mt={1}>
          Issued to {certificate.student_name} on{' '}
          {new Date(certificate.completion_date).toDateString()}
        </Typography>
        <Grid container spacing={2} sx={{ mt: 2 }}>
          <Grid item xs={12}>
            <Button
              component={Link}
              to={`/certificate/${certificate.id}`}
              variant="contained"
              startIcon={<ViewIcon />}
              fullWidth
            >
              View Certificate
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              startIcon={<DownloadIcon />}
              onClick={handleDownloadPDF}
              fullWidth
            >
              Download PDF
            </Button>
          </Grid>
          <Grid item xs={6}>
            <Button
              variant="outlined"
              startIcon={<DownloadIcon />}
              onClick={handleDownloadImage}
              fullWidth
            >
              Download Image
            </Button>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
