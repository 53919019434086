import React, { SyntheticEvent, useState } from 'react';
import {
  Divider,
  Box,
  Typography,
  LinearProgress,
  Tabs,
  Tab,
  Button,
  Stack,
  TextField,
  MenuItem
} from '@mui/material';
import { useParams } from 'react-router-dom';
import useStudents from '../../components/useStudents';
import StudentAvatar from '../../components/StudentAvatar';
import StudentCertificates from './StudentCertificates';
import { StudentListItem } from '../../client';
import { Loader } from '../../components/Loader';
import useOrganizationStudents from '../../hooks/useOrganzationStudents';
import { TabContext, TabPanel } from '@mui/lab';
import useLevels from '../../components/useLevels';
import StudentProgress from './StudentProgress';

const StudentPage: React.FC = () => {
  const { courses, courseData, activeCourse, setActiveCourse, setActiveLevel } = useLevels();
  const { studentId } = useParams();
  const { students: userStudents } = useStudents();
  const { students: orgStudents } = useOrganizationStudents();

  // TODO: merge these API calls
  const allStudents: StudentListItem[] = [...(userStudents || []), ...(orgStudents || [])];

  const student = allStudents?.find((student) => student.id === studentId);

  const [value, setValue] = useState('progress');

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  if (!student || !courseData) {
    return <Loader />;
  }

  return (
    <Box component="div" sx={{ display: 'flex', flexDirection: 'column', gap: 2, mt: 2 }}>
      <StudentAvatar student={student} size={64} variant="h4" />
      <Divider />
      <TabContext value={value}>
        <Box component="div" sx={{ borderBottom: 1, borderColor: 'divider' }}>
          <Tabs value={value} onChange={handleChange} aria-label="basic tabs example">
            <Tab label="Progress" value="progress" />
            <Tab label="Certificates" value="certificates" />
          </Tabs>
        </Box>
        <TabPanel value="progress" sx={{ p: 0 }}>
          <TextField
            select
            label="Select Course"
            helperText="Please select course"
            value={activeCourse}
            onChange={(e) => {
              setActiveCourse(e.target.value);
              const course = courses.find((c) => c.value === e.target.value);
              setActiveLevel(course?.firstLevel ?? '');
            }}
          >
            {courses.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
          <StudentProgress courseData={courseData} />
        </TabPanel>
        <TabPanel value="certificates" sx={{ p: 0 }}>
          {student.id && <StudentCertificates studentId={student.id} />}
        </TabPanel>
      </TabContext>
    </Box>
  );
};

export default StudentPage;
