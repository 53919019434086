import { Box, Stack, Typography } from '@mui/material';
import React from 'react';
import StudentBadge from './StudentBadge';

export default function StudentBadges({ activeBadges }: { activeBadges: string[] }) {
  const badges = [
    {
      id: 'course-python',
      name: 'Python Development Course',
      icon: 'WorkspacePremium'
    },
    {
      id: 'chapter-intro',
      name: 'Chapter 1 - Introduction to Python',
      icon: 'PlayCircleFilledWhite'
    },
    {
      id: 'chapter-variables',
      name: 'Chapter 2 - Variables and Types',
      icon: 'Abc'
    },
    {
      id: 'chapter-operators',
      name: 'Chapter 3 - Basic Operators',
      icon: 'Add'
    },
    {
      id: 'chapter-loops',
      name: 'Chapter 4 - Learning Loops',
      icon: 'Replay'
    },
    {
      id: 'chapter-lists',
      name: 'Chapter 5 - Creating Lists',
      icon: 'List'
    },
    {
      id: 'chapter-conditions',
      name: 'Chapter 6 - Conditions',
      icon: 'QuestionMark'
    },
    {
      id: 'chapter-functions',
      name: 'Chapter 7 - Creating your own Functions',
      icon: 'Functions'
    },
    {
      id: 'chapter-dictionaries',
      name: 'Chapter 8 - Using Dictionaries',
      icon: 'Book'
    },
    {
      id: 'chapter-classes',
      name: 'Chapter 9 - Classes and Objects',
      icon: 'ViewInAr'
    }
  ];

  return (
    <Stack gap={4}>
      <Box component="div" sx={{ display: 'flex', gap: 2, flexWrap: 'wrap' }}>
        {badges && badges.length > 0 ? (
          badges.map((badge) => (
            <StudentBadge
              key={badge.id}
              name={badge.name}
              icon={badge.icon}
              active={activeBadges.includes(badge.id)}
            />
          ))
        ) : (
          <Typography variant="body1">No certificates found.</Typography>
        )}
      </Box>
    </Stack>
  );
}
