import { Box, Button, Container, Typography } from '@mui/material';
import InviteTeacherModal from '../../components/Organization/Teacher/InviteTeacherModal';
import { useGenericModal } from '../../context/GenericModalContext';
import { TeacherUsersList } from '../../components/Organization/Teacher/TeacherUsersList';

export default function OrganizationTeacherUsers() {
  const { setOpenModal: setOpenInviteTeacherModal } = useGenericModal('signUp');

  return (
    <Container maxWidth="lg" sx={{ mt: 3, mb: 14 }}>
      <Box component="div" display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="h4" gutterBottom>
          Teachers
        </Typography>
        <Box component="div" display="flex" gap={2} alignItems="center">
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenInviteTeacherModal(true)}
          >
            Invite Teacher
          </Button>
          <Button variant="contained" color="primary" href="/organization/teachers/add">
            Add Teacher
          </Button>
        </Box>
      </Box>

      <TeacherUsersList />
      <InviteTeacherModal />
    </Container>
  );
}
