import GameObject, { GameObjectProps } from '../@core/GameObject';
import Sprite, { SpriteProps } from '../@core/Sprite';
import useGameObject from '../@core/useGameObject';
import useGameObjectEvent from '../@core/useGameObjectEvent';
import useSceneManager from '../@core/useSceneManager';
import useGame from '../@core/useGame';
import { useApiLevelData } from '../context/ApiLevelDataContext';
import { UNEXPECTED_ERRORS } from '../@core/utils/gameErrors';
import { DidCollectEvent } from '../@core/Collectable';
import waitForMs from '../@core/utils/waitForMs';
import Interactable, { InteractionEvent } from '../@core/Interactable';
import { useRef } from 'react';
import {
  Data,
  checkDataIsEqual,
  convertToPrimitive,
  parseJson
} from '../@core/utils/commandHelper';
import { useCommandError } from '../hooks/useCommandError';
import { XMARK_LIST } from '../@core/utils/xmarkList';

interface Phrase {
  phrase: Data;
}

function SpeakScript() {
  const xmarkState = useRef(false);
  const { getRef } = useGameObject();
  const { publish, findGameObjectByName } = useGame();

  const { levelData, setLevelData } = useSceneManager();
  const { apiLevelData } = useApiLevelData();
  const { triggerError } = useCommandError();

  useGameObjectEvent<InteractionEvent>('interaction', async ({ obj, step }) => {
    const speakStep = step as Phrase;

    const { phrase } = speakStep;
    if (!phrase) return;

    if (!apiLevelData) return;

    const { levelGoals } = apiLevelData;
    const { items } = apiLevelData;

    const speakItems = items?.filter((item) => item.action === 'speak') || [];
    const positionSpeakable = speakItems.find(
      (item) => item.position[0] === obj.transform.x && item.position[1] === obj.transform.y
    );

    const levelSpeakables = levelGoals?.filter(
      (goal) =>
        goal.action === 'speak' &&
        goal.position?.[0] === obj.transform.x &&
        goal.position?.[1] === obj.transform.y
    );

    const levelSpeakable =
      levelSpeakables.length > 1
        ? levelSpeakables.find((goal) => checkDataIsEqual(goal.data, phrase))
        : levelSpeakables[0];

    if (!positionSpeakable) {
      triggerError(UNEXPECTED_ERRORS.speak.position);
      return false;
    }

    if (!levelSpeakable) {
      triggerError(UNEXPECTED_ERRORS.speak.wrongPhrase);
      return false;
    }

    if (!checkDataIsEqual(levelSpeakable.data, phrase)) {
      triggerError(UNEXPECTED_ERRORS.speak.data(levelSpeakable?.data));
      return false;
    }

    const playerObject = findGameObjectByName('player');
    const playerMoveable = playerObject?.getComponent('Moveable');
    await playerMoveable.speak({ phrase: convertToPrimitive(phrase) });

    const newLevelData = levelData.map((item) => {
      if (
        XMARK_LIST.includes(item.type || '') &&
        item.position?.[0] === obj.transform.x &&
        item.position?.[1] === obj.transform.y &&
        checkDataIsEqual(parseJson(item.data), phrase)
      ) {
        return { ...item, score: (item.score += 1) };
      }
      return item;
    });

    setLevelData(newLevelData);

    publish<DidCollectEvent>('did-collect', newLevelData);

    xmarkState.current = !xmarkState.current;

    if (xmarkState.current) {
      getRef().setDisabled(true);
    }
    return waitForMs(400);
  });

  return null;
}

interface ItemSpeakProps {
  props: GameObjectProps;
  state: string;
  spriteData: SpriteProps;
  itemName: string;
}

export default function ItemSpeak({ props, state, spriteData }: ItemSpeakProps) {
  const name = `${state}-${props.x}-${props.y}`; // fallback name required for persisted flag

  return (
    <GameObject name={name} persisted={false} {...props} layer="item">
      <Sprite {...spriteData} state={state} />
      <Interactable type="speak" />
      <SpeakScript />
    </GameObject>
  );
}
