import { useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { childSchema } from '../components/Profile/childSchema';
import { useStudentUsernameExists } from './useStudentUsernameExists';
import { Student } from '../client';

export const useAddStudentForm = (student: Student | undefined) => {
  const formMethods = useForm({
    resolver: yupResolver(childSchema),
    mode: 'onChange'
  });

  const username = formMethods.watch('username');

  const { usernameExists, isUsernameLoading } = useStudentUsernameExists(username!);

  useEffect(() => {
    if (student && username === student.username) return;
    if (username && !isUsernameLoading) {
      if (usernameExists) {
        formMethods.setError('username', {
          type: 'manual',
          message: 'Student username already exists'
        });
      } else {
        if (formMethods.formState.errors.username?.type === 'manual') {
          formMethods.clearErrors('username');
        }
      }
    }
  }, [username, usernameExists, isUsernameLoading, formMethods.setError, formMethods.clearErrors]);

  return formMethods;
};
